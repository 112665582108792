const React = require('react');
const PropTypes = require('prop-types');
const Input = require('../Input/Input.react');
const FullIcon = require('./icons/CheckboxFullIcon.react');
const EmptyIcon = require('./icons/CheckboxEmptyIcon.react');
const IndeterminateIcon = require('./icons/CheckboxIndeterminateIcon.react');

require('./style.scss');

const Checkbox = ({
  name,
  id,
  value,
  label,
  checked,
  onChange,
  disabled,
  indeterminate,
}) => {
  const className = disabled ? 'wethod-checkbox disabled' : 'wethod-checkbox';

  const getIcon = () => {
    if (checked) {
      return <FullIcon />;
    }
    if (indeterminate) {
      return <IndeterminateIcon />;
    }
    return <EmptyIcon />;
  };

  function handleChange(e) {
    if (!disabled) {
      onChange(e);
    }
  }

  function handleClick(e) {
    e.stopPropagation();
  }

  return (
    <label htmlFor={id}
      className={className}>
      {getIcon()}
      <Input id={id}
        onClick={handleClick}
        type="checkbox"
        name={name}
        value={value}
        checked={checked}
        onChange={handleChange}
        disabled={disabled} />
      <span className="wethod-checkbox__label">{label}</span>
    </label>
  );
};

Checkbox.defaultProps = {
  checked: false,
  onChange: null,
  disabled: false,
  indeterminate: false,
};

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  /**
   * Used to group checkbox. All buttons in the same group must have the same name.
   */
  name: PropTypes.string.isRequired,
  /**
   * The unique value associated with the checkbox.
   */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  /**
   * Used for accessibility and showed to the user.
   */
  label: PropTypes.node.isRequired,
  /**
   * It says whether or not this checkbox is the default-selected item in the group.
   */
  checked: PropTypes.bool,
  /**
   * It says whether or no this checkbox is in the "indeterminate" pseudo-state.
   * Useful in nested checkboxes.
   */
  indeterminate: PropTypes.bool,
  /**
   * Function to call when value changes.
   * @param event {HtmlEvent}
   */
  onChange: PropTypes.func,
  /**
   * If `true`, the button will be disabled.
   */
  disabled: PropTypes.bool,
};

module.exports = Checkbox;
