const ChartConfigProbabilityDimensionGrouping = require('./ChartConfigProbabilityDimensionGrouping');

class ChartConfigProbabilityDimensionDistinctGrouping extends
  ChartConfigProbabilityDimensionGrouping {
  constructor() {
    super(ChartConfigProbabilityDimensionDistinctGrouping.TYPE);
  }

  /**
   * @return {ChartConfigProbabilityDimensionDistinctGrouping}
   */
  static fromJSON() {
    return new ChartConfigProbabilityDimensionDistinctGrouping();
  }

  // eslint-disable-next-line class-methods-use-this
  clone() {
    return new ChartConfigProbabilityDimensionDistinctGrouping();
  }
}

ChartConfigProbabilityDimensionDistinctGrouping.TYPE = 'distinct';

module.exports = ChartConfigProbabilityDimensionDistinctGrouping;
