const React = require('react');
const PropTypes = require('prop-types');
const isEqual = require('react-fast-compare');
const ChartSidebarStep = require('./ChartSidebarStep.react');
const RangeFilter = require('../../AdvancedSearch/filters/RangeFilter/NumericRangeFilter/NumericRangeFilter.react');
const ClientFilter = require('../../../../apps/core/modules/pipeline/components/advanced-search/filters/ClientFilter.react');
const TimeRangeFilter = require('./ChartSidebarTimeRangeFilter.react');
const AccountFilter = require('../../../../apps/core/modules/pipeline/components/advanced-search/filters/AccountFilter.react');
const ProjectTypeFilter = require('../../../../apps/core/modules/pipeline/components/advanced-search/filters/ProjectTypeFilter.react');
const JobOrderCategoryFilter = require('../../../../apps/core/modules/pipeline/components/advanced-search/filters/JobOrderCategoryFilter.react');
const AdvancedSearchBody = require('../../AdvancedSearch/AdvancedSearchBody.react');
const ChartConfigFilter = require('../../../../apps/core/modules/insights/revenues/models/ChartConfigFilter/ChartConfigFilter');

class ChartSidebarDataSegmentStep extends React.Component {
  /**
   * Check whether the given value is not empty
   * @param value
   * @returns {boolean}
   */
  static isDirty(value) {
    return value != null && value !== '' && !isEqual(value, {}) && value.length !== 0;
  }

  constructor(props) {
    super(props);

    this.state = {
      keyword: '', // Used for filtering filters
      filters: {}, // All filters
    };

    this.handleSearch = this.handleSearch.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }

  /**
   * Filter visible children on their 'label' attribute based on the search keyword
   * @param e
   */
  handleSearch(e) {
    const { value } = e.target;
    this.setState({ keyword: value });
  }

  handleReset(e) {
    e.stopPropagation();
    this.props.handleReset();
  }

  /**
   * Return the amount of children with a non-empty value
   * @returns {number}
   */
  getTotalActive() {
    return this.props.filters.length;
  }

  getFilter(target) {
    return this.props.filters.find((filter) => filter.target === target);
  }

  getClientFilterValue() {
    return this.getFilter('client')?.values;
  }

  getAccountFilterValue() {
    return this.getFilter('account')?.values;
  }

  getProjectTypeFilterValue() {
    return this.getFilter('project-type')?.values;
  }

  getJobOrderCategoryFilterValue() {
    return this.getFilter('job-order-category')?.values;
  }

  getProbabilityFilterValue() {
    const filter = this.getFilter('probability');
    const value = {};
    if (filter?.max) {
      value.max = filter?.max;
    }
    if (filter?.min) {
      value.min = filter?.min;
    }
    return value;
  }

  getTimeRangeFilterValue() {
    return this.getFilter('time')?.value;
  }

  /**
   * Return all filters.
   * Each filter component must have a label prop, this is used on filter search.
   * @return {JSX.Element[]}
   */
  getAvailableFilters() {
    return [
      <ClientFilter name="client"
        key="client"
        label="Client"
        value={this.getClientFilterValue()}
        onChange={this.props.onChange} />,
      <RangeFilter name="probability"
        key="probability"
        value={this.getProbabilityFilterValue()}
        onChange={this.props.onChange}
        label="Probability"
        suffix="%"
        min={0}
        max={100}
        errors={this.props.getErrors('prob')}
        updateErrors={this.props.updateErrors} />,
      <TimeRangeFilter label="Time range"
        key="time"
        onChange={this.props.onChange}
        value={this.getTimeRangeFilterValue()} />,
      <AccountFilter name="account"
        key="account"
        label="Account"
        value={this.getAccountFilterValue()}
        onChange={this.props.onChange} />,
      <ProjectTypeFilter name="project-type"
        key="project-type"
        value={this.getProjectTypeFilterValue()}
        onChange={this.props.onChange}
        label="Project type" />,
      <JobOrderCategoryFilter name="job-order-category"
        key="job-order-category"
        value={this.getJobOrderCategoryFilterValue()}
        onChange={this.props.onChange}
        label="Job order category" />,
    ];
  }

  /**
   * Return visible filters.
   * An available filter is visible if its label contains current search keyword.
   * @return {JSX.Element[]}
   */
  getVisibleFilters() {
    return this.getAvailableFilters().filter((filter) => {
      const label = filter.props.label.toLowerCase();
      const keyword = this.state.keyword.toLowerCase();
      return label.includes(keyword);
    });
  }

  /**
   * Check whether the child with the given name is active.
   * A filter is considered active when its value is non-empty.
   * @param name
   * @returns {boolean}
   */
  isActive(name) {
    return ChartSidebarDataSegmentStep.isDirty(this.state.filters[name]);
  }

  render() {
    return (
      <ChartSidebarStep title="Data segment" className="wethod-chart-sidebar-step--data-segment">
        <AdvancedSearchBody onReset={this.handleReset}
          onSearch={this.handleSearch}
          totalActive={this.getTotalActive()}>
          {this.getVisibleFilters()}
        </AdvancedSearchBody>
      </ChartSidebarStep>
    );
  }
}

ChartSidebarDataSegmentStep.propTypes = {
  handleReset: PropTypes.func.isRequired,
  filters: PropTypes.arrayOf(ChartConfigFilter).isRequired,
  onChange: PropTypes.func.isRequired,
  getErrors: PropTypes.func.isRequired,
  updateErrors: PropTypes.func.isRequired,
};

module.exports = ChartSidebarDataSegmentStep;
