const ChartConfigDimension = require('../ChartConfigDimension');
const DistinctGrouping = require('./ChartConfigJobOrderCategoryDimensionDistinctGrouping');
const ClusterGrouping = require('./ChartConfigJobOrderCategoryDimensionClusterGrouping');

class ChartConfigJobOrderCategoryDimension extends ChartConfigDimension {
  /**
   * @param {ChartConfigJobOrderCategoryDimensionGrouping} grouping
   */
  constructor(grouping = new DistinctGrouping()) {
    super(ChartConfigJobOrderCategoryDimension.KEY);
    this.grouping = grouping;
  }

  /**
   * Build and return job order category dimension grouping based on given data.
   * @param data
   * @return {ChartConfigJobOrderCategoryDimensionGrouping}
   */
  static groupingFromJSON(data) {
    switch (data.type) {
      case DistinctGrouping.TYPE:
        return DistinctGrouping.fromJSON();
      case ClusterGrouping.TYPE:
        return ClusterGrouping.fromJSON(data);
      default:
        throw new Error(`'${data.type}' is not a valid job order category dimension grouping type`);
    }
  }

  /**
   * Build and return job order category dimension based on given data.
   * @param data
   * @return {ChartConfigJobOrderCategoryDimension}
   */
  static fromJSON(data) {
    return new ChartConfigJobOrderCategoryDimension(ChartConfigJobOrderCategoryDimension
      .groupingFromJSON(data.grouping));
  }

  /**
   * Return a copy of this instance.
   * @abstract
   * @return {ChartConfigJobOrderCategoryDimension}
   */
  clone() {
    return new ChartConfigJobOrderCategoryDimension(this.grouping.clone());
  }
}

ChartConfigJobOrderCategoryDimension.KEY = 'job-order-category';

module.exports = ChartConfigJobOrderCategoryDimension;
