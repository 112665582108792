const ChartConfigFilter = require('./ChartConfigFilter');

class ChartConfigFilterProjectType extends ChartConfigFilter {
  constructor(type) {
    super(ChartConfigFilterProjectType.TARGET, type);
  }
}

ChartConfigFilterProjectType.TARGET = 'project-type';

module.exports = ChartConfigFilterProjectType;
