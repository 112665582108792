const Grouping = require('./ChartConfigProjectTypeDimensionGrouping');

class ChartConfigProjectTypeDimensionDistinctGrouping extends Grouping {
  constructor() {
    super(ChartConfigProjectTypeDimensionDistinctGrouping.TYPE);
  }

  /**
   * @return {ChartConfigProjectTypeDimensionDistinctGrouping}
   */
  static fromJSON(data) {
    return new ChartConfigProjectTypeDimensionDistinctGrouping(data);
  }

  // eslint-disable-next-line class-methods-use-this
  isGrouped() {
    return false;
  }

  // eslint-disable-next-line class-methods-use-this
  clone() {
    return new ChartConfigProjectTypeDimensionDistinctGrouping();
  }
}

ChartConfigProjectTypeDimensionDistinctGrouping.TYPE = 'distinct';

module.exports = ChartConfigProjectTypeDimensionDistinctGrouping;
