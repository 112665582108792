const ChartConfigJobOrderCategoryDimensionGrouping = require('./ChartConfigJobOrderCategoryDimensionGrouping');

class ChartConfigJobOrderCategoryDimensionDistinctGrouping extends
  ChartConfigJobOrderCategoryDimensionGrouping {
  constructor() {
    super(ChartConfigJobOrderCategoryDimensionDistinctGrouping.TYPE);
  }

  /**
   * @return {ChartConfigJobOrderCategoryDimensionDistinctGrouping}
   */
  static fromJSON() {
    return new ChartConfigJobOrderCategoryDimensionDistinctGrouping();
  }

  // eslint-disable-next-line class-methods-use-this
  clone() {
    return new ChartConfigJobOrderCategoryDimensionDistinctGrouping();
  }
}

ChartConfigJobOrderCategoryDimensionDistinctGrouping.TYPE = 'distinct';

module.exports = ChartConfigJobOrderCategoryDimensionDistinctGrouping;
