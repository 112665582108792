const React = require('react');
const Levels = require('../Cell/LevelsCell.react');
const Level = require('./HeaderLevel.react');

const HeaderLevels = ({ items }) => {
  const sortedLevels = items.filter((el) => el.is_visible);
  const levels = sortedLevels.map((level) => (
    <Level
      key={level.level.id}
      name={level.name}
      cost={level.cost}
      price={level.price}
      levelId={level.level.id}>
      {level.short_name}
    </Level>
  ));

  return (
    <Levels className="wethod-budget-calculator__header-levels">{levels}</Levels>
  );
};

module.exports = HeaderLevels;
