const React = require('react');
const SectionHeader = require('../../../../../common/react/SectionHeader/components/SectionHeader.react');
const Actions = require('../containers/Actions');
const SegmentTimeline = require('./timeline/Timeline.react');
const Dashboard = require('../containers/dashboard/DataFreezingDashboard.js');
const Modal = require('../containers/Modal.js');

const DataFreezingEngine = () => (
  <div className="wethod-section-body data-freezing-engine">
    <SectionHeader
      current_section="Data Freezing Engine"
      helper_url="data-freezing-engine/index/"
      tour_id={566402}
      tour_start_page="data-freezing/engine"
      big />
    <Actions />
    <SegmentTimeline />
    <Dashboard />
    <Modal />
  </div>
);

module.exports = DataFreezingEngine;
