const React = require('react');
const PropTypes = require('prop-types');
const Icon = require('./StatusIcon.react');
const ShowIf = require('../ShowIf/ShowIf.react');
const CollapseTransition = require('../Transition/CollapseTransition/CollapseTransition.react');

require('./style.scss');

class Accordion extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: props.expanded,
    };

    this.onButtonClick = this.onButtonClick.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);

    this.contentRef = null;
    this.mutationObserver = null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.expanded !== this.props.expanded && this.props.expanded !== this.state.expanded) {
      this.toggleExpanded();
    }
    if (prevState.expanded !== this.state.expanded && this.props.onChange) {
      this.props.onChange(this.state.expanded);
    }
  }

  onButtonClick() {
    this.toggleExpanded();
  }

  onKeyUp(e) {
    // User can toggle accordion from keyword using Enter key. For usability purpose
    const enterPressed = e.keyCode === 13;
    if (enterPressed) {
      this.toggleExpanded();
    }
  }

  getClassName() {
    let name = 'wethod-accordion';
    if (this.props.className) {
      name += ` ${this.props.className}`;
    }
    if (this.state.expanded) {
      name += ' wethod-accordion--expanded';
    }
    return name;
  }

  getIconClassName() {
    let name = 'wethod-accordion__status-icon';
    if (this.state.expanded) {
      name = `${name} ${name}--expanded`;
    }
    return name;
  }

  getContentWrapperStyle() {
    return {
      overflowX: this.props.overflowXHidden ? 'hidden' : 'auto',
      minWidth: this.props.overflowXHidden ? 'fit-content' : '',
    };
  }

  toggleExpanded() {
    this.setState((prevState) => ({ expanded: !prevState.expanded }));
  }

  render() {
    return (
      <div className={this.getClassName()}>
        <div
          role="button"
          tabIndex={0}
          aria-expanded={this.props.expanded}
          className="wethod-accordion__button"
          onClick={this.onButtonClick}
          onKeyUp={this.onKeyUp}>
          <div className="wethod-accordion__actions">{this.props.actions}</div>
          <div className="wethod-accordion__label">{this.props.label}</div>
          <div className="wethod-accordion__summary">{this.props.summary}</div>
          <ShowIf condition={this.props.showStatusIcon}>
            <div className={this.getIconClassName()} data-testid="accordion-status-icon">
              <Icon />
            </div>
          </ShowIf>
        </div>
        <CollapseTransition triggered={this.state.expanded}>
          <div className="wethod-accordion__content-wrapper" style={this.getContentWrapperStyle()}>
            <div className="wethod-accordion__content">
              {this.props.children}
            </div>
          </div>
        </CollapseTransition>
      </div>
    );
  }
}

Accordion.defaultProps = {
  expanded: false,
  label: null,
  actions: null,
  summary: null,
  className: null,
  showStatusIcon: true,
  onChange: null,
  overflowXHidden: false,
};

Accordion.propTypes = {
  /** Node to use as content * */
  children: PropTypes.node.isRequired,
  expanded: PropTypes.bool,
  label: PropTypes.node,
  className: PropTypes.string,
  /** Node containing actions, placed on the label left * */
  actions: PropTypes.node,
  /** Node containing a summary of accordion content, placed between label and status icon * */
  summary: PropTypes.node,
  showStatusIcon: PropTypes.bool,
  /**
   * Callback fired when the expand/collapse state is changed.
   *
   * @param {boolean} expanded True if accordion is expaned
   * * */
  onChange: PropTypes.func,
  /** Boolean that express whether the accordion x scroll is hidden or is active * */
  overflowXHidden: PropTypes.bool,
};

module.exports = Accordion;
