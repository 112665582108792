const ChartConfigFilter = require('./ChartConfigFilter');

class ChartConfigFilterJobOrderCategory extends ChartConfigFilter {
  constructor(type) {
    super(ChartConfigFilterJobOrderCategory.TARGET, type);
  }
}

ChartConfigFilterJobOrderCategory.TARGET = 'job-order-category';

module.exports = ChartConfigFilterJobOrderCategory;
