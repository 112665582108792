const React = require('react');
const Task = require('../Task.react');
const PrimaryInfo = require('../TaskPrimaryInfo.react');
const MoreOptions = require('../../MoreOptionsButton/MoreOptionsButton.react');
const MoreOptionsMenuItem = require('../../MoreOptionsButton/MoreOptionsMenuItem.react');
const ShowIf = require('../../../../../../../../common/react/ShowIf/ShowIf.react');
const PriceListDelta = require('../../../../containers/Calculator/Task/PriceListDelta');

/**
 * Component used to show pricelist for a budget area.
 * @param areaId - ID of the Area
 * @param name - Name of the pricelist
 * @param column - Column where the component is placed ("left" | "center" | "right")
 * @param editable - Tells if the budget is editable so the pricelist
 * @param isDefault - Tells if the pricelist is the default one
 * @param showPricelistModal - Function to show the pricelist modal
 * @param was - Previous pricelist
 * @param created - Tells if the pricelist was created
 * @param deleted - Tells if the pricelist was deleted
 * @returns {JSX.Element}
 * @constructor
 */
module.exports = class PriceList extends React.Component {
  constructor(props) {
    super(props);
    this.handleShowPriceListModal = this.handleShowPriceListModal.bind(this);
  }

  handleShowPriceListModal() {
    this.props.showPricelistModal(this.props.areaId, this.props.priceList?.id);
  }

  render() {
    if (this.props.column === 'left') {
      return (
        <div className="wethod-budget-area__pricelist__container">
          <div className="wethod-budget-area__pricelist__sub_container">
            <div className="wethod-budget-area__pricelist__label">Pricelist:</div>
            <div className="wethod-budget-area__pricelist__name">
              {this.props.isDefault ? 'Default' : this.props.priceList?.name}
            </div>
            <PriceListDelta
              value={this.props.priceList}
              prevValue={this.props.was} />
          </div>
        </div>
      );
    }
    if (this.props.column === 'center') {
      return (
        <Task column={this.props.column}>
          <PrimaryInfo />
        </Task>
      );
    }
    return (
      <Task column={this.props.column}>
        <PrimaryInfo className="wethod-budget-area__pricelist__options">
          <ShowIf condition={this.props.editable}>
            <MoreOptions editable>
              <MoreOptionsMenuItem onClick={this.handleShowPriceListModal}>
                Set pricelist
              </MoreOptionsMenuItem>
            </MoreOptions>
          </ShowIf>
        </PrimaryInfo>
      </Task>
    );
  }
};
