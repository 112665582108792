/* eslint-disable no-shadow */
const React = require('react');
const Table = require('../../../../../../common/react/Table2/Table2.react');
const TableCell = require('../../../../../../common/react/Table2/TableCell.react');
const TableHead = require('../../../../../../common/react/Table2/TableHead.react');
const TableRow = require('../../../../../../common/react/Table2/TableRow.react');
const TableBody = require('../../../../../../common/react/Table2/TableBody.react');
const Project = require('../containers/Project');
const ModeTag = require('./PSModeTag.react');
const Loader = require('../../../../../../common/react/Loader/Loader.react');
const PermissionManager = require('../containers/PermissionManager');

module.exports = class Projects extends React.Component {
  handleSort(col, order) {
    if (!this.props.isWaiting) {
      this.props.sortProjects(col, order);
    }
  }

  getSort(name) {
    if (this.props.sortBy.col === name) {
      return this.props.sortBy.order;
    }
    return null;
  }

  getEmptyContent() {
    if (this.props.isWaiting) return <Loader />;
    return (
      <span>
        There's nothing here! What about
        <a href="/#pipeline/projects"> starting a new project</a>?
      </span>
    );
  }

  getProjects() {
    if (!this.props.isWaiting) {
      const projectTypes = { 'days-left': [], progress: [], 'time-based': [] };
      this.props.projects.forEach((project) => {
        const risk = this.props.availableRisks.filter((risk) => risk.id === project.risk)[0];
        const accountId = project.account ? project.account.id : null;
        projectTypes[project.project.project_status_mode]
          .push(
            <PermissionManager key={project.project.id}
              projectManagerId={project.pm.id}
              accountId={accountId}
              reasonCannotEdit={project.reason_cannot_edit}>
              <Project
                isWaiting={this.props.isWaitingForProject(project.project.id)}
                {...project}
                risk={risk} />
            </PermissionManager>,
          );
      });
      let projects = [];

      if (projectTypes['days-left'].length > 0) {
        projects = projects.concat(<ModeTag key="days-left" mode="Work days to complete" />)
          .concat(projectTypes['days-left']);
      }
      if (projectTypes.progress.length > 0) {
        projects = projects.concat(<ModeTag key="progress" mode="Completed percentage" />)
          .concat(projectTypes.progress);
      }
      if (projectTypes['time-based'].length > 0) {
        projects = projects.concat(<ModeTag key="time-based" mode="Auto" />)
          .concat(projectTypes['time-based']);
      }

      return projects;
    } return [];
  }

  render() {
    return (
      <Table columns={7} maxHeight={this.props.tableHeight} search={this.props.search}>
        <TableHead>
          <TableRow>
            <TableCell className="project-status__column--color" />
            <TableCell className="project-status__column--project"
              onSort={this.handleSort.bind(this)}
              sort={this.getSort('name')}
              name="name">
              Project
            </TableCell>
            <TableCell className="project-status__column--pm hidden-mobile">PM</TableCell>
            <TableCell
              className="project-status__column--month-production project-status__column--month-production-header hidden-mobile">
              This
              month production
            </TableCell>
            <TableCell className="project-status__column--days-left">Days Left</TableCell>
            <TableCell className="project-status__column--progress">Progress</TableCell>
            <TableCell className="project-status__column--last-progress hidden-mobile"
              onSort={this.handleSort.bind(this)}
              sort={this.getSort('last')}
              name="last">
              Last was
            </TableCell>
            <TableCell className="project-status__column--risk">Risk</TableCell>
            <TableCell className="project-status__column--edit-area" />
          </TableRow>
        </TableHead>
        <TableBody
          hasMore={this.props.hasMore}
          loadMore={this.props.loadMore}
          empty={this.getEmptyContent()}>
          {this.getProjects()}
        </TableBody>
      </Table>
    );
  }
};
