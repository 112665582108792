const React = require('react');
const PropTypes = require('prop-types');
const { Transition } = require('react-transition-group');

require('./style.scss');

class CollapseTransition extends React.Component {
  constructor(props) {
    super(props);

    this.contentRef = null;
    this.handleEnter = this.handleEnter.bind(this);
    this.handleEntered = this.handleEntered.bind(this);
    this.handleEntering = this.handleEntering.bind(this);
    this.handleExit = this.handleExit.bind(this);
    this.handleExiting = this.handleExiting.bind(this);
    this.setContentRef = this.setContentRef.bind(this);
  }

  handleEnter() {
    this.contentRef.style.height = 0;
  }

  handleEntering() {
    const contentHeight = this.contentRef ? this.contentRef.scrollHeight : 0;

    this.contentRef.style.height = `${contentHeight}px`;
    this.contentRef.style.transitionDuration = `${contentHeight / this.props.timeout}s`;
  }

  handleEntered() {
    this.contentRef.style.height = 'auto';
  }

  handleExit() {
    const contentHeight = this.contentRef ? this.contentRef.scrollHeight : 0;
    this.contentRef.style.height = `${contentHeight}px`;
  }

  handleExiting() {
    this.contentRef.style.height = 0;
  }

  setContentRef(ref) {
    this.contentRef = ref;
  }

  getClassName() {
    return `wethod-transition-collapse ${this.props.className}`;
  }

  render() {
    return (
      <div
        className={this.getClassName()}>
        <Transition in={this.props.triggered}
          onEnter={this.handleEnter}
          onEntered={this.handleEntered}
          onEntering={this.handleEntering}
          onExit={this.handleExit}
          onExited={this.handleExiting}
          timeout={this.props.timeout}
          appear
          mountOnEnter={false}
          unmountOnExit={false}>
          <div ref={this.setContentRef} className="wethod-transition-collapse__content" style={{ visibility: this.props.triggered ? 'visible' : 'hidden' }}>
            {this.props.children}
          </div>
        </Transition>
      </div>
    );
  }
}

CollapseTransition.defaultProps = {
  timeout: 300,
  className: '',
};

CollapseTransition.propTypes = {
  /**
   * Element to animate.
   */
  children: PropTypes.node.isRequired,
  /**
   * The duration of the transition, in milliseconds.
   */
  timeout: PropTypes.number,
  /**
   * Show the component; triggers the enter or exit states
   */
  triggered: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

module.exports = CollapseTransition;
