const ChartConfigProbabilityDimensionGrouping = require('./ChartConfigProbabilityDimensionGrouping');
const Value = require('./ChartConfigProbabilityDimensionGroupingValue');

class ChartConfigProbabilityDimensionClusterGrouping extends
  ChartConfigProbabilityDimensionGrouping {
  /**
   * @param {ChartConfigProbabilityDimensionGroupingValue[]} values
   */
  constructor(values) {
    super(ChartConfigProbabilityDimensionClusterGrouping.TYPE);
    this.values = values;
  }

  /**
   * Return item with given name.
   * @param {string} name
   * @return {ChartConfigProbabilityDimensionGroupingValue}
   */
  findItem(name) {
    return this.values.find((value) => value.name === name);
  }

  /**
   * @param data
   * @return {ChartConfigProbabilityDimensionClusterGrouping}
   */
  static fromJSON(data) {
    const values = data.values.map(Value.fromJSON);
    return new ChartConfigProbabilityDimensionClusterGrouping(values);
  }

  clone() {
    return new ChartConfigProbabilityDimensionClusterGrouping(this.values);
  }
}

ChartConfigProbabilityDimensionClusterGrouping.TYPE = 'cluster';

module.exports = ChartConfigProbabilityDimensionClusterGrouping;
