const ChartConfigDimension = require('../ChartConfigDimension');
const ChartConfigClientDimensionDistinctGrouping = require('./ChartConfigClientDimensionDistinctGrouping');
const DistinctGrouping = require('./ChartConfigClientDimensionDistinctGrouping');
const TopNGrouping = require('./ChartConfigClientDimensionTopNGrouping');

class ChartConfigClientDimension extends ChartConfigDimension {
  /**
   * @param {ChartConfigClientDimensionGrouping} grouping
   */
  constructor(grouping = new ChartConfigClientDimensionDistinctGrouping()) {
    super(ChartConfigClientDimension.KEY);
    this.grouping = grouping;
  }

  /**
   * Build and return client dimension grouping based on given data.
   * @param data
   * @return {ChartConfigClientDimensionDistinctGrouping|ChartConfigClientDimensionTopNGrouping}
   */
  static groupingFromJSON(data) {
    switch (data.type) {
      case DistinctGrouping.TYPE:
        return DistinctGrouping.fromJSON();
      case TopNGrouping.TYPE:
        return TopNGrouping.fromJSON(data.value);
      default:
        throw new Error(`'${data.type}' is not a valid client dimension grouping type`);
    }
  }

  /**
   * Build and return client dimension based on given data.
   * @param data
   * @return {ChartConfigClientDimension}
   */
  static fromJSON(data) {
    return new ChartConfigClientDimension(ChartConfigClientDimension
      .groupingFromJSON(data.grouping));
  }

  clone() {
    return new ChartConfigClientDimension(this.grouping.clone());
  }
}

ChartConfigClientDimension.KEY = 'client';

module.exports = ChartConfigClientDimension;
