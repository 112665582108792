const request = require('./Request');

const BudgetPriceListLevel = {
  // TODO: update naming of the method which updates the visibility of the level
  update(levelId, isVisible, budgetId) {
    return request({
      method: 'patch',
      url: `/budget/${budgetId}/budget-price-list-level/${levelId}/visibility`,
      data: {
        is_visible: isVisible,
      },
    });
  },
};

module.exports = BudgetPriceListLevel;
