const Grouping = require('./ChartConfigAccountDimensionGrouping');

class ChartConfigAccountDimensionDistinctGrouping extends Grouping {
  constructor() {
    super(ChartConfigAccountDimensionDistinctGrouping.TYPE);
  }

  /**
   * @return {ChartConfigAccountDimensionDistinctGrouping}
   */
  static fromJSON(data) {
    return new ChartConfigAccountDimensionDistinctGrouping(data);
  }

  // eslint-disable-next-line class-methods-use-this
  isGrouped() {
    return false;
  }

  // eslint-disable-next-line class-methods-use-this
  clone() {
    return new ChartConfigAccountDimensionDistinctGrouping();
  }
}

ChartConfigAccountDimensionDistinctGrouping.TYPE = 'distinct';

module.exports = ChartConfigAccountDimensionDistinctGrouping;
