const React = require('react');
const PropTypes = require('prop-types');
const Checkbox = require('../Checkbox/Checkbox.react');
const SelectItem = require('./SelectItem.react');

const MultipleSelectItem = ({ value, selected, onClick, children, ...rest }) => (
  <SelectItem value={value} className="wethod-menu__item-multiple" onClick={onClick} {...rest}>
    <Checkbox label={children} checked={selected} />
  </SelectItem>
);

MultipleSelectItem.defaultProps = {
  onClick: null,
  selected: false,
};

MultipleSelectItem.propTypes = {
  /**
   * Item content.
   * @see SelectItem
   */
  children: PropTypes.node.isRequired,
  /**
   * Function called when item is clicked.
   * @see SelectItem
   */
  onClick: PropTypes.func,
  /**
   * A value which uniquely identifies this item.
   * @see SelectItem
   */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  /** True if item has been selected * */
  selected: PropTypes.bool,
};

module.exports = MultipleSelectItem;
