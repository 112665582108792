const SummaryNode = require('./Node/SummaryNode');
const AreaNode = require('./Node/AreaNode');
const ContingencyTaskNode = require('./Node/ContingencyTaskNode');
const DiscountTaskNode = require('./Node/DiscountTaskNode');
const BaseTaskNode = require('./Node/BaseTaskNode');
// eslint-disable-next-line no-unused-vars
const TaskNode = require('./Node/TaskNode');
const LevelAmountNode = require('./Node/LevelAmountNode');
const AvailableLevel = require('./AvailableLevel');
const constants = require('../constants');
const JobTitleNode = require('./Node/JobTitleNode');
const ProductTaskNode = require('./Node/ProductTaskNode');

/**
 * Calculator is primarily a list of summary, each summary can depends on another one for its calculation.
 * A summary contains:
 * - Its data (name, id, ecc.)
 * - Areas
 * - Subtotal: total of another summary needed by this summary for calculation (can be null)
 *
 * Each area contains:
 * - Its data
 * - Tasks
 *
 * Each task contains:
 * - Its data
 * - List of level amounts
 * - List of its job titles
 *
 * Each level amount contains:
 * - Its data
 */
module.exports = class CalculatorStructure {
  constructor(
    summaries,
    areas,
    tasks,
    levelAmounts,
    availableLevels,
    budgetJobTitles = [],
    budgetPriceListLevels,
  ) {
    this.summaries = summaries;
    this.areas = areas.sort(CalculatorStructure.compareAreas);
    this.tasks = tasks.sort(CalculatorStructure.compareTasks);
    this.budgetPricelistLevels = budgetPriceListLevels;
    // TODO on contract rename to "days"
    this.levelAmounts = levelAmounts;
    /** @var {AvailableLevel[]} */
    this.availableLevels = availableLevels.map(
      (level) => new AvailableLevel({
        ...level,
        sort: budgetPriceListLevels
          ? budgetPriceListLevels.find((lvl) => lvl.level.id === level.level.id).sort
          : level.sort,
      }),
    ).sort(CalculatorStructure.compareLevels);

    // Job titles available for the current budget version
    this.budgetJobTitles = budgetJobTitles;

    this.summaryMap = CalculatorStructure.getMapFromArray(this.summaries, 'id');

    this.tree = this.buildSummary('total');
  }

  /**
   * Transforms the given array into an object map using the give key.
   * @param array
   * @param key
   * @private
   * @returns {{}}
   */
  static getMapFromArray(array, key) {
    const map = {};
    array.forEach((item) => map[item[key]] = item);
    return map;
  }

  /**
   * Return available level with given id.
   * @private
   * @param id
   * @returns {*}
   */
  getAvailableLevel(id) {
    const found = this.availableLevels.filter((level) => level.getId() === id);
    return found.length ? found[0] : null;
  }

  /**
   * Return available level with given price list level id.
   * @param id
   * @returns {AvailableLevel|null}
   */
  getAvailableLevelByPriceListLevelId(id) {
    const found = this.availableLevels
      .filter((level) => level.getPriceListLevelId() === id);
    if (found.length) {
      found[0].order = this.getAvailableLevel(found[0].getId()).getOrder();
    }
    return found.length ? found[0] : null;
  }

  /**
   * Return budget job title with given id.
   * @private
   * @param id
   * @return {{}|null}
   */
  getBudgetJobTitle(id) {
    const found = this.budgetJobTitles.filter((jobTitle) => jobTitle.id === id);

    return found.length ? found[0] : null;
  }

  /**
   * Return data related to summary with given id.
   * @private
   * @param summaryId
   * @returns {*}
   */
  getSummaryData(summaryId) {
    return this.summaryMap[summaryId];
  }

  /**
   * Return data related to areas belonging to summary with given id.
   * @private
   * @param summary
   * @returns {[]}
   */
  getSummaryAreasData(summary) {
    return this.areas.filter((area) => summary.area_types.includes(area.type));
  }

  /**
   * Return data related to tasks belonging to area with given id.
   * @private
   * @param areaId
   * @returns {[]}
   */
  getAreaTasksData(areaId) {
    return this.tasks.filter((task) => task.area.uid === areaId);
  }

  /**
   * Return data related to level amounts belonging to task with given id.
   * Include level amounts related to task's job titles.
   * Include related price list level.
   * @private
   * @param taskId
   * @returns {[]}
   */
  getTaskLevelAmounts(taskId) {
    return this.levelAmounts
      .filter((amount) => amount.task.uid === taskId)
      .map((amount) => ({
        ...amount,
        budget_price_list_level: this.budgetPricelistLevels
          .find((budgetPriceListLevel) => budgetPriceListLevel
            .id === amount.budget_price_list_level.id
            || budgetPriceListLevel.was?.id === amount.budget_price_list_level.id),
      }));
  }

  /**
   * Build level amounts for task with given id.
   * Level amounts related to job titles are excluded.
   * @private
   * @param taskId
   * @returns {LevelAmount[]}
   */
  buildTaskLevelAmounts(taskId) {
    const amounts = this.getTaskLevelAmounts(taskId);
    const amountsWithJobTitle = amounts.filter((amount) => !amount.budget_job_title);
    return amountsWithJobTitle
      .map((amount) => {
        const data = this.getAvailableLevelByPriceListLevelId(amount.budget_price_list_level.id);
        return new LevelAmountNode(amount, data);
      }).sort(CalculatorStructure.compareLevelAmounts);
  }

  /**
   * Build job titles for task with given id.
   * @param taskId
   * @return {JobTitle[]}
   */
  buildTaskJobTitles(taskId) {
    return this.getTaskLevelAmounts(taskId)
      .filter((amount) => amount.budget_job_title)
      .map((amount) => {
        const budgetJobTitleId = amount.budget_job_title ? amount.budget_job_title.id : null;
        const levelData = this
          .getAvailableLevelByPriceListLevelId(amount.budget_price_list_level.id);
        const jobTitle = this.getBudgetJobTitle(budgetJobTitleId);
        const levelAmountNode = new LevelAmountNode(amount, levelData);

        return new JobTitleNode(jobTitle, levelAmountNode);
      });
  }

  /**
   * @private
   * @param areaId
   * @param {SummaryNodeTotal|null} subtotal
   * @param {AvailableLevel[]} availableLevels
   * @returns {TaskNode[]}
   */
  buildAreaTasks(areaId, subtotal, availableLevels) {
    return this.getAreaTasksData(areaId)
      .map((task) => {
        const levelAmounts = this.buildTaskLevelAmounts(task.uid);
        const taskJobTitles = this.buildTaskJobTitles(task.uid);

        switch (task.type) {
          case constants.TASK_TYPE_CONTINGENCY:
            return new ContingencyTaskNode(
              task,
              levelAmounts,
              subtotal,
              availableLevels,
            );
          case constants.TASK_TYPE_DISCOUNT:
            return new DiscountTaskNode(
              task,
              levelAmounts,
              subtotal,
              availableLevels,
            );
          case constants.TASK_TYPE_PRODUCT:
            return new ProductTaskNode(
              task,
              levelAmounts,
              availableLevels,
            );
          default:
            return new BaseTaskNode(
              task,
              levelAmounts,
              availableLevels,
              taskJobTitles,
            );
        }
      });
  }

  /**
   * Compare two numbers to sort them from the lower to the higher.
   * Use this as callback for a .sort() function.
   * @param {AvailableLevel} a
   * @param {AvailableLevel} b
   * @return {number}
   */
  static compareLevels(a, b) {
    if (a.getOrder() < b.getOrder()) {
      return -1;
    }
    if (a.getOrder() > b.getOrder()) {
      return 1;
    }
    return 0;
  }

  /**
   * Compare two level amount objects to sort them from the lower to the higher.
   * Use this as callback for a .sort() function.
   * @param a
   * @param b
   * @return {number}
   */
  static compareLevelAmounts(a, b) {
    return a.getOrder() - b.getOrder();
  }

  /**
   * Compare two areas object to sort them from the lower to the higher.
   * Use this as callback for a .sort() function.
   * @param a
   * @param b
   * @return {number}
   */
  static compareAreas(a, b) {
    return a.sort - b.sort;
  }

  /**
   * Compare two tasks object to sort them from the lower to the higher.
   * Use this as callback for a .sort() function.
   * @param a
   * @param b
   * @return {number}
   */
  static compareTasks(a, b) {
    return a.sort - b.sort;
  }

  /**
   * TODO maybe BE can change format to avoid this formatter
   * Return given pricelistLevel formatted as levelAmount.
   * @param pricelistLevel
   * @return {{level_short_name: string, level_name: string, is_visible, visible, level, level_cost: number, id, sort: number, level_price: number}}
   */
  formatPricelistLevel(pricelistLevel) {
    return {
      id: pricelistLevel.id,
      level: pricelistLevel.level,
      level_name: pricelistLevel.name,
      level_short_name: pricelistLevel.short_name,
      level_cost: pricelistLevel.cost,
      level_price: pricelistLevel.price,
      is_visible: pricelistLevel.is_visible,
      sort: this.getAvailableLevel(pricelistLevel.level.id).getOrder(),
    };
  }

  /**
   * Return priceListLevels related to the area with given price list.
   * @param priceListId
   * @return {*}
   */
  getPricelistLevelsForArea(priceListId) {
    const priceListLevels = this.budgetPricelistLevels
      .filter((level) => level.price_list.id === priceListId);

    return priceListLevels.map(this.formatPricelistLevel.bind(this));
  }

  /**
   * @private
   * @param summary
   * @param {Summary|null} subtotal
   * @returns {AreaNode[]}
   */
  buildSummaryAreas(summary, subtotal) {
    return this.getSummaryAreasData(summary)
      .map((area) => {
        const subtotalValue = subtotal ? subtotal.getTotal() : null;
        const priceListLevels = this.getPricelistLevelsForArea(area.price_list.id)
          .map(
            (level) => new AvailableLevel(level),
          )
          .sort(CalculatorStructure.compareLevels);
        const tasks = this.buildAreaTasks(area.uid, subtotalValue, priceListLevels);
        return new AreaNode(area, tasks, this.availableLevels);
      });
  }

  /**
   * @private
   * @returns {Summary}
   */
  buildSummary(id) {
    const data = this.getSummaryData(id);
    const subtotal = data.subtotal_id ? this.buildSummary(data.subtotal_id) : null;
    const areas = this.buildSummaryAreas(data, subtotal);
    return new SummaryNode(
      data,
      areas,
      subtotal,
      this.availableLevels,
    );
  }

  /**
   * Return list of level titles.
   * @return {{cost: *, acronym: *, price: *, name: *}[]}
   */
  getLevels() {
    return this.availableLevels.map((level) => ({
      id: level.getId(),
      name: level.getName(),
      acronym: level.getAcronym(),
      price: level.getPrice(),
      cost: level.getCost(),
    }));
  }

  /**
   * Return structure formatted as JSON, this way it can be easily used to render components.
   * @returns {[]}
   */
  toJson() {
    return this.tree.toJson();
  }
};
