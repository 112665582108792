/* eslint-disable react/sort-comp,class-methods-use-this */
const React = require('react');
const { CSSTransition } = require('react-transition-group');
const TableSearch = require('../../../../../../common/react/Table2/TableSearch.react');
const WeekSelector = require('../containers/WeekSelector');
const TypeSwitcher = require('../containers/TypeSwitcher');
const MobileTypeSelect = require('../containers/MobileTypeSelect');
const Projects = require('./Projects.react');
const SectionHeader = require('../../../../../../common/react/SectionHeader/components/SectionHeader.react');
const Modal = require('../containers/Modal');
const RiskMenu = require('../containers/RiskMenu');
const Sidebar = require('../containers/sidebar/ProjectStatusSidebar');

module.exports = class ProjectStatus extends React.Component {
  constructor(props) {
    super(props);

    this.state = { tableHeight: null, page: 0, size: 0 };
  }

  getTableMaxHeight() {
    return window.innerHeight - (Wethod.getHeaderHeight() + $('.wethod-section-actions').outerHeight(true) + 24);
  }

  updateTableHeight() {
    this.setState({ tableHeight: this.getTableMaxHeight() });
  }

  componentDidMount() {
    this.props.getProjects(this.props.date, this.props.ownership);
    this.props.getInternationalCalendarSettings();
    this.updateTableHeight();
    dispatcher.trigger('tour:start', this.props.idTour);
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.date.isSame(this.props.date)) {
      this.props.getProjects(this.props.date, this.props.ownership);
    }
  }

  getLoadedProjects() {
    return this.props.projects.slice(0, this.state.page * this.state.size);
  }

  hasMore() {
    const loaded = this.state.page * this.state.size;
    return this.props.projects.length > loaded;
  }

  loadMore(size, page) {
    this.setState({ page, size });
  }

  filter(keyword) {
    this.setState({ page: 1 });
    this.props.filterProjects(keyword);
  }

  getSearch() {
    const style = this.props.projectFilterId ? 'wethod-highlighter--search-keyword' : '';
    return (
      <TableSearch
        className={style}
        placeholder="Search for project, client, pm or job order"
        keyword={this.props.keyword}
        filter={this.filter.bind(this)} />
    );
  }

  isWaitingForProject(id) {
    return this.props.isSaving.filter((key) => key === id).length > 0;
  }

  render() {
    return (
      <div className={`project-status project-status-${this.props.ownership} wethod-section-body`}>
        <div className="project-status__header wethod-section-actions">
          <SectionHeader
            current_section="Project Status"
            preview_anchor_id="project-status"
            tour_id={74342}
            tour_start_page="project-status"
            helper_url="friday/index/#project-status" />
          <div className="project-status__actions">
            <WeekSelector />
            <TypeSwitcher />
            <MobileTypeSelect />
          </div>
        </div>
        <Projects
          tableHeight={this.state.tableHeight}
          date={this.props.date}
          projects={this.getLoadedProjects()}
          search={this.getSearch()}
          hasMore={this.hasMore()}
          loadMore={this.loadMore.bind(this)}
          isWaiting={this.props.isWaiting}
          isWaitingForProject={this.isWaitingForProject.bind(this)}
          internationalCalendar={this.props.internationalCalendar}
          updateHours={this.props.updateHours}
          availableRisks={this.props.availableRisks}
          deleteHours={this.props.deleteHours}
          sortBy={this.props.sortBy}
          sortProjects={this.props.sortProjects} />
        <RiskMenu />
        <Modal />
        <CSSTransition
          in={this.props.showSidebar}
          classNames="wethod-sidebar--transition"
          timeout={400}
          mountOnEnter
          unmountOnExit>
          <Sidebar />
        </CSSTransition>
      </div>
    );
  }
};
