const React = require('react');
const PropTypes = require('prop-types');
const Radio = require('../../../inputs/RadioGroup/Radio.react');
const InputValidator = require('../../../InputValidator/InputValidator.react');
const NumericField = require('../../../inputs/NumericField/OutlinedNumericField/OutlinedNumericField.react');

const ChartSidebarDimensionTopNGroupingOption = ({
  id, name, onClick, updateErrors,
  checked, value, topNValue, onChangeTopNValue, getError,
}) => {
  const getValueInput = () => {
    if (checked) {
      const inputName = `${name}-option-top-value`;
      return (
        <div className="wethod-chart-sidebar__dimension-option-attribute-top-input">
          <InputValidator updateErrors={updateErrors} constraints={['required', 'positive']}>
            <NumericField label="Top"
              id={inputName}
              value={topNValue}
              onChange={onChangeTopNValue}
              name={inputName}
              errorText={getError(inputName)} />
          </InputValidator>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="wethod-chart-sidebar__dimension-option">
      <Radio id={id}
        name={name}
        checked={checked}
        onClick={onClick}
        value={value}
        label="Show top and group others" />
      <div className="wethod-chart-sidebar__dimension-option-attributes">
        {getValueInput()}
      </div>
    </div>
  );
};

ChartSidebarDimensionTopNGroupingOption.defaultProps = {
  id: '',
  name: '',
  checked: false,
  onClick: null,
};

ChartSidebarDimensionTopNGroupingOption.propTypes = {
  /**
   * Radio ID.
   */
  id: PropTypes.string,
  /**
   * Radio button name.
   */
  name: PropTypes.string,
  /**
   * It says whether or not this radio button is checked.
   */
  checked: PropTypes.bool,
  /**
   * Function to call when radio is clicked.
   * @param event {{}}
   */
  onClick: PropTypes.func,
  /**
   * Function to call when an input is validated.
   */
  updateErrors: PropTypes.func.isRequired,
  /**
   * Dimension group value.
   */
  topNValue: PropTypes.number.isRequired,
  /**
   * Dimension group type.
   */
  value: PropTypes.string.isRequired,
  /**
   * Function to call when dimension group value is changed.
   */
  onChangeTopNValue: PropTypes.func.isRequired,
  /**
   * Function that returns errors related to given input name.
   */
  getError: PropTypes.func.isRequired,
};

module.exports = ChartSidebarDimensionTopNGroupingOption;
