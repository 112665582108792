const React = require('react');
const PropTypes = require('prop-types');
const MenuItem = require('../../../../../../../common/react/Menu/ListMenu/MenuItem.react');

const SeeMoreButtonOption = ({ id, children, onClick }) => {
  function handleClick() {
    onClick(id);
  }
  return (
    <MenuItem key={id} onClick={handleClick}>{children}</MenuItem>
  );
};

SeeMoreButtonOption.defaultProps = {};

SeeMoreButtonOption.propTypes = {
  id: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

module.exports = SeeMoreButtonOption;
