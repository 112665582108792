module.exports = class ChartConfigJobOrderCategoryDimensionGroupingValue {
  /**
   * @param {string} name
   * @param {string[]} values
   */
  constructor(name, values) {
    this.name = name;
    this.values = values;
  }

  /**
   * @param data
   * @return {ChartConfigJobOrderCategoryDimensionGroupingValue}
   */
  static fromJSON(data) {
    return new ChartConfigJobOrderCategoryDimensionGroupingValue(data.name, data.values);
  }
};
