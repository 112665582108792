const request = require('./Request');

const ProjectType = {
  list() {
    return request({
      method: 'get',
      url: '/projectlabel',
    });
  },
};

module.exports = ProjectType;
