const React = require('react');

const CheckboxFullIcon = () => (
  <svg className="wethod-checkbox__icon wethod-checkbox__icon--full"
    focusable="false"
    viewBox="0 0 16 16"
    fill="none">
    <rect x="0.5" y="0.5" width="15" height="14.8997" rx="1.5" fill="#212121" />
    <path d="M13.7818 3.11207C13.7313 3.0706 13.673 3.03953 13.6104 3.02078C13.5478 3.00204 13.4821 2.99584 13.4171 3.00273C13.3521 3.00961 13.2891 3.02947 13.2318 3.06091C13.1745 3.09236 13.124 3.13478 13.0833 3.18591L6.18009 11.6341L2.89286 7.05659C2.81552 6.95118 2.70006 6.88026 2.5711 6.85876C2.44214 6.83727 2.30989 6.867 2.20254 6.94163C2.1501 6.97987 2.10573 7.02805 2.07197 7.08348C2.03821 7.1389 2.01573 7.20052 2.0058 7.26465C1.99588 7.32879 1.99871 7.39423 2.01413 7.45726C2.02956 7.5203 2.05728 7.57972 2.09571 7.63202L5.78562 12.7272C5.78562 12.7272 5.78562 12.7766 5.84315 12.793C5.86484 12.7974 5.8872 12.7974 5.90889 12.793H5.9582C6.02042 12.8201 6.08757 12.8339 6.15544 12.8339V12.8339C6.20573 12.8657 6.26144 12.8881 6.3198 12.8997H6.3691H6.42663L6.47594 12.8422C6.48942 12.8456 6.50355 12.8456 6.51703 12.8422L13.8311 3.90098C13.8882 3.85044 13.9329 3.78743 13.962 3.71699C13.9911 3.64655 14.0038 3.57055 13.999 3.49449C13.9943 3.41842 13.9722 3.34447 13.9346 3.2782C13.897 3.21193 13.8447 3.15511 13.7818 3.11207V3.11207Z"
      fill="white" />
    <rect x="0.5" y="0.5" width="15" height="14.8997" rx="1.5" stroke="#212121" />
  </svg>
);

module.exports = CheckboxFullIcon;
