const ChartConfigJobOrderCategoryDimensionGrouping = require('./ChartConfigJobOrderCategoryDimensionGrouping');
const Value = require('./ChartConfigJobOrderCategoryDimensionGroupingValue');

class ChartConfigJobOrderCategoryDimensionClusterGrouping extends
  ChartConfigJobOrderCategoryDimensionGrouping {
  /**
   * @param {ChartConfigJobOrderCategoryDimensionGroupingValue[]} values
   */
  constructor(values) {
    super(ChartConfigJobOrderCategoryDimensionClusterGrouping.TYPE);
    this.values = values;
  }

  /**
   * Return item with given name.
   * @param {string} name
   * @return {ChartConfigJobOrderCategoryDimensionGroupingValue}
   */
  findItem(name) {
    return this.values.find((value) => value.name === name);
  }

  /**
   * @param data
   * @return {ChartConfigJobOrderCategoryDimensionClusterGrouping}
   */
  static fromJSON(data) {
    const values = data.values.map(Value.fromJSON);
    return new ChartConfigJobOrderCategoryDimensionClusterGrouping(values);
  }

  clone() {
    return new ChartConfigJobOrderCategoryDimensionClusterGrouping(this.values);
  }
}

ChartConfigJobOrderCategoryDimensionClusterGrouping.TYPE = 'cluster';

module.exports = ChartConfigJobOrderCategoryDimensionClusterGrouping;
