const ChartConfigDimension = require('../ChartConfigDimension');
const DistinctGrouping = require('./ChartConfigAccountDimensionDistinctGrouping');
const TopNGrouping = require('./ChartConfigAccountDimensionTopNGrouping');

class ChartConfigAccountDimension extends ChartConfigDimension {
  /**
   * @param {ChartConfigAccountDimensionGrouping} grouping
   */
  constructor(grouping = new DistinctGrouping()) {
    super(ChartConfigAccountDimension.KEY);
    this.grouping = grouping;
  }

  /**
   * Build and return account dimension grouping based on given data.
   * @param data
   * @return {ChartConfigAccountDimensionGrouping}
   */
  static groupingFromJSON(data) {
    switch (data.type) {
      case DistinctGrouping.TYPE:
        return DistinctGrouping.fromJSON();
      case TopNGrouping.TYPE:
        return TopNGrouping.fromJSON(data.value);
      default:
        throw new Error(`'${data.type}' is not a valid account dimension grouping type`);
    }
  }

  /**
   * Build and return account dimension based on given data.
   * @param data
   * @return {ChartConfigAccountDimension}
   */
  static fromJSON(data) {
    return new ChartConfigAccountDimension(ChartConfigAccountDimension
      .groupingFromJSON(data.grouping));
  }

  clone() {
    return new ChartConfigAccountDimension(this.grouping.clone());
  }
}

ChartConfigAccountDimension.KEY = 'account';

module.exports = ChartConfigAccountDimension;
