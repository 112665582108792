const React = require('react');
const AdvancedSearchController = require('../../../../../../common/react/AdvancedSearch/AdvancedFiltersController.react');
const TextFilter = require('../../../../../../common/react/AdvancedSearch/filters/TextFilter/TextFilter.react');
const RangeFilter = require('../../../../../../common/react/AdvancedSearch/filters/RangeFilter/NumericRangeFilter/NumericRangeFilter.react');
const ArchivedFilter = require('../../containers/advanced-search/ArchivedFilter');
const BudgetStatusFilter = require('../../containers/advanced-search/BudgetStatusFilter');
const InvoicePlanModeFilter = require('../../containers/advanced-search/InvoicePlanModeFilter');
const JobOrderCategoryFilter = require('./filters/JobOrderCategoryFilter.react');
const ProjectTypeFilter = require('./filters/ProjectTypeFilter.react');
const OwnershipFilter = require('../../containers/advanced-search/OwnershipFilter');
const DurationFilter = require('../../../../../../common/react/AdvancedSearch/filters/DateRangeFilter/DateRangeFilter.react');
const AccountFilter = require('./filters/AccountFilter.react');
const ClientFilter = require('./filters/ClientFilter.react');
const ClientContactFilter = require('./filters/ClientContactFilter.react');
const PmFilter = require('./filters/PmFilter.react');
const ProgramFilter = require('./filters/ProgramFilter.react');
const MetadataFilter = require('./filters/MetadataFilter.react');
const RiskFilter = require('../../containers/advanced-search/RiskFilter');
const BudgetConsumptionFilter = require('./filters/BudgetConsumptionFilter.react');
const ProgressFilter = require('./filters/ProgressFilter.react');
const StageFilter = require('../../containers/advanced-search/StageFilter');
const DayFilter = require('../../../../../../common/react/AdvancedSearch/filters/RangeFilter/DayRangeFilter/DayRangeFilter.react');
const WhitelistEnabledFilter = require('../../containers/advanced-search/WhitelistFilter');

const PipelineAdvancedFilters = ({
  type, errors, updateErrors, availableMetadata,
  ...rest
}) => {
  const probMin = type === 'opportunities' ? 0 : 90;
  const probMax = type === 'opportunities' ? 75 : 100;

  const getMetadataFilters = () => availableMetadata.map((metadata) => {
    const { label, name } = metadata;

    return (
      <MetadataFilter label={label}
        key={label}
        name={name}
        availableValues={metadata.availableValues}
        type={metadata.type} />
    );
  });

  const getStatusLabel = () => {
    if (window.location.hash.includes('opportunities')) {
      return 'Opportunity status';
    }
    return 'Project status';
  };

  return (
    <AdvancedSearchController errors={errors} updateErrors={updateErrors} {...rest}>
      <AccountFilter name="account" label="Account" />
      <ArchivedFilter name="archived" label={getStatusLabel()} />
      <DayFilter name="archived-date" label="Archived on" />
      <BudgetStatusFilter name="budget-status" label="Budget status" />
      <BudgetConsumptionFilter name="budget-consumption" label="Budget consumption" suffix="%" />
      <ClientFilter name="client" label="Client" />
      <ClientContactFilter name="client-contact" label="Client contact" />
      <TextFilter name="po" label="Client PO" placeholder="Type a client PO" />
      <DayFilter name="due-date" label="Due date" />
      <DayFilter name="contract-date" label="Signed on" />
      <RangeFilter name="ext-cost" label="External costs %" suffix="%" />
      <RangeFilter name="ext-cost-value" label="External costs" />
      <InvoicePlanModeFilter name="invoice-plan-mode" label="Invoice plan mode" />
      <TextFilter name="job-order" label="Job order" placeholder="Type a job order" />
      <JobOrderCategoryFilter name="job-order-category" label="Job order category" />
      <RangeFilter name="margin" label="Margin" suffix="%" />
      <TextFilter name="project" label="Name" placeholder="Type a project name" />
      <OwnershipFilter name="ownership" label="Ownership" />
      <PmFilter name="pm" label="PM" />
      <RangeFilter name="prob"
        label="Probability"
        suffix="%"
        min={probMin}
        max={probMax}
        errors={errors}
        updateErrors={updateErrors} />
      <ProgramFilter name="program" label="Program" />
      <ProgressFilter name="progress" label="Progress" suffix="%" />
      <TextFilter name="id" label="Project ID" placeholder="Type an ID" />
      <ProjectTypeFilter name="project-type" label="Project type" />
      <RiskFilter name="risk" label="Risk" />
      <StageFilter name="stage" label="Stage" />
      <DurationFilter name="duration" label="Start/Duration" id="duration" />
      <RangeFilter name="value" label="Value" />
      <WhitelistEnabledFilter name="whitelist" label="Whitelist" />
      {getMetadataFilters()}
    </AdvancedSearchController>
  );
};

module.exports = PipelineAdvancedFilters;
