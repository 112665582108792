const ChartConfigDimension = require('../ChartConfigDimension');
const DistinctGrouping = require('./ChartConfigProjectTypeDimensionDistinctGrouping');
const TopNGrouping = require('./ChartConfigProjectTypeDimensionTopNGrouping');

class ChartConfigProjectTypeDimension extends ChartConfigDimension {
  /**
   * @param {ChartConfigProjectTypeDimensionGrouping} grouping
   */
  constructor(grouping = new DistinctGrouping()) {
    super(ChartConfigProjectTypeDimension.KEY);
    this.grouping = grouping;
  }

  /**
   * Build and return project type dimension grouping based on given data.
   * @param data
   * @return {ChartConfigProjectTypeDimensionGrouping}
   */
  static groupingFromJSON(data) {
    switch (data.type) {
      case DistinctGrouping.TYPE:
        return DistinctGrouping.fromJSON();
      case TopNGrouping.TYPE:
        return TopNGrouping.fromJSON(data.value);
      default:
        throw new Error(`'${data.type}' is not a valid project type dimension grouping type`);
    }
  }

  /**
   * Build and return project type dimension based on given data.
   * @param data
   * @return {ChartConfigProjectTypeDimension}
   */
  static fromJSON(data) {
    return new ChartConfigProjectTypeDimension(ChartConfigProjectTypeDimension
      .groupingFromJSON(data.grouping));
  }

  clone() {
    return new ChartConfigProjectTypeDimension(this.grouping.clone());
  }
}

ChartConfigProjectTypeDimension.KEY = 'project-type';

module.exports = ChartConfigProjectTypeDimension;
