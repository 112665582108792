/**
 * @abstract
 * @type {ChartConfigDimension}
 */
module.exports = class ChartConfigDimension {
  /**
   * @param {string} key
   */
  constructor(key) {
    this.key = key;
  }

  /**
   * Return a copy of this instance.
   * @abstract
   * @return {ChartConfigDimension}
   */
  // eslint-disable-next-line class-methods-use-this
  clone() {
    throw new Error('clone must be defined in classes that extend ChartConfigDimension');
  }
};
