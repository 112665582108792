module.exports = class ChartAxis {
  /**
   * @param {string} key
   * @param {string} label
   * @param {boolean} isDataSourceAxis True if this is the axis which expects data source dimension
   */
  constructor(key, label, isDataSourceAxis = false) {
    this.key = key;
    this.label = label;
    this.isDataSourceAxis = isDataSourceAxis;
  }
};
