/* eslint-disable no-shadow,eqeqeq,default-case */

'use strict';

var MathService = require('../../../services/MathService');

Wethod.module('ReportApp.Detail', function (Detail, Wethod, Backbone, Marionette, $, _, Header) {
  var structureTemplate;
  var projectId;
  var modalTemplate;

  var resetData = function () {
    structureTemplate = undefined;
    projectId = undefined;
    modalTemplate = undefined;
  };

  var getTimetrackingSerie = function (data) {
    var series = [];

    _.each(data, function (val) {
      series.push({
        y: parseFloat(val.timetracking),
        x: val.week,
        name: moment(val.week).format(),
        type: 'timetracking',
      });
    });

    return series;
  };

  var getProjectStatusSerie = function (data, budgetDays) {
    var series = [];

    _.each(data, function (val) {
      series.push({
        y: Number.isNaN(parseFloat(val.project_status)) ? null : parseFloat(val.project_status),
        name: moment(val.week).format(),
        type: 'projectStatus',
        budgetDays: budgetDays,
      });
    });

    return series;
  };

  var getRoadrunnerSerie = function (data) {
    var series = [];

    _.each(data, function (val) {
      series.push({
        y: val.roadrunner !== null ? MathService.round(val.roadrunner, 1) : null,
        name: moment(val.week).format(),
        type: 'roadrunner_index',
      });
    });
    return series;
  };

  var getDottedSerie = function (actual, progress, name, color, nameXpos) {
    return {
      color: color,
      enableMouseTracking: false,
      type: 'line',
      lineWidth: 1,
      dashStyle: 'Dot',
      tooltip: {
        enabled: false,
        formatter: function () {
          return false;
        },
      },
      data: [
        {
          x: parseFloat(progress),
          y: 0,
          marker: {
            enabled: false,
          },
          dataLabels: {
            enabled: true,
            format: '{x}',
            crop: false,
            overflow: 'none',
            y: +21,
          },
        },
        {
          x: parseFloat(progress),
          y: parseFloat(actual),
          marker: {
            enabled: true,
            symbol: 'circle',
          },
          dataLabels: {
            enabled: true,
            format: name,
            color: color,
            shadow: false,
            crop: false,
            overflow: 'none',
            x: nameXpos, // -45,
            y: 4,
            style: {
              textShadow: 'none',
              background: 'rgb(255, 255, 255)',
              fontWeight: 'normal',
              fontSize: '10px',
            },
          },
        },
        {
          x: 0,
          y: parseFloat(actual),
          marker: {
            enabled: false,
          },
          dataLabels: {
            enabled: true,
            crop: false,
            overflow: 'none',
            x: -12,
            y: +10,
          },
        },
      ],
    };
  };

  var getMarkerSerie = function (actual, progress, name, color, trackedHoursValue) {
    return {
      name: name,
      color: color,
      data: [
        {
          x: parseFloat(progress),
          y: parseFloat(actual),
          marker: {
            enabled: true,
            symbol: 'circle',
          },
        },
        {
          trackedHoursValue: trackedHoursValue,
        },
      ],
    };
  };

  var chartSeries = function (seriesData, wastedHoursEnabled) {
    var markerSerie;
    var series = [{
      name: null,
      enableMouseTracking: false,
      tooltip: {
        enabled: false,
        formatter: function () {
          return false;
        },
      },
      lineWidth: 1,
      type: 'line',
      marker: {
        enabled: false,
      },
      color: '#DCDCDE',
      zIndex: -1,
      data: [
        [0, 0],
        [100, 100],
      ],
      dashStyle: 'solid',
    }];
    var serie;
    // retta che unisce forecast a projected
    var forcastProjectSerie = {
      color: 'rgb(204,204,204)',
      enableMouseTracking: false,
      type: 'line',
      lineWidth: 1,
      dashStyle: 'Dot',
      tooltip: {
        enabled: false,
        formatter: function () {
          return false;
        },
      },
      marker: {
        enabled: false,
      },
      data: [],
    };

    _.each(seriesData, function (data) {
      serie = {};
      markerSerie = {};

      serie.lineWidth = 1;
      serie.marker = {
        enabled: true,
        symbol: 'circle',
      };

      switch (data.versions) {
        case 'actual': // Forecast
          serie = getDottedSerie(data.actual, data.progress, 'Forecast', 'rgb(72,165,244)', -28);
          serie.zIndex = 4;
          markerSerie = getMarkerSerie(data.actual, data.progress, 'Forecast', 'rgb(72,165,244)', data.trackedHoursValue);
          markerSerie.cursor = 'pointer';
          markerSerie.zIndex = 4;
          markerSerie.point = {
            events: {
              click: function () {
                window.open(URL + '/#pipeline/budget/' + data.idProject, '_blank');
              },
            },
          };

          series.push(serie);
          series.push(markerSerie);

          // aggiungo le coordinate del punto forecast
          forcastProjectSerie.data.push([data.progress, data.actual]);

          break;
        case 'ghost': // Baseline
          serie = getDottedSerie(data.actual, data.progress, 'Baseline', 'rgb(219,77,105)', -28);
          serie.zIndex = 1;

          markerSerie = getMarkerSerie(data.actual, data.progress, 'Baseline', 'rgb(219,77,105)');
          markerSerie.cursor = 'pointer';
          markerSerie.zIndex = 3;
          markerSerie.point = {
            events: {
              click: function () {
                window.open(URL + '/#pipeline/budget/' + data.idProject + '/version/1', '_blank');
              },
            },
          };

          series.push(serie);
          series.push(markerSerie);
          break;
        case 'wasted': // Wasted
          if (wastedHoursEnabled || data.actual) {
            serie = getDottedSerie(data.actual, data.progress, 'Wasted', 'rgb(255,185, 72)', -24);
            serie.zIndex = -1;

            markerSerie = getMarkerSerie(data.actual, data.progress, 'Wasted', 'rgb(255,185, 72)');
            markerSerie.zIndex = 1;

            series.push(serie);
            series.push(markerSerie);
          }
          break;
        case 'forecast': // Projected
          serie = getDottedSerie(data.actual, data.progress, 'Projected', 'rgb(78, 216, 141)', -28);
          serie.zIndex = 1;

          markerSerie = getMarkerSerie(data.actual, data.progress, 'Projected', 'rgb(78, 216, 141)');
          markerSerie.zIndex = 1;
          series.push(serie);
          series.push(markerSerie);

          // aggiungo le coordinate del punto projected
          forcastProjectSerie.data.push([data.progress, data.actual]);

          break;
        default:
          var name = 'version ' + data.versions + '<br/>' + moment(data.snapshot_at)
            .format('DD/MM/YYYY');
          serie = getMarkerSerie(data.actual, data.progress, name, 'rgb(204,204,204)');

          serie.cursor = 'pointer';
          serie.point = {
            events: {
              click: function () {
                window.open(URL + '/#pipeline/budget/' + data.idProject + '/version/' + data.versions, '_blank');
              },
            },
          };
          series.push(serie);
      }
    });

    // aggiungo la retta che collega forecast a projected
    series.push(forcastProjectSerie);

    return series;
  };

  this.showModalShareLink = function () {
    var data = {
      title: 'Share this Report',
      id: projectId,
    };

    var modalModel = new Detail.ModalModel(data);
    Detail.actionDialog = new Detail.ShareLinkModalTemplateView({
      model: modalModel,
    });
    structureTemplate.dialog.show(Detail.actionDialog);
  };

  this.showModalExport = function (projectId) {
    Detail.actionDialog = new Detail.ModalExportItemView({ projectId: projectId });
    structureTemplate.dialog.show(Detail.actionDialog);
  };

  this.showTimetrackingPeriodModal = function (from, to, projectId) {
    var model = new Backbone.Model({
      from: from,
      to: to,
    });
    Detail.actionDialog = new Detail.ModalTimetrackingPeriodView({
      model: model,
      projectId: projectId,
    });
    structureTemplate.dialog.show(Detail.actionDialog);
  };

  this.doModuleExport = function (viewObj) {
    Wethod.request('get:timesheet:cost:export', viewObj.options.projectId);
    viewObj.destroy();
  };

  this.autocomplete = function (val, viewObj) {
    val = val.replace(' ', ',');
    var params = {
      params: val,
      id_project: projectId,
    };
    // Loading
    var searchFeedbackTemplate = new Detail.SearchLoadingTemplateView();
    viewObj.ui.autocompleteArea.html(searchFeedbackTemplate.render().$el);

    var autocompleteRequest = Wethod.request('report:detail:autocomplete', params);
    $.when(autocompleteRequest).done(function (autocompleteResponse) {
      var autocompleteTemplate;
      if (!_.isUndefined(autocompleteResponse)) {
        autocompleteTemplate = new Detail.AutocompleteRecordsCollectionView({
          collection: autocompleteResponse,
          childViewOptions: {
            parent: viewObj,
          },
        });
        viewObj.ui.autocompleteArea.html(autocompleteTemplate.render().$el);
      } else {
        // not found
        searchFeedbackTemplate = new Detail.SearchNotFoundTemplateView();
        viewObj.ui.autocompleteArea.html(searchFeedbackTemplate.render().$el);
      }
    });
  };

  this.shareReportWidth = function (userID, message, viewObj) {
    var params = {
      user_id: userID,
      project_id: projectId,
      path: window.location.host + window.location.pathname,
      message: message,
    };
    viewObj.ui.cancelButton.addClass('disabled');
    viewObj.ui.actionButton.addClass('disabled');
    viewObj.ui.statusEl.text('Sending...');
    var shareLinkRequest = Wethod.request('report:detail:share:link', params);
    $.when(shareLinkRequest).done(function () {
      viewObj.destroy();
    });
  };

  this.showModal = function (data, externalModel, viewObj) {
    var model = new Detail.ModalModel(data);
    modalTemplate = new Detail.ModalItemView({
      model: model,
      externalModel: externalModel,
      viewObj: viewObj,
    });
    structureTemplate.dialog.show(modalTemplate);
  };

  var closeModal = function () {
    modalTemplate.destroy();
  };

  this.updateProjectStatus = function (viewObj, type) {
    var data = { id: viewObj.model.get('project').id };
    var label = '';
    var archive = null;

    var getProjRequest = Wethod.request('report:detail:get:project', data);
    $.when(getProjRequest).done(function (getProjResponse) {
      switch (type) {
        case 'archive':
          data.archived = getProjResponse.get('archived');
          archive = !data.archived;
          break;
      }

      var model = new Wethod.PipelineApp.Pipeline.ProjectSafeModel({ id: getProjResponse.get('id') });
      var updateProjRequest = Wethod.request('archive:pipeline:project', model, archive);
      $.when(updateProjRequest).done(function (updateProjResponse) {
        switch (type) {
          case 'archive':
            viewObj.model.get('project').archived = updateProjResponse.get('archived');
            label = viewObj.model.get('project').archived ? 'UNARCHIVE' : 'ARCHIVE';
            viewObj.ui.archiveLabelEl.text(label);
            closeModal();
            break;
        }
      }).fail(function (updateProjResponse) {
        switch (type) {
          case 'archive':
            var data = {
              title: 'PROJECT ARCHIVE',
              text: updateProjResponse.message,
              buttonOkText: undefined,
              buttonKoText: 'CLOSE',
              action: 'closeModal',
            };
            Detail.showModal(data, viewObj.model, this);
            break;
        }
      });
    });
  };

  this.getOrdersTotal = function (orders, status) {
    var total = 0;
    for (var i = 0; i < orders.length; i++) {
      var order = orders[i];
      if (order.status === status) {
        total += order.value;
      }
    }
    return total;
  };

  Detail.Controller = {
    showReportDetail: function (idProject, idTour) {
      resetData();
      projectId = idProject;

      var sentence = Wethod.Utility.randomSentence(Wethod.sentencesDays, Wethod.sentencesSongs);
      var loadingView = new Wethod.View.Loading.BigView({ sentence: sentence });
      Wethod.regions.body.show(loadingView);

      var getRportProjectRequest = Wethod.request('report:get:project', idProject);
      var getWastedHoursSettings = Wethod.request('get:wasted:hours:settings');
      $.when(getRportProjectRequest, getWastedHoursSettings)
        .done(function (prjReportData, wastedHoursSettings) {
          // controllo che la risposta non sia 403
          if (!_.isUndefined(prjReportData)) {
            var reportAuthorizationRequest = Wethod.request('get:report:authorization');
            $.when(reportAuthorizationRequest).done(function (reportAuthorizationResponse) {
              if (_.isUndefined(reportAuthorizationResponse)) {
                return;
              }

              Wethod.ReportApp.authorization = reportAuthorizationResponse;

              if (
                Wethod.ReportApp.getPermission('view_details') || prjReportData.pm.id === Wethod.userInfo.get('employee_id')
              ) {
                // structure
                loadingView.destroy();
                structureTemplate = new Detail.ProjectReportStructureLayoutView();
                structureTemplate.render();
                var wastedHoursEnabled = wastedHoursSettings.get('enabled');
                var sectionHeaderModel = new Header.SectionHeaderModel({
                  current_section: 'Project Report',
                  tour_id: 31036,
                  tour_start_page: 'project/9/report',
                  helper_url: 'reports/index/#project-report',
                  bookmark_fragment: 'project/' + idProject + '/report',
                });
                var headerRegion = new Header.SectionHeaderView({ model: sectionHeaderModel });
                structureTemplate.headerSection.show(headerRegion);

                if (_.isEmpty(prjReportData.budget)) {
                  prjReportData.budget = {
                    actual_external_cost: 0,
                    actual_internal_cost: 0,
                    actual_margin: 0,
                    actual_value: 0,
                    contingency: null,
                    cost: null,
                    discount: null,
                    external_cost: 0,
                    final_net_price: null,
                    id: null,
                    margin_absolute: 0,
                    margin_percent: 0,
                    price: null,
                    suggested_net_price: null,
                  };
                }
                // init + versions
                var chartData = _.isUndefined(prjReportData.versions)
                  ? [] : _.map(prjReportData.versions);
                // add project id to the versions
                if (!_.isEmpty(chartData)) {
                  _.each(chartData, function (chart) {
                    chart.idProject = parseInt(idProject);
                  });
                }

                // l'ordine di aggiunta a chartData determina con che gerarchia vengono disegnate

                // forecast
                var actualData = {
                  actual: prjReportData.actual,
                  progress: prjReportData.progress,
                  idProject: parseInt(idProject),
                  versions: 'actual',
                  trackedHoursValue: prjReportData.timetracking.tracked_hours_value,
                };
                chartData.push(actualData);

                // baseline
                if (!_.isEmpty(prjReportData.ghost)) {
                  var ghostData = {
                    actual: prjReportData.ghost.actual,
                    progress: prjReportData.ghost.progress,
                    idProject: parseInt(idProject),
                    versions: 'ghost',
                  };
                  chartData.push(ghostData);
                }

                // projected
                var forecastData = {
                  actual: prjReportData.forecast.actual,
                  progress: prjReportData.forecast.progress,
                  versions: 'forecast',
                };
                chartData.push(forecastData);

                // wasted
                // add the wasted serie if wasted hours is > 0
                if (prjReportData.timetracking.wasted_hours > 0) {
                  var wastedData = {
                    actual: prjReportData.wasted.actual,
                    progress: prjReportData.wasted.progress,
                    versions: 'wasted',
                  };
                  chartData.push(wastedData);
                }

                // transform the data in an highcharts series
                var chartSeriesData = chartSeries(chartData, wastedHoursEnabled);

                // find the max Y value
                var maxYAxis = 100;
                _.each(chartData, function (serie) {
                  if (parseInt(serie.actual) > maxYAxis) {
                    maxYAxis = parseInt(serie.actual);
                  }
                });

                // Actual / progress chart template
                var actualProgressModel = new Wethod.ReportApp.Report.ReportModel({
                  chartSeriesData: chartSeriesData,
                });
                var actualProgressTemplate = new Detail.ActualProgressLayoutView({
                  model: actualProgressModel,
                  projectStatusPercentage: prjReportData.project_type.progress_percent,
                  maxYAxis: maxYAxis,
                });
                actualProgressTemplate.render();

                // hours details
                var hoursDetailModel = new Wethod.ReportApp.Report.ReportModel({
                  actual: prjReportData.actual,
                  progress: prjReportData.progress,
                  first_version_days: prjReportData.timetracking.first_version_days,
                  first_version_hours: prjReportData.timetracking.first_version_hours,
                  versions_delta: prjReportData.timetracking.versions_delta,
                  totalHours: prjReportData.timetracking.total_hours,
                  workedHours: prjReportData.timetracking.worked_hours,
                  futurePlanned: prjReportData.timetracking.future_planned_hours,
                  lastWeekWorkedHours: prjReportData.timetracking.last_week_worked_hours,
                  availableHours: prjReportData.timetracking.available_hours,
                  contingencyHours: prjReportData.timetracking.contingency_hours,
                  originalPlan: prjReportData.timetracking.original_plan,
                  westedHours: prjReportData.timetracking.wasted_hours,
                  wastedHoursEnabled: wastedHoursEnabled,
                });

                var hoursDetailsTemplate = new Detail.HoursDetailsItemView({
                  model: hoursDetailModel,
                });
                actualProgressTemplate.hoursDetailsContainer.show(hoursDetailsTemplate);

                // invoices list
                var invoiced = 0;
                $.each(prjReportData.invoices, function (key, value) {
                  invoiced += parseFloat(value.value);
                });
                var invoicesCollection = new Wethod.ReportApp.Report
                  .InvoicesCollection(prjReportData.invoices);

                var invoiceCompositeModel = new Wethod.ReportApp.Report.CompositeInvoiceModel({
                  final_net_price: prjReportData.invoices_amount.final_net_price,
                  invoiced: prjReportData.invoices_amount.final_net_price - invoiced,
                });

                var invoicesCompositeView = new Detail.InvoiceCompositeView({
                  model: invoiceCompositeModel,
                  collection: invoicesCollection,
                });
                structureTemplate.invoicesContainer.show(invoicesCompositeView);

                // orders list
                var ordered = 0;
                $.each(prjReportData.orders, function (key, value) {
                  ordered += parseFloat(value.value);
                });
                var ordersCollection = new Wethod.ReportApp.Report
                  .OrdersCollection(prjReportData.orders);

                var extCosts = prjReportData.orders_amount.external_cost;
                var orderCompositeModel = new Wethod.ReportApp.Report.CompositeOrderModel({
                  external_cost: extCosts,
                  ordered: extCosts - ordered,
                  progress: prjReportData.progress,
                  not_sent: prjReportData.orders_amount.not_sent,
                  sent: prjReportData.orders_amount.sent,
                  authorized: prjReportData.orders_amount.authorized,
                  paid: prjReportData.orders_amount.paid,
                  travels: prjReportData.orders_amount.travels,
                  expenses: prjReportData.orders_amount.expenses,
                });

                var ordersCompositeView = new Detail.OrderCompositeView({
                  model: orderCompositeModel,
                  collection: ordersCollection,
                });
                structureTemplate.ordersContainer.show(ordersCompositeView);

                // Timesheet/Projects Status must be on last 12 weeks and maximum on 24 weeks
                var newSteps = [];
                var byWeek = prjReportData.weekly_steps;

                for (var i = 1; i < 13; i++) {
                  var thatMonday = moment().day('Monday').subtract(i, 'week').format('YYYY-MM-DD');
                  var found = false;

                  for (var j = 0; j < byWeek.length; j++) {
                    if (moment(byWeek[j].week).format('YYYY-MM-DD') === thatMonday) {
                      found = true;
                    }
                  }

                  if (found === false) {
                    newSteps.push({
                      week: thatMonday,
                      timetracking: 0,
                      project_status: null,
                      roadrunner: null,
                    });
                  }
                }

                byWeek = byWeek.concat(newSteps);
                if (byWeek.length > 24) {
                  // byWeek = byWeek.slice(-24, byWeek.length);
                }

                prjReportData.weekly_steps = _.sortBy(byWeek, function (week) {
                  return week.week;
                });

                // ECONOMICS table
                var economicsModel = new Detail.EconomicsModel(prjReportData.economics).set('wastedHoursEnabled', wastedHoursEnabled);
                var economicsTemplate = new Detail.HoursEconomicsDetailsItemView({
                  model: economicsModel,
                });
                structureTemplate.economicsContainer.show(economicsTemplate);

                var budgetDays = Math.round(prjReportData.timetracking.total_hours / 8);

                // hours / week chart template
                var hoursWeekChartTemplate = new Detail.HoursWeeksChartItemView({
                  model: new Wethod.ReportApp.Report.ReportModel({
                    budget_days: budgetDays,
                    tt_serie: getTimetrackingSerie(prjReportData.weekly_steps),
                    ps_serie: getProjectStatusSerie(prjReportData.weekly_steps, budgetDays),
                    roadrunner_serie: getRoadrunnerSerie(prjReportData.weekly_steps),
                  }),
                });
                structureTemplate.hoursWeeksContainer.show(hoursWeekChartTemplate);

                // hours / user table template
                var hoursUsersTableTemplate = new Detail.HoursUsersTableLayoutView({
                  projectId: idProject,
                  timetrackingByEmployee: prjReportData.timetracking.by_employee,
                  trackedHoursValue: prjReportData.timetracking.tracked_hours_value,
                });
                hoursUsersTableTemplate.render();

                // project details & economics template
                var detailModel = new Wethod.ReportApp.Report.ReportModel({
                  program: prjReportData.program,
                  project: prjReportData.project,
                  client: prjReportData.client,
                  pm: prjReportData.pm,
                  budget: prjReportData.budget,
                  invoices_amount: prjReportData.invoices_amount,
                  orders_amount: prjReportData.orders_amount,
                  progress: prjReportData.progress,
                });
                var projectEconomicsTemplate = new Detail.ProjectEconomicsItemView({
                  model: detailModel,
                });
                structureTemplate.projectEconomicsContainer.show(projectEconomicsTemplate);

                dispatcher.trigger('tour:start', idTour);
              } else {
                loadingView.destroy();
                var permissionDeniedView = new Wethod.PermissionDenied
                  .PermissionDeniedTemplateView();
                Wethod.regions.body.show(permissionDeniedView);
              }
            });
          } else {
            loadingView.destroy();
            var permissionDeniedView = new Wethod.PermissionDenied.PermissionDeniedTemplateView();
            Wethod.regions.body.show(permissionDeniedView);
          }
        });
    },
  };
}, Wethod.module('HeaderApp.Header'));
