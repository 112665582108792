const { connect } = require('react-redux');
const Component = require('../../../../components/Calculator/Task/JobTitleInsertModal/JobTitleInsertModalContent.react');
const Reducer = require('../../../../reducer');
const LevelService = require('../../../../services/LevelService');

const mapStateToProps = (state) => ({
  mainBudgetPriceListLevels: LevelService.sort(state.info.mainBudgetPriceListLevels),
  // only non archived job title are available for insertion
  availableJobTitles: state.info.job_titles.filter((jobTitle) => !jobTitle.job_title.archived),
  isSaving: Reducer.waitingFor(state, 'insert-job-title'),
});

module.exports = connect(mapStateToProps)(Component);
