const React = require('react');
const PropTypes = require('prop-types');
const SelectionCard = require('../../SelectionCard/SelectionCard.react');

const ChartSidebarTypeOption = ({ value, name, onClick, id, title, subtitle, icon, checked }) => (
  <SelectionCard subtitle={subtitle}
    name={name}
    checked={checked}
    title={title}
    value={value}
    id={id}
    icon={icon}
    onClick={onClick} />
);

ChartSidebarTypeOption.defaultProps = {
  id: null,
  onClick: null,
};

ChartSidebarTypeOption.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  id: PropTypes.string,
  onClick: PropTypes.func,
};

module.exports = ChartSidebarTypeOption;
