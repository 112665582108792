const React = require('react');
const Levels = require('../Cell/LevelsCell.react');
const Level = require('./TaskLevel.react');

const TaskLevels = ({
  items,
  saveLevel,
  taskId,
  editable = false,
}) => {
  // const sortedLevels = items.filter((el) => el.level.is_visible);
  const levels = items.filter((el) => el.level.is_visible).map((level) => {
    const levelId = level.id;
    return (
      <Level key={levelId}
        previousValue={level.was.days}
        levelAmountId={level.id}
        value={level.total.days}
        readOnly={!editable}
        taskId={taskId}
        id={levelId}
        save={saveLevel} />
    );
  });

  return (
    <Levels className="wethod-budget-task__levels">{levels}</Levels>
  );
};

module.exports = TaskLevels;
