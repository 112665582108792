const React = require('react');
const Modal = require('../../../../../../common/react/Modal/Modal.react');
const ModalContent = require('../../../../../../common/react/Modal/ModalContent.react');
const ModalBody = require('../../../../../../common/react/Modal/ModalBody.react');
const ModalBodyBlock = require('../../../../../../common/react/Modal/ModalBodyBlock.react');
const Footer = require('../../../../../../common/react/Modal/ModalFooter.react');
const FooterAction = require('../../../../../../common/react/Modal/ModalFooterAction.react');
const Typography = require('../../../../../../common/react/Typography/Typography.react');
const status = require('../../segmentsStatus');

const ConfirmPauseModal = ({ isSaving, updatedSegments, closeModal, save }) => {
  const segment = updatedSegments.find((seg) => seg.status === status.PAUSED);

  function getFormattedDate(date) {
    return date ? date.format('DD/MM/YYYY') : '';
  }

  function handleSave() {
    save(updatedSegments);
  }

  function getFeedback() {
    return isSaving ? 'Saving...' : null;
  }

  const startDate = segment.from
    ? getFormattedDate(moment(segment.from).startOf('month'))
    : 'up'; // in order to display 'up to' in the text
  const endDate = segment.to ? getFormattedDate(moment(segment.to).endOf('month')) : '';

  return (
    <Modal title="Important Information" onClose={closeModal}>
      <ModalContent>
        <ModalBody>
          <ModalBodyBlock>
            Unless data editing permissions apply, {' '}
            <Typography weight={Typography.WEIGHTS.SEMIBOLD}>
              users won’t be able to edit the data set belonging to period {startDate} to {endDate}.
            </Typography>
            This data edit status preference is reversible at any time.
            <br /><br />
            Are you sure you want to continue?
          </ModalBodyBlock>
        </ModalBody>
        <Footer feedback={getFeedback()}>
          <FooterAction onClick={closeModal}>
            Dismiss
          </FooterAction>
          <FooterAction onClick={handleSave}>
            Yes, pause data
          </FooterAction>
        </Footer>
      </ModalContent>
    </Modal>
  );
};

module.exports = ConfirmPauseModal;
