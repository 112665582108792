const ChartConfigClientDimensionGrouping = require('./ChartConfigClientDimensionGrouping');

class ChartConfigClientDimensionTopNGrouping extends ChartConfigClientDimensionGrouping {
  /**
   * @param {number} value
   */
  constructor(value) {
    super(ChartConfigClientDimensionTopNGrouping.TYPE);
    this.value = value;
  }

  /**
   * Return index of the group containing "all the rest" clients.
   * @return {?number}
   */
  getOthersGroupIndex() {
    return this.isGrouped() ? this.value : null;
  }

  /**
   * @param data
   * @return {ChartConfigClientDimensionTopNGrouping}
   */
  static fromJSON(data) {
    const value = parseInt(data);
    return new ChartConfigClientDimensionTopNGrouping(value);
  }

  // eslint-disable-next-line class-methods-use-this
  isGrouped() {
    return true;
  }

  clone() {
    return new ChartConfigClientDimensionTopNGrouping(this.value);
  }
}

ChartConfigClientDimensionTopNGrouping.TYPE = 'top-n';

module.exports = ChartConfigClientDimensionTopNGrouping;
