const React = require('react');
const PropTypes = require('prop-types');
const InputValidator = require('../../../InputValidator/InputValidator.react');
const TextField = require('../../../inputs/TextField/OutlinedTextField/OutlinedTextField.react');
const Select = require('../../../inputs/Select/OutlinedSelect/OutlinedSelect.react');
const SelectItem = require('../../../inputs/Select/MultipleSelectItem.react');
const GroupingValue = require('../../../../../apps/core/modules/insights/revenues/models/ChartConfigDimension/ChartConfigProbabilityDimension/ChartConfigProbabilityDimensionGroupingValue');
const Button = require('../../../Button/IconButton.react');
const Tooltip = require('../../../TooltipFixed/Tooltip.react');

const ChartSidebarDimensionCluster = ({
  getError, updateErrors, onUpdate, onDelete, dimensionLabel, values, name, availableValues, id,
  index,
}) => {
  const nameInputId = `${id}-name`;
  const valuesInputId = `${id}-values`;

  function onChangeName(e) {
    const cluster = new GroupingValue(e.target.value, values);

    onUpdate(index, cluster);
  }

  function onChangeValues(e) {
    const cluster = new GroupingValue(name, e.target.value);

    onUpdate(index, cluster);
  }

  function handleDeleteClick() {
    onDelete(index);
  }

  const getAvailableItems = () => availableValues
    .map((value) => <SelectItem key={value} value={value}>{value}</SelectItem>);

  const deleteButtonLabel = 'Delete cluster';

  return (
    <div className="wethod-chart-sidebar__dimension-option-attribute-cluster">
      <InputValidator updateErrors={updateErrors} constraints={['required', 'maxLength:20']}>
        <TextField label="Name"
          id={nameInputId}
          value={name}
          onBlur={onChangeName}
          name={nameInputId}
          errorText={getError(nameInputId)} />
      </InputValidator>
      <InputValidator updateErrors={updateErrors} constraints={['required']}>
        <Select label={dimensionLabel}
          id={valuesInputId}
          multiple
          value={values}
          onChange={onChangeValues}
          name={valuesInputId}
          errorText={getError(valuesInputId)}>
          {getAvailableItems()}
        </Select>
      </InputValidator>
      <Tooltip label={deleteButtonLabel}>
        <Button className="wethod-chart-sidebar__dimension-option-attribute-cluster-delete-button"
          onClick={handleDeleteClick}
          icon="delete"
          label={deleteButtonLabel}
          border={false} />
      </Tooltip>
    </div>
  );
};

ChartSidebarDimensionCluster.propTypes = {
  id: PropTypes.string.isRequired,
  updateErrors: PropTypes.func.isRequired,
  getError: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
  dimensionLabel: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  availableValues: PropTypes.arrayOf(PropTypes
    .oneOfType(PropTypes.string, PropTypes.number)).isRequired,
  /**
   * This cluster position in the cluster list.
   */
  index: PropTypes.number.isRequired,
};

module.exports = ChartSidebarDimensionCluster;
