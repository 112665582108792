const TimeDimension = require('../models/ChartConfigDimension/ChartConfigTimeDimension/ChartConfigTimeDimension');
const TimeIncludedFilter = require('../models/ChartConfigFilter/ChartConfigFilterTimeIncluded');
const ProbabilityDimension = require('../models/ChartConfigDimension/ChartConfigProbabilityDimension/ChartConfigProbabilityDimension');
const ProbabilityDimensionClusterGrouping = require('../models/ChartConfigDimension/ChartConfigProbabilityDimension/ChartConfigProbabilityDimensionClusterGrouping');
const ProbabilityDimensionDistinctGrouping = require('../models/ChartConfigDimension/ChartConfigProbabilityDimension/ChartConfigProbabilityDimensionDistinctGrouping');
const ProbabilityIncludedFilter = require('../models/ChartConfigFilter/ChartConfigFilterProbabilityIncluded');
const ClientDimension = require('../models/ChartConfigDimension/ChartConfigClientDimension/ChartConfigClientDimension');
const ClientNotIncludedFilter = require('../models/ChartConfigFilter/ChartConfigFilterClientNotIncluded');
const ClientIncludedFilter = require('../models/ChartConfigFilter/ChartConfigFilterClientIncluded');
const AccountDimension = require('../models/ChartConfigDimension/ChartConfigAccountDimension/ChartConfigAccountDimension');
const AccountNotIncludedFilter = require('../models/ChartConfigFilter/ChartConfigFilterAccountNotIncluded');
const AccountIncludedFilter = require('../models/ChartConfigFilter/ChartConfigFilterAccountIncluded');
const ProjectTypeDimension = require('../models/ChartConfigDimension/ChartConfigProjectTypeDimension/ChartConfigProjectTypeDimension');
const ProjectTypeNotIncludedFilter = require('../models/ChartConfigFilter/ChartConfigFilterProjectTypeNotIncluded');
const ProjectTypeIncludedFilter = require('../models/ChartConfigFilter/ChartConfigFilterProjectTypeIncluded');
const JocDimension = require('../models/ChartConfigDimension/ChartConfigJobOrderCategoryDimension/ChartConfigJobOrderCategoryDimension');
const JocDimensionClusterGrouping = require('../models/ChartConfigDimension/ChartConfigJobOrderCategoryDimension/ChartConfigJobOrderCategoryDimensionClusterGrouping');
const JocDimensionDistinctGrouping = require('../models/ChartConfigDimension/ChartConfigJobOrderCategoryDimension/ChartConfigJobOrderCategoryDimensionDistinctGrouping');
const JocIncludedFilter = require('../models/ChartConfigFilter/ChartConfigFilterJobOrderCategoryIncluded');

const BuilderService = {
  /**
   * Derive filter by given point and related chart.
   * @param {BarChartPoint} selectedPoint
   * @param {Chart} chart
   * @param {BarChartPoint[]} points
   */
  buildFilterByPoint(selectedPoint, chart, points) {
    const { dimensions } = chart.config;
    const filters = [];
    const axisList = Object.keys(dimensions);
    // All dimensions except the one related to section data source can be used to create filters
    const filterableAxisList = axisList
      .filter((axis) => dimensions[axis] && dimensions[axis].key !== chart.dataSource);
    filterableAxisList.forEach((axis) => {
      const dimension = dimensions[axis];
      if (dimension instanceof TimeDimension) {
        if (dimension.grouping.type === 'period') {
          const precision = dimension.grouping.value;
          const filter = new TimeIncludedFilter([selectedPoint[axis]], precision);
          filters.push(filter);
        }
      } else if (dimension instanceof ProbabilityDimension) {
        const { grouping } = dimension;
        if (grouping instanceof ProbabilityDimensionClusterGrouping) {
          const { values } = dimension.grouping.findItem(selectedPoint[axis]);
          const filter = new ProbabilityIncludedFilter(values);
          filters.push(filter);
        } else if (grouping instanceof ProbabilityDimensionDistinctGrouping) {
          const filter = new ProbabilityIncludedFilter([selectedPoint[axis]]);
          filters.push(filter);
        } else {
          throw new Error(`'${grouping.type}' is not a supported probability dimension grouping`);
        }
      } else if (dimension instanceof JocDimension) {
        const { grouping } = dimension;
        if (grouping instanceof JocDimensionClusterGrouping) {
          const { values } = dimension.grouping.findItem(selectedPoint[axis]);
          const filter = new JocIncludedFilter(values);
          filters.push(filter);
        } else if (grouping instanceof JocDimensionDistinctGrouping) {
          const filter = new JocIncludedFilter([selectedPoint[axis]]);
          filters.push(filter);
        } else {
          throw new Error(`'${grouping.type}' is not a supported job order category dimension grouping`);
        }
      } else if (dimension instanceof ClientDimension) {
        // if clients are grouped then find the "all the rest" index
        if (dimension.grouping.isGrouped()) {
          const isOthersGroup = points.findIndex((p) => p[axis] === selectedPoint[axis])
            === dimension.grouping.getOthersGroupIndex();
          if (isOthersGroup) {
            // If click happens on all the rest then use NOT IN filter and pass all non-selected points
            const nonSelectedPoints = points.filter((p) => p.id !== selectedPoint.id);
            const nonSelectedPointsValues = nonSelectedPoints.map((p) => p[axis]);
            const filter = new ClientNotIncludedFilter(nonSelectedPointsValues);
            filters.push(filter);
          } else {
            // Otherwise use IN filter and pass the selected point
            const filter = new ClientIncludedFilter([selectedPoint[axis]]);
            filters.push(filter);
          }
        } else {
          // Otherwise use IN filter
          const filter = new ClientIncludedFilter([selectedPoint[axis]]);
          filters.push(filter);
        }
      } else if (dimension instanceof AccountDimension) {
        // if accounts are grouped then find the "all the rest" index
        if (dimension.grouping.isGrouped()) {
          const isOthersGroup = points.findIndex((p) => p[axis] === selectedPoint[axis])
            === dimension.grouping.getOthersGroupIndex();
          if (isOthersGroup) {
            // If click happens on all the rest then use NOT IN filter and pass all non-selected points
            const nonSelectedPoints = points.filter((p) => p.id !== selectedPoint.id);
            const nonSelectedPointsValues = nonSelectedPoints.map((p) => p[axis]);
            const filter = new AccountNotIncludedFilter(nonSelectedPointsValues);
            filters.push(filter);
          } else {
            // Otherwise use IN filter and pass the selected point
            const filter = new AccountIncludedFilter([selectedPoint[axis]]);
            filters.push(filter);
          }
        } else {
          // Otherwise use IN filter
          const filter = new AccountIncludedFilter([selectedPoint[axis]]);
          filters.push(filter);
        }
      } else if (dimension instanceof ProjectTypeDimension) {
        // if accounts are grouped then find the "all the rest" index
        if (dimension.grouping.isGrouped()) {
          const isOthersGroup = points.findIndex((p) => p[axis] === selectedPoint[axis])
            === dimension.grouping.getOthersGroupIndex();
          if (isOthersGroup) {
            // If click happens on all the rest then use NOT IN filter and pass all non-selected points
            const nonSelectedPoints = points.filter((p) => p.id !== selectedPoint.id);
            const nonSelectedPointsValues = nonSelectedPoints.map((p) => p[axis]);
            const filter = new ProjectTypeNotIncludedFilter(nonSelectedPointsValues);
            filters.push(filter);
          } else {
            // Otherwise use IN filter and pass the selected point
            const filter = new ProjectTypeIncludedFilter([selectedPoint[axis]]);
            filters.push(filter);
          }
        } else {
          // Otherwise use IN filter
          const filter = new ProjectTypeIncludedFilter([selectedPoint[axis]]);
          filters.push(filter);
        }
      } else {
        throw new Error(`'${dimension.key}' is not a supported filter.`);
      }
    });

    return filters;
  },
};

module.exports = BuilderService;
