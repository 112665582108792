const React = require('react');

const CheckboxIndeterminateIcon = () => (
  <svg className="wethod-checkbox__icon wethod-checkbox__icon--indeterminate"
    data-testid="wethod-checkbox-icon-indeterminate"
    focusable="false"
    viewBox="0 0 16 16"
    fill="none">
    <rect x="0.5" y="0.5" width="15" height="14.9" rx="1.5" fill="#212121" />
    <rect x="0.5" y="0.5" width="15" height="14.9" rx="1.5" fill="none" stroke="#212121" />
    <path d="M13.5,8.5H2.5a.5.5,0,0,1,0-1h11a.5.5,0,0,1,0,1Z"
      transform="translate(0 0)"
      fill="#fff" />
  </svg>
);

module.exports = CheckboxIndeterminateIcon;
