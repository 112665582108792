const request = require('./Request');

const JobOrderCategory = {
  list() {
    return request({
      method: 'get',
      url: '/projecttype',
    });
  },
};

module.exports = JobOrderCategory;
