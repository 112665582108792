const React = require('react');
const PropTypes = require('prop-types');
const MoreOptions = require('../../../../../../../common/react/MoreOptionsButton/MoreOptionsButton.react');
const Button = require('../../../../../../../common/react/Button/RoundedButton.react');
const Option = require('./SeeMoreButtonOption.react');
const ChartPickerTab = require('../../models/ChartPickerTab');

/**
 * Select hiding chart tabs that cannot be displayed inline due to lack of available space.
 * @param setRef
 * @param charts
 * @param onOptionClick
 * @return {JSX.Element}
 * @constructor
 */
const SeeMoreButton = ({ setRef, tabs, onOptionClick }) => {
  const getOptions = () => tabs
    .map((tab) => <Option id={tab.chartId} onClick={onOptionClick}>{tab.title}</Option>);

  return (
    <MoreOptions button={<Button>See more</Button>}
      setRef={setRef}
      className="wethod-insight-level__chart-picker-more-button">
      {getOptions()}
    </MoreOptions>
  );
};

SeeMoreButton.propTypes = {
  setRef: PropTypes.func.isRequired,
  onOptionClick: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(ChartPickerTab).isRequired,
};

module.exports = SeeMoreButton;
