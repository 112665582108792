/**
 * Get budget levels associated to the budget price list
 * @param budgetPriceListLevels
 * @param budgetPriceList
 * @returns {*}
 */
const getMainBudgetPriceListLevels = (budgetPriceListLevels, budgetPriceList) => (
  budgetPriceListLevels
    .filter((priceListLevel) => priceListLevel.price_list.id === budgetPriceList.id)
    // .map((priceListLevel) => formatLevel(priceListLevel))
);

const getMainVersionedBudgetPriceListLevels = (budgetPriceListLevels, budgetPriceList) => (
  budgetPriceListLevels
    .filter((priceListLevel) => priceListLevel.from.price_list.id === budgetPriceList.id)
    .map((priceListLevel) => ({
      from: priceListLevel.from ? priceListLevel.from : null,
      to: priceListLevel.to ? priceListLevel.to : null,
    }))
);

const formatBudgetInfo = (info) => ({
  ...info,
  mainBudgetPriceListLevels: getMainBudgetPriceListLevels(
    info.budget_price_list_levels, info.price_list,
  ),
});

const formatBudgetInfoVersion = (info) => ({
  ...info,
  mainBudgetPriceListLevels: getMainVersionedBudgetPriceListLevels(
    info.budget_price_list_levels, info.to.price_list,
  ),
});

module.exports = {
  formatBudgetInfo,
  formatBudgetInfoVersion,
};
