const { connect } = require('react-redux');
const Component = require('../../../components/Calculator/Summary/Summary.react');
const actions = require('../../../actions');
const LevelService = require('../../../services/LevelService');

const mapStateToProps = (state) => ({
  scrollingComponentAmount: state.scrollingComponentAmount,
  mainBudgetPriceListLevels: LevelService.sort(state.info.mainBudgetPriceListLevels),
});
const mapDispatchToProps = (dispatch) => ({
  showModal: (type, data) => dispatch(actions.showModal(type, data)),
  setScrollingComponentScrollAmount: ((amount) => dispatch(actions
    .setScrollingComponentScrollAmount(amount))),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
