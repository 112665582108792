const ChartConfigFilterJobOrderCategory = require('./ChartConfigFilterJobOrderCategory');
const ChartConfigFilter = require('./ChartConfigFilter');

class ChartConfigFilterJobOrderCategoryIncluded extends ChartConfigFilterJobOrderCategory {
  /**
   * @param {string[]} values
   */
  constructor(values) {
    super(ChartConfigFilter.TYPE_IN);
    this.values = values;
  }

  /**
   * @param data
   * @return {ChartConfigFilterJobOrderCategoryIncluded}
   */
  static fromJSON(data) {
    return new ChartConfigFilterJobOrderCategoryIncluded(data.values);
  }
}

module.exports = ChartConfigFilterJobOrderCategoryIncluded;
