const React = require('react');
const CalculatorStructure = require('../../models/CalculatorStructure');

const CalculatorManager = ({
  children,
  levelsData,
  summaries,
  areas,
  tasks,
  levelAmounts,
  budgetJobTitles,
  budgetPriceListLevels,
  ...rest
}) => {
  const structure = new CalculatorStructure(
    summaries, areas, tasks, levelAmounts, levelsData,
    budgetJobTitles, budgetPriceListLevels,
  );

  const calculatorStructure = structure.toJson();

  const levels = structure.getLevels();

  return React.cloneElement(children, {
    ...rest,
    levels,
    summaries: calculatorStructure,
  });
};

module.exports = CalculatorManager;
