const React = require('react');
const Area = require('./Area.react');
const Header = require('./AreaHeader.react');
const TaskContainer = require('./AreaTaskContainer.react');
const Name = require('./AreaName.react');
const Levels = require('./AreaLevels.react');
const DaysCell = require('../Cell/ManDaysCell.react');
const PercentageCell = require('../Cell/PercentageCell.react');
const TaskCreator = require('../CreationRow/CreationRow.react');
const ShowIf = require('../../../../../../../common/react/ShowIf/ShowIf.react');
const Switch = require('./AreaSwitch.react');
const Price = require('../Cell/PriceCell.react');
const Cost = require('../Cell/CostCell.react');
const Accordion = require('../../../../../../../common/react/Accordion/Accordion.react');
const ExternalCosts = require('../../../containers/Calculator/Cell/ExternalCostHeaderCell');
const MoreOptionsMenuItem = require('../MoreOptionsButton/MoreOptionsMenuItem.react');
const MoreOptions = require('../MoreOptionsButton/MoreOptionsButton.react');
const Task = require('../Task/Task.react');
const LaptopAndAbove = require('../../../../../../../common/react/media-queries/LaptopAndAbove.react');
const PriceList = require('../../../containers/Calculator/Task/PriceList');

/**
 * Area created by a user.
 * @param {string} name - Area name
 * @param levels
 * @param {number} externalCosts - Total external cost for area
 * @param {number} markup - Total markup for area
 * @param {number} cost - Total cost for area
 * @param {number} price - Total price for area
 * @param {Array.<JSX.Element>} tasks - List of task elements
 * @param {number} days - Total days for area
 * @param {bool} editableBudget - Tells if budget is editable
 * @param {bool} isCreatingTask - Tells if area is waiting for a new task to be created
 * @param {function} createTask - Invoked to create a task related to this area
 * @param {function} updateArea - Invoked to update attributes related to this area
 * @param {number|string} id - Area ID
 * @param {bool} enabled - Tells if area enabled or disabled
 * @param previous
 * @param {function} showSortTaskModal - Invoked to show modal used to sort area's tasks
 * @param {function} showProductInsertModal - Invoked to show modal used to add products to area
 * @param {number} nextTaskOrder - Order to use for the next created task in this area
 * @param column
 * @param mainBudgetPriceListLevels
 * @param isCollapsed
 * @param onAccordionChange
 * @param fitContentWidth
 * @param priceList
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
const UserDefinedArea = ({
  name, levels, externalCosts, markup, cost, price,
  tasks, days, editableBudget, isCreatingTask, createTask, updateArea,
  id, enabled, previous, showSortTaskModal, showProductInsertModal, nextTaskOrder,
  column, isCollapsed, onAccordionChange, fitContentWidth,
  priceList, mainBudgetPriceListLevels,
  ...props
}) => {
  function onInsertModalClick() {
    showProductInsertModal(id, nextTaskOrder);
  }

  if (column === 'left') {
    const header = (
      <Header>
        <Name editable={editableBudget} save={updateArea} id={`area-${id}-name`}>{name}</Name>
        <PercentageCell label="Area quantity" />
      </Header>
    );
    return (
      <Area
        column={column}
        enabled={enabled}
        wasEnabled={previous.on}
        showShadows={props.showShadows}>
        <Accordion summary={header}
          showStatusIcon={false}
          expanded={!isCollapsed}
          onChange={onAccordionChange}>
          <PriceList
            column={column}
            priceList={priceList}
            areaId={id}
            was={previous.priceList} />
          <TaskContainer>
            {tasks}
            <ShowIf condition={editableBudget}>
              <TaskCreator
                column={column}
                placeholder="+ New task"
                className="wethod-budget-creation-row--task"
                save={createTask}
                isSaving={isCreatingTask} />
            </ShowIf>
          </TaskContainer>
        </Accordion>
      </Area>
    );
  }
  if (column === 'center') {
    const header = (
      <Header>
        <Levels
          items={levels}
          mainBudgetPriceListLevels={mainBudgetPriceListLevels}
          previousItems={previous.levels} />
      </Header>
    );

    return (
      <Area
        column={column}
        enabled={enabled}
        wasEnabled={previous.on}
        fitContentWidth={fitContentWidth}>
        <Accordion overflowXHidden
          onChange={onAccordionChange}
          summary={header}
          showStatusIcon={false}
          expanded={!isCollapsed}>
          <PriceList column={column} />
          <TaskContainer>
            {tasks}
            <ShowIf condition={editableBudget}>
              <Task className="wethod-budget-task__content--right-column" />
            </ShowIf>
          </TaskContainer>
        </Accordion>
      </Area>
    );
  }
  const header = (
    <Header>
      <ExternalCosts value={externalCosts} previousValue={previous.external_cost} label="Area external costs" />
      <PercentageCell label="Area markup">{markup}</PercentageCell>
      <DaysCell label="Area days">{days}</DaysCell>
      <Cost label="Area costs">{cost}</Cost>
      <Price label="Area price">{price}</Price>
      <Switch id={id}
        editable={editableBudget}
        save={updateArea}
        checked={enabled} />
      <MoreOptions editable={editableBudget}>
        <MoreOptionsMenuItem onClick={onInsertModalClick}>
          Add product
        </MoreOptionsMenuItem>
        <MoreOptionsMenuItem onClick={showSortTaskModal}>
          Reorder tasks
        </MoreOptionsMenuItem>
      </MoreOptions>
    </Header>
  );
  return (
    <Area
      column={column}
      enabled={enabled}
      wasEnabled={previous.on}
      showShadows={props.showShadows}>
      <Accordion summary={header}
        showStatusIcon={false}
        expanded={!isCollapsed}
        onChange={onAccordionChange}>
        <PriceList
          column={column}
          editable={editableBudget}
          id={priceList.id}
          priceList={priceList}
          areaId={id} />
        <TaskContainer>
          {tasks}
          <ShowIf condition={editableBudget}>
            <LaptopAndAbove>
              <Task className="wethod-budget-task__content--right-column" />
            </LaptopAndAbove>
          </ShowIf>
        </TaskContainer>
      </Accordion>
    </Area>
  );
};

module.exports = UserDefinedArea;
