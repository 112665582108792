const React = require('react');
const Levels = require('./SummaryLevels.react');
const NameCell = require('../Cell/NameCell.react');
const PercentageCell = require('../Cell/PercentageCell.react');
const DaysCell = require('../Cell/ManDaysCell.react');
const MoreOptions = require('../MoreOptionsButton/MoreOptionsButton.react');
const Header = require('./SummaryHeader.react');
const AreaContainer = require('./SummaryAreaContainer.react');
const Area = require('../../../containers/Calculator/Area/AreaFactory');
const RequestManager = require('../../../containers/RequestManager');
const TaskCreator = require('../CreationRow/CreationRow.react');
const ShowIf = require('../../../../../../../common/react/ShowIf/ShowIf.react');
const Price = require('../Cell/PriceCell.react');
const Cost = require('../Cell/CostCell.react');
const IconCell = require('../Cell/IconCell.react');
const ExternalCosts = require('../../../containers/Calculator/Cell/ExternalCostHeaderCell');
const constants = require('../../../constants');
const Task = require('../Task/Task.react');
const LaptopAndAbove = require('../../../../../../../common/react/media-queries/LaptopAndAbove.react');

const Summary = ({
  id,
  column,
  name,
  levels,
  externalCosts,
  markup,
  cost,
  price,
  days,
  areas,
  editableBudget,
  createArea,
  isPending,
  withAreaCreator,
  previous,
  className = '',
  showModal,
  mainBudgetPriceListLevels,
  ...props
}) => {
  function handleAreaCreate(label) {
    // Expect areas ordered from smallest to biggest areas.sort
    const lastArea = areas[areas.length - 1];
    const sort = lastArea ? lastArea.sort + 1 : 1;

    createArea({ name: label, sort });
  }

  const areaList = areas.map((area) => (
    <RequestManager key={area.id}>
      <Area
        showShadows={props.showShadows}
        column={column}
        showModal={showModal}
        enabled={area.enabled}
        order={area.sort}
        id={area.id}
        days={area.total.days}
        editableBudget={editableBudget}
        name={area.name}
        previous={area.was}
        tasks={area.tasks}
        markup={area.total.markup}
        externalCosts={area.total.external_cost}
        cost={area.total.cost}
        price={area.total.price}
        levels={area.total.levels}
        type={area.type}
        priceList={area.price_list} />
    </RequestManager>
  ));

  const areaListCenter = areas
    .filter((area) => area.type !== constants.AREA_TYPE_CONTINGENCY_DISCOUNT)
    .map((area) => (
      <RequestManager key={area.id}>
        <Area
          fitContentWidth
          column={column}
          showModal={showModal}
          enabled={area.enabled}
          order={area.sort}
          id={area.id}
          days={area.total.days}
          editableBudget={editableBudget}
          name={area.name}
          previous={area.was}
          tasks={area.tasks}
          markup={area.total.markup}
          externalCosts={area.total.external_cost}
          cost={area.total.cost}
          price={area.total.price}
          levels={area.total.levels}
          type={area.type}
          priceLists={area.total.price_lists} />
      </RequestManager>
    ));

  if (column === 'left') {
    return (
      <div className={`wethod-budget-summary ${className}`}>
        <Header column={column} total showShadows={props.showShadows} id={id}>
          <NameCell className="wethod-budget-summary__name">{name}</NameCell>
          <PercentageCell />
        </Header>
        <AreaContainer>
          {areaList}
        </AreaContainer>
        <ShowIf condition={editableBudget && withAreaCreator}>
          <TaskCreator
            column={column}
            placeholder="+ New Area"
            className="wethod-budget-creation-row--area"
            budgetIsEditable={editableBudget}
            save={handleAreaCreate}
            isSaving={isPending('add-area')} />
        </ShowIf>
      </div>
    );
  }
  if (column === 'center') {
    return (
      <div className={`wethod-budget-summary ${className}`}>
        <Header
          column={column}
          setScrollingComponentScrollAmount={
          props.setScrollingComponentScrollAmount
          }
          name={name}
          scrollingComponentAmount={props.scrollingComponentAmount}
          horizontalScrollClass="wethod-budget-summary__header--horizontal-scroll">
          <Levels
            items={levels}
            mainBudgetPriceListLevels={mainBudgetPriceListLevels}
            previousItems={previous.levels} />
        </Header>
        <AreaContainer
          horizontalScroll
          className="wethod-budget-summary__area-container--horizontal-scroll"
          setScrollingComponentScrollAmount={
            props.setScrollingComponentScrollAmount
          }
          scrollingComponentAmount={props.scrollingComponentAmount}>
          {areaListCenter}
        </AreaContainer>
        <ShowIf condition={editableBudget && withAreaCreator}>
          <Task className="wethod-budget-area__content--new-area wethod-budget-creation-row--area" column={column} />
        </ShowIf>
      </div>
    );
  }
  return (
    <div className={`wethod-budget-summary ${className}`}>
      <Header column="right" showShadows={props.showShadows} id={id}>
        <ExternalCosts value={externalCosts} previousValue={previous.external_cost} />
        <PercentageCell>{markup}</PercentageCell>
        <DaysCell>{days}</DaysCell>
        <Cost>{cost}</Cost>
        <Price>{price}</Price>
        <IconCell />
        <MoreOptions />
      </Header>
      <AreaContainer>
        {areaList}
      </AreaContainer>
      <ShowIf condition={editableBudget && withAreaCreator}>
        <LaptopAndAbove>
          <Task className="wethod-budget-area__content--new-area wethod-budget-creation-row--area" column={column} />
        </LaptopAndAbove>
      </ShowIf>
    </div>
  );
};

module.exports = Summary;
