const { connect } = require('react-redux');
const Component = require('../../../components/Calculator/Task/BaseTask.react');
const actions = require('../../../actions');

const mapStateToProps = (state) => ({
  mainBudgetPriceListLevels: state.info.mainBudgetPriceListLevels,
});
const mapDispatchToProps = (dispatch) => ({
  showJobTitleInsertModal: (taskId, taskDays, taskJobTitles) => dispatch(actions
    .showJobTitleInsertModal(taskId, taskDays, taskJobTitles)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
