const React = require('react');

const BudgetSubmitModal = require('../containers/Footer/DefaultFooterContent/StatusButton/SubmitStatusButton/SubmitStatusButtonModal');
const BudgetApprovalModal = require('./Footer/DefaultFooterContent/StatusButton/ApprovalStatusButton/ApprovalStatusButtonModal.react');
const BudgetDraftModal = require('./Footer/DefaultFooterContent/StatusButton/DraftStatusButton/DraftStatusModal.react');
const SaveTemplateModal = require('../containers/Footer/DefaultFooterContent/Actions/SaveTemplateAction/SaveTemplateModal');
const DisableMulticurrencyModal = require('./Footer/DefaultFooterContent/Actions/MulticurrencyAction/DisableMulticurrencyModal.react');
const EnableMulticurrencyModal = require('./Footer/DefaultFooterContent/Actions/MulticurrencyAction/EnableMulticurrencyModal.react');
const ProjectStatusCorrectionModalManager = require('../containers/Footer/DefaultFooterContent/Actions/ProjectStatusCorrectionAction/ProjectStatusCorrectionModalManager');
const ConsumptionReportModal = require('../containers/Footer/DefaultFooterContent/Actions/ConsumptionReportAction/ConsumptionReportModal');
const LogModal = require('../containers/Footer/DefaultFooterContent/Actions/LogAction/LogModal.react');
const BaselineModal = require('../containers/Footer/DefaultFooterContent/Actions/BaselineAction/BaselineModal.react');
const NotesModal = require('../containers/Footer/DefaultFooterContent/Actions/NotesAction/NotesModal');
const DeleteTaskModal = require('./Calculator/Task/DeleteTaskModal/DeleteTaskModal.react');
const MakeTaskIntercompanyModal = require('../containers/Calculator/Task/MakeTaskIntercompanyModal/MakeTaskIntercompanyModal');
const SelectIntercompanyTaskSupplierModal = require('./Calculator/Task/SelectIntercompanySupplierModal/SelectIntercompanySupplierModal.react');
const InviteIntercompanyTaskSupplierModal = require('./Calculator/Task/InviteIntercompanySupplierModal/InviteIntercompanySupplierModal.react');
const TaskSortModal = require('./Calculator/Area/TaskSortModal/TaskSortModal.react');
const ApiErrorModal = require('./ApiErrorModal/ApiErrorModal.react');
const IntercompanyTaskDetailsModal = require('../containers/Calculator/Task/IntercompanyDetailsModal/IntercompanyDetailsModal');
const modals = require('../modals');
const CreationModeModal = require('../containers/CreationModal/CreationModeModal');
const TemplateListModal = require('../containers/CreationModal/TemplateListModal');
const JobTitleInsertModal = require('./Calculator/Task/JobTitleInsertModal/JobTitleInsertModal.react');
const JobTitleDeleteModal = require('./Calculator/JobTitle/DeleteJobTitleModal/DeleteJobTitleModal.react');
const ProductInsertModal = require('./Calculator/Area/ProductInsertModal/ProductInsertModal.react');
const PricelistModal = require('../containers/Calculator/Header/PricelistModal/PricelistModal');
const DailyCostPriceLevelModal = require('./DailyCostPriceLevelModal/DailyCostPriceLevelModal.react');

const availableModals = {
  [modals.BUDGET_SUBMIT]: BudgetSubmitModal,
  [modals.BUDGET_APPROVAL]: BudgetApprovalModal,
  [modals.BUDGET_DRAFT]: BudgetDraftModal,
  [modals.SAVE_TEMPLATE]: SaveTemplateModal,
  [modals.DISABLE_MULTICURRENCY]: DisableMulticurrencyModal,
  [modals.ENABLE_MULTICURRENCY]: EnableMulticurrencyModal,
  [modals.CONSUMPTION_REPORT]: ConsumptionReportModal,
  [modals.LOG]: LogModal,
  [modals.NOTES]: NotesModal,
  [modals.TASK_DELETE]: DeleteTaskModal,
  [modals.TASK_MAKE_INTERCOMPANY]: MakeTaskIntercompanyModal,
  [modals.TASK_SELECT_INTERCOMPANY_SUPPLIER]: SelectIntercompanyTaskSupplierModal,
  [modals.TASK_INTERCOMPANY_INVITE_SUPPLIER]: InviteIntercompanyTaskSupplierModal,
  [modals.TASK_SORT_MODAL]: TaskSortModal,
  [modals.API_ERROR_MODAL]: ApiErrorModal,
  [modals.TASK_INTERCOMPANY_DETAILS]: IntercompanyTaskDetailsModal,
  [modals.BUDGET_CREATION_MODAL]: CreationModeModal,
  [modals.BUDGET_TEMPLATE_LIST_MODAL]: TemplateListModal,
  [modals.JOB_TITLE_INSERT_MODAL]: JobTitleInsertModal,
  [modals.JOB_TITLE_DELETE_MODAL]: JobTitleDeleteModal,
  [modals.PRODUCT_INSERT_MODAL]: ProductInsertModal,
  [modals.PRICELIST_MODAL]: PricelistModal,
  [modals.BASELINE_MODAL]: BaselineModal,
  [modals.DAILY_COST_PRICE_LEVEL_MODAL]: DailyCostPriceLevelModal,
  [modals.PS_CORRECTION]: ProjectStatusCorrectionModalManager,
};

module.exports = ({
  toShow,
  closeModal,
  modalData,
}) => {
  const Component = availableModals[toShow];
  if (Component) {
    return <Component closeModal={closeModal} {...modalData} />;
  }

  return null;
};
