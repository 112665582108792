const React = require('react');
const DaysCell = require('../Cell/DaysCell.react');
const Tooltip = require('../../../../../../../common/react/TooltipFixed/Tooltip.react');
const TooltipMessage = require('../../../containers/Header/HeaderLevelTooltipMessage');

const HeaderLevel = ({
  children,
  name,
  price,
  cost,
  levelId,
}) => (
  <DaysCell>
    <Tooltip className="wethod-budget-calculator__level-info-tooltip wethod-budget-calculator__level-info-tooltip-click"
      hoverable
      label={(
        <TooltipMessage
          name={name}
          price={price}
          cost={cost}
          levelId={levelId} />
        )}>
      {children}
    </Tooltip>
  </DaysCell>
);

module.exports = HeaderLevel;
