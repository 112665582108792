const React = require('react');
const sidebarService = require('./services/SidebarService');
const SlideInTransition = require('../SlideInTransition/SlideInTransition.react');

class SidebarManager extends React.Component {
  static disableBodyScroll() {
    document.body.classList.add('wethod-no-scroll');
  }

  static enableBodyScroll() {
    document.body.classList.remove('wethod-no-scroll');
  }

  constructor(props) {
    super(props);

    /**
     * @type {{current: ?JSX.Element}}
     */
    this.state = {
      current: <span />,
      visible: false,
    };

    this.handleSidebarChange = this.handleSidebarChange.bind(this);
  }

  componentDidMount() {
    sidebarService.subscribe(this.handleSidebarChange);
  }

  componentWillUnmount() {
    sidebarService.unsubscribe(this.handleSidebarChange);
  }

  /**
   * @param {?JSX.Element} current
   */
  handleSidebarChange(current) {
    if (current) {
      SidebarManager.disableBodyScroll();
      this.setState({ current, visible: true });
    } else {
      SidebarManager.enableBodyScroll();
      this.setState({ visible: false });
    }
  }

  getSidebar() {
    return React.cloneElement(this.state.current, {
      onCloseClick: sidebarService.closeSidebar.bind(sidebarService),
    });
  }

  render() {
    return (
      <SlideInTransition triggered={this.state.visible} animateFirstMount>
        {this.getSidebar()}
      </SlideInTransition>
    );
  }
}

module.exports = SidebarManager;
