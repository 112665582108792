const React = require('react');
const PropTypes = require('prop-types');
const ChartSidebarStep = require('./ChartSidebarStep.react');
const Dimension = require('./ChartSidebarDimension.react');
const ChartConfig = require('../../../../apps/core/modules/insights/revenues/models/ChartConfig');
const ChartConfigDimensions = require('../../../../apps/core/modules/insights/revenues/models/ChartConfigDimensions');
const DimensionSelectItem = require('./models/DimensionSelectItem');
const ChartAxis = require('./models/ChartAxis');
const TimeDimension = require('../../../../apps/core/modules/insights/revenues/models/ChartConfigDimension/ChartConfigTimeDimension/ChartConfigTimeDimension');
const ProbabilityDimension = require('../../../../apps/core/modules/insights/revenues/models/ChartConfigDimension/ChartConfigProbabilityDimension/ChartConfigProbabilityDimension');
const ClientDimension = require('../../../../apps/core/modules/insights/revenues/models/ChartConfigDimension/ChartConfigClientDimension/ChartConfigClientDimension');
const RevenuesDimension = require('../../../../apps/core/modules/insights/revenues/models/ChartConfigDimension/ChartConfigRevenuesDimension/ChartConfigRevenuesDimension');
const AccountDimension = require('../../../../apps/core/modules/insights/revenues/models/ChartConfigDimension/ChartConfigAccountDimension/ChartConfigAccountDimension');
const ProjectTypeDimension = require('../../../../apps/core/modules/insights/revenues/models/ChartConfigDimension/ChartConfigProjectTypeDimension/ChartConfigProjectTypeDimension');
const JocDimension = require('../../../../apps/core/modules/insights/revenues/models/ChartConfigDimension/ChartConfigJobOrderCategoryDimension/ChartConfigJobOrderCategoryDimension');

const ChartSidebarDimensionsStep = ({
  chartType, updateErrors, getErrorText, chartDimensions,
  onChange, hasError,
}) => {
  const availableDimensions = [
    new DimensionSelectItem(RevenuesDimension.KEY, 'Revenues'),
    new DimensionSelectItem(ProbabilityDimension.KEY, 'Probability'),
    new DimensionSelectItem(ClientDimension.KEY, 'Client'),
    new DimensionSelectItem(TimeDimension.KEY, 'Time'),
    new DimensionSelectItem(AccountDimension.KEY, 'Account'),
    new DimensionSelectItem(ProjectTypeDimension.KEY, 'Project type'),
    new DimensionSelectItem(JocDimension.KEY, 'Job order category'),
  ];

  const getAxis = () => {
    switch (chartType) {
      case ChartConfig.TYPE_STACKED_BAR:
        return [
          new ChartAxis('x', 'X Axis', false),
          new ChartAxis('y', 'Y Axis', true),
          new ChartAxis('color', 'Color', false),
        ];
      default:
        return [
          new ChartAxis('x', 'X Axis', false),
          new ChartAxis('y', 'Y Axis', true),
        ];
    }
  };

  const getDimensions = () => getAxis().map((axis) => (
    <Dimension availableDimensions={availableDimensions}
      axisLabel={axis.label}
      key={axis.key}
      axisKey={axis.key}
      isDataSourceAxis={axis.isDataSourceAxis}
      updateErrors={updateErrors}
      getError={getErrorText}
      dimension={chartDimensions[axis.key]}
      onChange={onChange} />
  ));

  return (
    <ChartSidebarStep title="Dimensions"
      hasError={hasError}>
      <div>
        {getDimensions()}
      </div>
    </ChartSidebarStep>
  );
};

ChartSidebarDimensionsStep.propTypes = {
  chartType: PropTypes.string.isRequired,
  chartDimensions: PropTypes.instanceOf(ChartConfigDimensions).isRequired,
  updateErrors: PropTypes.func.isRequired,
  getErrorText: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
};

module.exports = ChartSidebarDimensionsStep;
