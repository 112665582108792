const React = require('react');
const PropTypes = require('prop-types');
const Button = require('../../../../../../../common/react/Button/RoundedButton.react');
const T = require('../../../../../../../common/react/Typography/Typography.react');
const MoreOptionsButton = require('../../../../../../../common/react/MoreOptionsButton/MoreOptionsButton.react');
const MenuItem = require('../../../../../../../common/react/Menu/ListMenu/MenuItem.react');
const ShowIf = require('../../../../../../../common/react/ShowIf/ShowIf.react');
const modalService = require('../../../../../../../common/react/Modal/ModalManager/services/ModalService');
const DeleteChartModal = require('../modals/DeleteChartModal.react');
/**
 * Tab used to select and show a Chart in an InsightLevelChartPicker.
 * @param {number} id Chart id
 * @param {string} title Chart title
 * @param onClick Function to call when tab is clicked
 * @param onDeleteClick Function to call when delete option is clicked
 * @param {bool} canDelete If user can delete chart or not
 * @param {boolean} selected If current tab is selected or not
 * @return {JSX.Element}
 * @constructor
 */
const InsightLevelChartTab = ({
  id, title, onClick, onDeleteChart, setRef,
  canDelete = false, selected = false,
}) => {
  function handleClick() {
    onClick(id);
  }

  function handleDeleteChart() {
    onDeleteChart(id);
  }

  function updateRef(el) {
    setRef(id, el);
  }

  function handleDeleteClick() {
    const modal = (
      <DeleteChartModal chartName={title}
        chartId={id}
        onDelete={handleDeleteChart} />
    );
    modalService.openModal(modal);
  }

  const getButtonClassName = () => {
    let name = 'wethod-insight-level__chart-picker-tab-button';
    if (selected) {
      name = `${name} ${name}--selected`;
    }
    return name;
  };

  /**
   * Return options to show in "more options" menu.
   * @return {JSX.Element[]}
   */
  const getOptions = () => {
    const options = [];
    if (canDelete) {
      options.push(<MenuItem key="delete" onClick={handleDeleteClick}>Delete</MenuItem>);
    }
    return options;
  };

  return (
    <li className="wethod-insight-level__chart-picker-tab" role="tab" aria-selected={selected} ref={updateRef}>
      <Button onClick={handleClick}
        label={title}
        className={getButtonClassName()}
        border={false}>
        <T size={T.SIZES.PX14}>
          {title}
        </T>
      </Button>
      <ShowIf condition={getOptions().length > 0}>
        <MoreOptionsButton>
          {getOptions()}
        </MoreOptionsButton>
      </ShowIf>
    </li>
  );
};

InsightLevelChartTab.defaultProps = {
  onClick: null,
  onDeleteChart: null,
  canDelete: false,
  selected: false,
};

InsightLevelChartTab.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  onDeleteChart: PropTypes.func,
  canDelete: PropTypes.bool,
  selected: PropTypes.bool,
  setRef: PropTypes.func.isRequired,
};

module.exports = InsightLevelChartTab;
