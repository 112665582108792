const ChartConfigFilterTime = require('./ChartConfigFilterTime');
const ChartConfigFilter = require('./ChartConfigFilter');

class ChartConfigFilterTimeRelative extends ChartConfigFilterTime {
  /**
   * @param {string} value
   */
  constructor(value) {
    super(ChartConfigFilter.TYPE_RELATIVE);
    this.value = value;
  }

  /**
   * @param data
   * @return {ChartConfigFilterTimeRelative}
   */
  static fromJSON(data) {
    return new ChartConfigFilterTimeRelative(data.value);
  }
}

ChartConfigFilterTimeRelative.VALUE_YEAR_TO_DATE = 'ytd';
ChartConfigFilterTimeRelative.VALUE_THIS_YEAR = 'current';
ChartConfigFilterTimeRelative.VALUE_LAST_YEAR = 'last-1';
ChartConfigFilterTimeRelative.VALUE_LAST_3_YEARS = 'last-3';
ChartConfigFilterTimeRelative.VALUE_LAST_5_YEARS = 'last-5';

module.exports = ChartConfigFilterTimeRelative;
