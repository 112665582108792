/* eslint-disable react/prop-types,react/sort-comp,react/no-access-state-in-setstate,
 jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
const React = require('react');
const PropTypes = require('prop-types');
const HelperModal = require('./HelperModal.react');
const HelperModalModel = require('../models/HelperModalModel');
const Bookmark = require('./Bookmark.react');
const Button = require('../../TooltipFixed/IconButtonTooltip.react');
const Icon = require('../../Icon/Icon.react');
const Collaborators = require('./Collaborators/Collaborators.react');

class SectionHeader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showHelper: false,
    };

    this.setDocumentTitle();

    this.model = new HelperModalModel(
      {
        helper_url: this.props.helper_url,
        tour_id: this.props.tour_id,
        tour_start_page: this.props.tour_start_page,
      },
    );
  }

  componentDidMount() {
    dispatcher.trigger('section-header:mounted', this.props.current_section);
  }

  /**
   * Set the HTML document title, for example "Dashboard | hic | WETHOD".
   */
  setDocumentTitle() {
    if (Wethod.userInfo) {
      document.title = `${this.props.current_section} | ${Wethod.userInfo.get('company')} | WETHOD`;
    } else {
      document.title = 'WETHOD';
    }
  }

  onHelperButtonClick() {
    this.setState({ showHelper: !this.state.showHelper });
  }

  onHelperClose() {
    this.setState({ showHelper: false });
  }

  getHelper() {
    if (this.state.showHelper) {
      return (
        <HelperModal model={this.model}
          onClose={this.onHelperClose.bind(this)} />
      );
    }
    return null;
  }

  getHelperModalClassName() {
    let name = 'section-header__helper-modal';
    if (this.state.showHelper) {
      name += ' wethod-modal2';
    }
    return name;
  }

  getBookmark() {
    const fragment = this.props.bookmark_fragment;
    if (fragment) {
      return <Bookmark fragment={fragment} />;
    }
    return null;
  }

  /**
   * Used for width transition from 1024 to 1440.
   * @returns {string}
   */
  getGridClassName() {
    let name = 'section-header__grid';
    if (this.props.big) {
      name += ' section-header__grid--big';
    }
    return name;
  }

  getCollaborators() {
    return this.props.sectionId ? <Collaborators sectionId={this.props.sectionId} /> : null;
  }

  render() {
    return (
      <div className="section-header">
        <div className="section-header__content">
          <div className={this.getGridClassName()}>
            <div className="section-header__title-grid">
              <span className="section-title__current">{this.props.current_section}</span>
              {this.getBookmark()}
            </div>
            <div className="section-header__helper-alt">
              {this.getCollaborators()}
            </div>
            <div className="section-header__helper-button-grid">
              <Button label="Learning Center" onClick={this.onHelperButtonClick.bind(this)}>
                <Icon icon="learning-center" />
              </Button>
            </div>
          </div>
        </div>
        <div className="section-header__bottom-spacer" />
        <div className={this.getHelperModalClassName()}>
          {this.getHelper()}
        </div>
      </div>
    );
  }
}

SectionHeader.defaultProps = {
  big: false,
  tour_id: null,
  tour_start_page: null,
  bookmark_fragment: null,
  sectionId: null,
};

SectionHeader.propTypes = {
  /**
   * The URL fragment to reach a specific guide's section.
   * I.e. helper_url="data-freezing-engine/index/" creates a link to
   * support.wethod.com/data-freezing-engine/index/
   */
  helper_url: PropTypes.string.isRequired,
  /**
   * Intercom tour ID, if any.
   */
  tour_id: PropTypes.number,
  /**
   * In which wethod page the Intercom tour should start.
   */
  tour_start_page: PropTypes.string,
  /**
   * Section title.
   */
  current_section: PropTypes.string.isRequired,
  /**
   * Used to save page as bookmark.
   * I.e. "project/12/canvas"
   */
  bookmark_fragment: PropTypes.string,
  /**
   * If true, SectionHeader span all available horizontal space.
   */
  big: PropTypes.bool,
  /**
   * Unique ID for this section. Used to display collaborators on this page.
   */
  sectionId: PropTypes.string,
};

module.exports = SectionHeader;
