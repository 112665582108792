const TimeDimension = require('./ChartConfigDimension/ChartConfigTimeDimension/ChartConfigTimeDimension');
const TableConfig = require('./ChartConfigTable/ChartConfigTable');
const FilterClient = require('./ChartConfigFilter/ChartConfigFilterClient');
const FilterProbability = require('./ChartConfigFilter/ChartConfigFilterProbability');
const FilterTime = require('./ChartConfigFilter/ChartConfigFilterTime');
const FilterProjectType = require('./ChartConfigFilter/ChartConfigFilterProjectType');
const FilterJoc = require('./ChartConfigFilter/ChartConfigFilterJobOrderCategory');
const FilterAccount = require('./ChartConfigFilter/ChartConfigFilterAccount');
const FilterAccountIncluded = require('./ChartConfigFilter/ChartConfigFilterAccountIncluded');
const FilterAccountNotIncluded = require('./ChartConfigFilter/ChartConfigFilterAccountNotIncluded');
const BarChartDimensions = require('./BarChartConfigDimensions');
const ChartConfigFilter = require('./ChartConfigFilter/ChartConfigFilter');
const ClientFilterIncluded = require('./ChartConfigFilter/ChartConfigFilterClientIncluded');
const ClientFilterNotIncluded = require('./ChartConfigFilter/ChartConfigFilterClientNotIncluded');
const ProbabilityFilterRange = require('./ChartConfigFilter/ChartConfigFilterProbabilityRange');
const ProbabilityFilterIncluded = require('./ChartConfigFilter/ChartConfigFilterProbabilityIncluded');
const TimeFilterIncluded = require('./ChartConfigFilter/ChartConfigFilterTimeIncluded');
const TimeFilterRelative = require('./ChartConfigFilter/ChartConfigFilterTimeRelative');
const ProjectTypeIncludedFilter = require('./ChartConfigFilter/ChartConfigFilterProjectTypeIncluded');
const ProjectTypeNotIncludedFilter = require('./ChartConfigFilter/ChartConfigFilterProjectTypeNotIncluded');
const JocIncludedFilter = require('./ChartConfigFilter/ChartConfigFilterJobOrderCategoryIncluded');
const JocNotIncludedFilter = require('./ChartConfigFilter/ChartConfigFilterJobOrderCategoryNotIncluded');

class ChartConfig {
  /**
   *
   * @param {string} name
   * @param {string} description
   * @param {string} type
   * @param {ChartConfigFilter[]} filters
   * @param {BarChartConfigDimensions} dimensions
   * @param {ChartConfigTable} table
   */
  constructor(name, description, type, filters = [], dimensions, table) {
    this.name = name;
    this.description = description;
    this.type = type;
    this.filters = filters;
    this.dimensions = dimensions;
    this.table = table;
  }

  /**
   * @param {string} axis
   * @return {string}
   */
  getDimensionType(axis) {
    const dimension = this.dimensions[axis];
    if (dimension instanceof TimeDimension) {
      return dimension.grouping.value;
    }
    return dimension.key;
  }

  /**
   * @param {string} target
   * @return {?ChartConfigFilter}
   */
  findFilterByTarget(target) {
    return this.filters.find((filter) => filter.target === target);
  }

  /**
   * @param {string} target
   * @return {?ChartConfigFilter[]}
   */
  removeFilter(target) {
    this.filters = this.filters.filter((filter) => filter.target !== target);
    return this.filters;
  }

  /**
   * Add filter.
   * Remove old filter with same target if found.
   * @param {ChartConfigFilter} filter
   * @return {?ChartConfigFilter[]}
   */
  addFilter(filter) {
    this.removeFilter(filter.target).push(filter);

    return this.filters;
  }

  /**
   *
   * @param {string} axis
   * @param {ChartConfigDimension} dimension
   */
  updateDimension(axis, dimension) {
    this.dimensions[axis] = dimension;
  }

  clearFilters() {
    this.filters = [];
  }

  /**
   * Return a copy of this instance.
   * @return {ChartConfig}
   */
  clone() {
    return new ChartConfig(this.name, this.description, this.type, this.filters,
      this.dimensions.clone(), this.table);
  }

  /**
   * Return attributes to serialize when calling JSON.stringify on this object.
   */
  toJSON() {
    return {
      name: this.name,
      description: this.description,
      type: this.type,
      filters: this.filters,
      dimensions: this.dimensions,
    };
  }

  /**
   * Build and return probability filter based on given data.
   * @param probabilityFilterData
   * @return {ChartConfigFilter}
   */
  static probabilityFilterFromJSON(probabilityFilterData) {
    switch (probabilityFilterData.type) {
      case ChartConfigFilter.TYPE_IN:
        return ProbabilityFilterIncluded.fromJSON(probabilityFilterData);
      case ChartConfigFilter.TYPE_RANGE:
        return ProbabilityFilterRange.fromJSON(probabilityFilterData);
      default:
        throw new Error(`'${probabilityFilterData.type}' is not a valid type for a time filter`);
    }
  }

  /**
   * Build and return time filter based on given data.
   * @param timeFilterData
   * @return {ChartConfigFilterTime}
   */
  static timeFilterFromJSON(timeFilterData) {
    switch (timeFilterData.type) {
      case ChartConfigFilter.TYPE_IN:
        return TimeFilterIncluded.fromJSON(timeFilterData);
      case ChartConfigFilter.TYPE_RELATIVE:
        return TimeFilterRelative.fromJSON(timeFilterData);
      default:
        throw new Error(`'${timeFilterData.type}' is not a valid type for a probability filter`);
    }
  }

  /**
   * Build and return client filter based on given data.
   * @param clientFilterData
   * @return {ChartConfigFilterClient}
   */
  static clientFilterFromJSON(clientFilterData) {
    switch (clientFilterData.type) {
      case ChartConfigFilter.TYPE_IN:
        return ClientFilterIncluded.fromJSON(clientFilterData);
      case ChartConfigFilter.TYPE_NOT_IN:
        return ClientFilterNotIncluded.fromJSON(clientFilterData);
      default:
        throw new Error(`'${clientFilterData.type}' is not a valid type for a client filter`);
    }
  }

  /**
   * Build and return account filter based on given data.
   * @param accountFilterData
   * @return {ChartConfigFilterAccount}
   */
  static accountFilterFromJSON(accountFilterData) {
    switch (accountFilterData.type) {
      case ChartConfigFilter.TYPE_IN:
        return FilterAccountIncluded.fromJSON(accountFilterData);
      case ChartConfigFilter.TYPE_NOT_IN:
        return FilterAccountNotIncluded.fromJSON(accountFilterData);
      default:
        throw new Error(`'${accountFilterData.type}' is not a valid type for a account filter`);
    }
  }

  /**
   * Build and return project type filter based on given data.
   * @param filterData
   * @return {ChartConfigFilterProjectType}
   */
  static projectTypeFilterFromJSON(filterData) {
    switch (filterData.type) {
      case ChartConfigFilter.TYPE_IN:
        return ProjectTypeIncludedFilter.fromJSON(filterData);
      case ChartConfigFilter.TYPE_NOT_IN:
        return ProjectTypeNotIncludedFilter.fromJSON(filterData);
      default:
        throw new Error(`'${filterData.type}' is not a valid type for a project type filter`);
    }
  }

  /**
   * Build and return job order category filter based on given data.
   * @param filterData
   * @return {ChartConfigFilterJobOrderCategory}
   */
  static jocFilterFromJSON(filterData) {
    switch (filterData.type) {
      case ChartConfigFilter.TYPE_IN:
        return JocIncludedFilter.fromJSON(filterData);
      case ChartConfigFilter.TYPE_NOT_IN:
        return JocNotIncludedFilter.fromJSON(filterData);
      default:
        throw new Error(`'${filterData.type}' is not a valid type for a job order category filter`);
    }
  }

  /**
   * Build and return filter based on given data.
   * @param filterData
   * @return {ChartConfigFilter}
   */
  static filterFromJSON(filterData) {
    switch (filterData.target) {
      case FilterClient.TARGET:
        return ChartConfig.clientFilterFromJSON(filterData);
      case FilterProbability.TARGET:
        return ChartConfig.probabilityFilterFromJSON(filterData);
      case FilterTime.TARGET:
        return ChartConfig.timeFilterFromJSON(filterData);
      case FilterAccount.TARGET:
        return ChartConfig.accountFilterFromJSON(filterData);
      case FilterProjectType.TARGET:
        return ChartConfig.projectTypeFilterFromJSON(filterData);
      case FilterJoc.TARGET:
        return ChartConfig.jocFilterFromJSON(filterData);
      default:
        throw new Error(`'${filterData.target}' is not a valid target for a chart filter`);
    }
  }

  /**
   * Build and return chart dimensions on given data.
   * @param dimensionsData
   * @param type
   * @return {BarChartConfigDimensions}
   */
  static dimensionsFromJSON(dimensionsData, type) {
    switch (type) {
      case ChartConfig.TYPE_STACKED_BAR:
      case ChartConfig.TYPE_BAR:
        return BarChartDimensions.fromJSON(dimensionsData);
      default:
        throw new Error(`'${type}' is not a valid chart type`);
    }
  }

  /**
   * Build and return chart config based on given filter data.
   * @param data
   * @return {ChartConfig}
   */
  static fromJSON(data) {
    const filters = data.filters.map(ChartConfig.filterFromJSON);
    const dimensions = ChartConfig.dimensionsFromJSON(data.dimensions, data.type);
    const tableConfig = TableConfig.fromJSON(data.table);

    return new ChartConfig(data.name, data.description, data.type, filters, dimensions,
      tableConfig);
  }
}

ChartConfig.TYPE_BAR = 'bar';
ChartConfig.TYPE_STACKED_BAR = 'stacked-bar';

module.exports = ChartConfig;
