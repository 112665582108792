/* eslint-disable no-prototype-builtins,no-restricted-syntax */
const $ = require('jquery');
const constants = require('./constants');
const TemplateModel = require('../../../../models/BudgetTemplate');
const BudgetModel = require('../../../../models/Budget');
const TaskModel = require('../../../../models/BudgetTask');
const AreaModel = require('../../../../models/BudgetArea');
const ProjectStatusCorrectionModel = require('../../../../models/ProjectStatusCorrection');
const ProjectReportModel = require('../../../../models/ProjectReport');
const modals = require('./modals');
const DayModel = require('../../../../models/BudgetDay');
const CurrencyModel = require('../../../../models/Currency');
const BudgetJobTitleModel = require('../../../../models/BudgetJobTitle');
const BudgetProductModel = require('../../../../models/BudgetProduct');
const BudgetPriceListLevel = require('../../../../models/BudgetPriceListLevel');
const BudgetBaseline = require('../../../../models/BudgetBaseline');
const VersionModel = require('../../../../models/BudgetVersion');
const Pricelist = require('../../../../models/Pricelist');
const BudgetPricelistModel = require('../../../../models/BudgetPricelist');
const BudgetArea = require('../../../../models/BudgetArea');
const BudgetInfoService = require('./services/BudgetInfoService');

const collaboratorUpdatedInfo = (info) => ({
  type: constants.COLLABORATOR_UPDATE_INFO,
  updatedBudgetInfo: info,
});

module.exports.updateInfoFromCollaborator = (info) => (dispatch) => {
  dispatch(collaboratorUpdatedInfo(info));
};

const collaboratorUpdatedArea = (area) => ({
  type: constants.COLLABORATOR_UPDATE_AREA,
  area,
});

module.exports.updateAreaFromCollaborator = (area) => (dispatch) => {
  dispatch(collaboratorUpdatedArea(area));
};

const collaboratorUpdatedTaskInfo = (taskInfo) => ({
  type: constants.COLLABORATOR_UPDATE_TASK_INFO,
  taskInfo,
});

module.exports.updateTaskInfoFromCollaborator = (taskInfo) => (dispatch) => {
  dispatch(collaboratorUpdatedTaskInfo(taskInfo));
};

const collaboratorUpdatedTaskLevelAmount = (info) => ({
  type: constants.COLLABORATOR_UPDATE_TASK_LEVEL_AMOUNT,
  info,
});

module.exports.updateTaskLevelAmountFromCollaborator = (info) => (dispatch) => {
  dispatch(collaboratorUpdatedTaskLevelAmount(info));
};

const collaboratorUpdatedTaskValues = (info) => ({
  type: constants.COLLABORATOR_UPDATE_TASK_VALUES,
  info,
});

module.exports.updateTaskValuesFromCollaborator = (info) => (dispatch) => {
  dispatch(collaboratorUpdatedTaskValues(info));
};

const collaboratorDeleteTask = (info) => ({
  type: constants.COLLABORATOR_DELETE_TASK,
  info,
});

const collaboratorCreateTask = (info) => ({
  type: constants.COLLABORATOR_CREATE_TASK,
  info,
});

module.exports.createTaskFromCollaborator = (info) => (dispatch) => {
  dispatch(collaboratorCreateTask(info));
};
module.exports.deleteTaskFromCollaborator = (info) => (dispatch) => {
  dispatch(collaboratorDeleteTask(info));
};

const collaboratorCreateArea = (info) => ({
  type: constants.COLLABORATOR_CREATE_AREA,
  info,
});

module.exports.createAreaFromCollaborator = (info) => (dispatch) => {
  dispatch(collaboratorCreateArea(info));
};

const collaboratorUpdateTaskOrder = (info) => ({
  type: constants.COLLABORATOR_UPDATE_TASK_ORDER,
  info,
});

module.exports.updateTaskOrderFromCollaborator = (info) => (dispatch) => {
  dispatch(collaboratorUpdateTaskOrder(info));
};

const collaboratorInsertJobTitle = (info) => ({
  type: constants.COLLABORATOR_INSERT_JOB_TITLE,
  info,
});

module.exports.insertJobTitleFromCollaborator = (info) => (dispatch) => {
  dispatch(collaboratorInsertJobTitle(info));
};

const collaboratorRemoveJobTitle = (info) => ({
  type: constants.COLLABORATOR_DELETE_JOB_TITLE,
  info,
});

module.exports.removeJobTitleFromCollaborator = (info) => (dispatch) => {
  dispatch(collaboratorRemoveJobTitle(info));
};

const closeModal = () => ({
  type: constants.CLOSE_MODAL,
});

module.exports.closeModal = closeModal;

const showModal = (key, data) => ({
  type: 'SHOW_MODAL',
  key,
  data,
});

module.exports.showModalBudgetSubmit = () => showModal(modals.BUDGET_SUBMIT);
module.exports.showModalBudgetApproval = () => showModal(modals.BUDGET_APPROVAL);
module.exports.showModalBudgetDraft = () => showModal(modals.BUDGET_DRAFT);
module.exports.showModalSaveTemplate = () => showModal(modals.SAVE_TEMPLATE);
module.exports.showModalDisableMulticurrency = () => showModal(modals.DISABLE_MULTICURRENCY);
module.exports.showModalEnableMulticurrency = () => showModal(modals.ENABLE_MULTICURRENCY);
module.exports.showModalProjectStatusCorrection = () => showModal(modals.PS_CORRECTION);
module.exports.showModalConsumptionReport = () => showModal(modals.CONSUMPTION_REPORT);
module.exports.showModalLog = () => showModal(modals.LOG);
module.exports.showModalNotes = () => showModal(modals.NOTES);
module.exports.showModalPricelist = (areaId, selectedPriceListId) => showModal(
  modals.PRICELIST_MODAL, {
    areaId,
    selectedPriceListId,
  },
);

module.exports.showModalBaseline = () => showModal(modals.BASELINE_MODAL);

module.exports.showDeleteTaskModal = (taskId) => showModal(
  modals.TASK_DELETE, {
    taskId,
  },
);
module.exports.showDeleteJobTitleModal = (dayId, taskId, jobTitleId) => showModal(
  modals.JOB_TITLE_DELETE_MODAL, {
    jobTitleId,
    taskId,
    dayId,
  },
);

module.exports.showSelectIntercompanyTaskSupplier = (task) => showModal(
  modals.TASK_SELECT_INTERCOMPANY_SUPPLIER, {
    task,
  },
);
module.exports.showMakeTaskIntercompanyModal = (
  task, days, markup, jobTitles = [],
) => showModal(
  modals.TASK_MAKE_INTERCOMPANY, {
    task,
    days,
    markup,
    jobTitles,
  },
);
module.exports.showInviteIntercompanySupplierModal = (areaId, taskId, supplierCompany) => showModal(
  modals.TASK_INTERCOMPANY_INVITE_SUPPLIER, {
    taskId,
    areaId,
    taskSupplier: supplierCompany,
  },
);

module.exports.showIntercompanyTaskDetailsModal = (taskId, intercompany) => showModal(
  modals.TASK_INTERCOMPANY_DETAILS, {
    taskId,
    intercompany,
  },
);
module.exports.showSortTaskModal = (areaId, areaOrder, tasks) => showModal(modals
  .TASK_SORT_MODAL, {
  areaId,
  areaOrder,
  tasks,
});

module.exports.showJobTitleInsertModal = (taskId, taskDays, taskJobTitles) => showModal(
  modals.JOB_TITLE_INSERT_MODAL, {
    taskId,
    taskDays,
    taskJobTitles,
  },
);

module.exports.showProductInsertModal = (areaId, nextTaskOrder) => showModal(
  modals.PRODUCT_INSERT_MODAL, {
    areaId,
    nextTaskOrder,
  },
);

module.exports.showCreationModeModal = () => showModal(modals.BUDGET_CREATION_MODAL);

module.exports.showCreationTemplateModal = () => showModal(modals.BUDGET_TEMPLATE_LIST_MODAL);

module.exports.closeSidebar = () => ({
  type: constants.CLOSE_SIDEBAR,
});

module.exports.showSidebar = () => ({
  type: 'SHOW_SIDEBAR',
});

module.exports.enterCompareVersionMode = () => ({
  type: constants.COMPARE_VERSIONS_MODE_ENTER,
});

const getVersionRequest = () => ({
  type: constants.GET_VERSION_REQUEST,
});

const getVersionFailure = () => ({
  type: constants.GET_VERSION_FAILURE,
});

const getVersionSuccess = (
  info,
  version,
  currencies,
  logs,
) => ({
  type: constants.GET_VERSION_SUCCESS,
  info,
  version,
  currencies,
  logs,
});

const getVersion = (projectId, version) => (dispatch) => {
  dispatch(getVersionRequest());

  $.when(
    VersionModel.get(projectId, version),
    CurrencyModel.getWithProjectRates(projectId),
    BudgetModel.getLogs(projectId),
  ).done((info, currencies, logs) => {
    const infoBudget = BudgetInfoService
      .formatBudgetInfo(info);
    dispatch(getVersionSuccess(
      infoBudget,
      infoBudget.version,
      currencies,
      logs,
    ));
  }).fail(() => dispatch(getVersionFailure()));
};

module.exports.exitCompareVersionMode = (projectId) => (dispatch) => {
  dispatch(getVersion(projectId));

  dispatch({
    type: constants.COMPARE_VERSIONS_MODE_EXIT,
  });
};

const saveTemplateSuccess = () => ({
  type: constants.SAVE_TEMPLATE_SUCCESS,
});

const saveTemplateRequest = () => ({
  type: constants.SAVE_TEMPLATE_REQUEST,
});

module.exports.saveTemplate = (budgetId, name, description) => (dispatch) => {
  dispatch(saveTemplateRequest());

  const template = { name, description };
  $.when(TemplateModel.createTemplate(budgetId, template))
    .done(() => {
      dispatch(saveTemplateSuccess(budgetId));
    });
};

const updateInfoRequest = (updatedBudgetInfo, optimistic) => ({
  type: constants.UPDATE_INFO_REQUEST,
  updatedBudgetInfo,
  optimistic,
});

const updateInfoSuccess = (updatedBudgetInfo, optimistic) => ({
  type: constants.UPDATE_INFO_SUCCESS,
  updatedBudgetInfo,
  optimistic,
});

const updateInfoFailure = (message) => ({
  type: constants.UPDATE_INFO_FAILURE,
  message,
});

const emitSocketEvent = (projectId, eventName, eventInfo) => {
  socket.emit(`budget-${eventName}`, {
    room: `budget-${projectId}`,
    info: eventInfo,
  });
  return true;
};

/**
 * @param budgetId
 * @param info
 * @param {boolean} optimistic true if state must be updated on request, without waiting for successful response. This is the preferred behaviour for auto-saving inputs.
 * @return {(function(*): void)|*}
 */
module.exports.updateInfo = (projectId, budgetId, info, optimistic = false) => (dispatch) => {
  dispatch(updateInfoRequest(info, optimistic));

  $.when(BudgetModel.update(budgetId, info))
    .done((updatedInfo) => {
      dispatch(updateInfoSuccess(updatedInfo, optimistic));
      emitSocketEvent(projectId, 'info-update', updatedInfo);
    })
    .fail((message, code) => {
      // Not the best way to check an error response but this is the best I can do without
      // further details from BE
      if (code === 401) {
        dispatch(updateInfoFailure(message));
        dispatch(getVersion(projectId)); // Refresh the budget to get the latest info
        dispatch(showModal(modals.API_ERROR_MODAL, {
          message,
          title: 'Cannot enable multicurrency',
        }));
      }
    });
};

module.exports.updateInfoTotals = (price, cost, days, externalCosts) => ({
  type: constants.UPDATE_INFO_TOTAL,
  price,
  cost,
  days,
  externalCosts,
});

const applyProjectStatusCorrectionRequest = () => ({
  type: constants.APPLY_PS_CORRECTION_REQUEST,
});

const applyProjectStatusCorrectionSuccess = () => ({
  type: constants.APPLY_PS_CORRECTION_SUCCESS,
});

module.exports.applyProjectStatusCorrection = (budgetId) => (dispatch) => {
  dispatch(applyProjectStatusCorrectionRequest());

  $.when(ProjectStatusCorrectionModel.apply(budgetId))
    .done(() => dispatch(applyProjectStatusCorrectionSuccess()));
};

const getConsumptionReportRequest = () => ({
  type: constants.GET_CONSUMPTION_REPORT_REQUEST,
});

const getConsumptionReportSuccess = (data) => ({
  type: constants.GET_CONSUMPTION_REPORT_SUCCESS,
  data,
});

module.exports.getConsumptionReport = (projectId) => (dispatch) => {
  dispatch(getConsumptionReportRequest());

  $.when(ProjectReportModel.get(projectId))
    .done((data) => dispatch(getConsumptionReportSuccess(data)));
};

const getAvailableApproversRequest = () => ({
  type: constants.GET_APPROVERS_REQUEST,
});

const getAvailableApproversSuccess = (approvers) => ({
  type: constants.GET_APPROVERS_SUCCESS,
  approvers,
});

const getAvailableApproversFailure = () => ({
  type: constants.GET_APPROVERS_FAILURE,
});

module.exports.getAvailableApprovers = (projectId) => (dispatch) => {
  dispatch(getAvailableApproversRequest());

  $.when(BudgetModel.getAvailableApprovers(projectId))
    .done((approvers) => {
      const formattedApprovers = approvers
        .map((approver) => ({
          id: approver.id,
          name: `${approver.name} ${approver.surname}`,
        }))
        .sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });

      dispatch(getAvailableApproversSuccess(formattedApprovers));
    })
    .fail(() => {
      dispatch(getAvailableApproversFailure());
    });
};

module.exports.getVersion = getVersion;

const getVersionListRequest = () => ({
  type: constants.GET_VERSION_LIST_REQUEST,
});

const getVersionListSuccess = (versions, baselines) => ({
  type: constants.GET_VERSION_LIST_SUCCESS,
  versions,
  baselines,
});

const getVersionListFailure = () => ({
  type: constants.GET_VERSION_LIST_FAILURE,
  versions: [],
});

const getBaselines = (versions) => versions.map((version) => ({
  version: version.version,
  baseline: version.baseline,
  budgetId: version.id,
}));
const getVersionList = (budgetId) => (dispatch) => {
  dispatch(getVersionListRequest());

  $.when(VersionModel.getAll(budgetId))
    .done((versions) => dispatch(
      getVersionListSuccess(versions, getBaselines(versions)),
    ))
    .fail(() => dispatch(getVersionListFailure()));
};

module.exports.getVersionList = getVersionList;

const getProjectStatusCorrectionRequest = () => ({
  type: constants.GET_PS_CORRECTION_REQUEST,
});

const getProjectStatusCorrectionSuccess = (correction) => ({
  type: constants.GET_PS_CORRECTION_SUCCESS,
  correction,
});

const getProjectStatusCorrection = (budgetId) => (dispatch) => {
  dispatch(getProjectStatusCorrectionRequest());

  $.when(ProjectStatusCorrectionModel.get(budgetId))
    .done((correction) => {
      dispatch(getProjectStatusCorrectionSuccess(correction));
    });
};

module.exports.getProjectStatusCorrection = getProjectStatusCorrection;

const changeStatusRequest = () => ({
  type: constants.CHANGE_STATUS_REQUEST,
});

const changeStatusSuccess = () => ({
  type: constants.CHANGE_STATUS_SUCCESS,
});

module.exports.changeStatus = (projectId, budgetId, status, message, approverId) => (dispatch) => {
  dispatch(changeStatusRequest());

  const attributes = {
    action: status,
    message,
    recipient: approverId,
  };

  $.when(VersionModel.updateStatus(budgetId, attributes))
    .done(() => {
      dispatch(getVersion(projectId));
      dispatch(getVersionList(projectId));
      dispatch(changeStatusSuccess());
      dispatch(getProjectStatusCorrection(budgetId));

      emitSocketEvent(projectId, 'change-status', { projectId });
    })
    .fail((errorMessage, code) => {
      // Not the best way to check an error response but this is the best I can do without
      // further details from BE
      if (code === 400) {
        dispatch(showModal(modals.API_ERROR_MODAL, {
          message: errorMessage,
        }));
      }
    });
};

const updateTaskInfoRequest = (taskInfo, taskId) => ({
  type: constants.UPDATE_TASK_INFO_REQUEST,
  taskInfo,
  taskId,
});

const updateTaskInfoSuccess = (task) => ({
  type: constants.UPDATE_TASK_INFO_SUCCESS,
  task,
});

module.exports.updateTaskInfo = (taskId, taskInfo, projectId) => (dispatch) => {
  dispatch(updateTaskInfoRequest(taskInfo, taskId));

  $.when(TaskModel.update(taskId, taskInfo))
    .done((task) => {
      dispatch(updateTaskInfoSuccess(task));
      emitSocketEvent(projectId, 'task-info-update', task);
    });
};

const updateTaskLevelAmountRequest = (levelId, quantity) => ({
  type: constants.UPDATE_TASK_LEVEL_AMOUNT_REQUEST,
  levelId,
  quantity,
});

const updateTaskLevelAmountSuccess = (day) => ({
  type: constants.UPDATE_TASK_LEVEL_AMOUNT_SUCCESS,
  day,
});

module.exports.updateTaskLevelAmount = (taskLevelAmountId, levelId, budgetTaskId,
  days, projectId) => (dispatch) => {
  dispatch(updateTaskLevelAmountRequest(taskLevelAmountId, days));

  const attributes = {
    days,
  };

  $.when(DayModel.update(taskLevelAmountId, attributes))
    .done((day) => {
      dispatch(updateTaskLevelAmountSuccess(day));
      emitSocketEvent(projectId, 'task-level-amount-update', day);
    });
};

const createTaskRequest = (areaId) => ({
  type: constants.CREATE_TASK_REQUEST,
  areaId,
});

const createTaskSuccess = (areaId, task, days, budgetPriceListLevels) => ({
  type: constants.CREATE_TASK_SUCCESS,
  areaId,
  task,
  days,
  budgetPriceListLevels,
});

module.exports.createTask = (projectId, areaId, taskInfo) => (dispatch) => {
  dispatch(createTaskRequest(areaId));
  $.when(TaskModel.create(areaId, taskInfo))
    .done((response) => {
      dispatch(createTaskSuccess(
        areaId,
        response.task,
        response.days,
      ));
      emitSocketEvent(projectId, 'create-tasks', {
        areaId,
        tasks: [response.task],
        days: response.days,
      });
    });
};

const createAreaRequest = () => ({
  type: constants.CREATE_AREA_REQUEST,
});

const createAreaSuccess = (area) => ({
  type: constants.CREATE_AREA_SUCCESS,
  area,
});

module.exports.createArea = (name, projectId) => (dispatch) => {
  dispatch(createAreaRequest());
  $.when(BudgetArea.create(name, projectId))
    .done((area) => {
      dispatch(createAreaSuccess(area));
      emitSocketEvent(projectId, 'create-area', { area });
    });
};

const deleteTaskRequest = () => ({
  type: constants.DELETE_TASK_REQUEST,
});

const deleteTaskSuccess = (id) => ({
  type: constants.DELETE_TASK_SUCCESS,
  id,
});

module.exports.deleteTask = (projectId, budgetId, id) => (dispatch) => {
  dispatch(deleteTaskRequest());

  $.when(TaskModel.delete(budgetId, id))
    .done(() => {
      dispatch(deleteTaskSuccess(id));
      emitSocketEvent(projectId, 'delete-task', {
        id,
      });
    });
};

const updateAreaRequest = (id, area) => ({
  type: constants.UPDATE_AREA_REQUEST,
  id,
  area,
});

const updateAreaSuccess = (id, area) => ({
  type: constants.UPDATE_AREA_SUCCESS,
  id,
  area,
});

module.exports.updateArea = (id, area, projectId) => (dispatch) => {
  dispatch(updateAreaRequest(id, area));

  $.when(AreaModel.update(id, area))
    .done((updatedArea) => {
      dispatch(updateAreaSuccess(id, updatedArea));
      emitSocketEvent(projectId, 'area-update', updatedArea);
    });
};

const makeTaskIntercompanySuccess = (task, supplierId) => ({
  type: constants.MAKE_TASK_INTERCOMPANY_SUCCESS,
  task,
  supplierId,
});

const makeTaskIntercompanyRequest = () => ({
  type: constants.MAKE_TASK_INTERCOMPANY_REQUEST,
});

module.exports.makeTaskIntercompany = (task, supplierId) => (dispatch) => {
  dispatch(makeTaskIntercompanyRequest());

  $.when(TaskModel.makeIntercompany(task.uid, supplierId))
    .done(() => dispatch(makeTaskIntercompanySuccess(task, supplierId)));
};

const getIntercompanyTaskInfoSuccess = (info, taskId) => ({
  type: constants.GET_INTERCOMPANY_TASK_INFO_SUCCESS,
  taskId,
  info,
});

const getIntercompanyTaskInfoRequest = () => ({
  type: constants.GET_INTERCOMPANY_TASK_INFO_REQUEST,
});

module.exports.getIntercompanyTaskInfo = (taskId) => (dispatch) => {
  dispatch(getIntercompanyTaskInfoRequest());

  $.when(TaskModel.getIntercompanyInfo(taskId))
    .done((info) => dispatch(getIntercompanyTaskInfoSuccess(info, taskId)));
};

const inviteIntercompanySupplierSuccess = (areaId, taskId) => ({
  type: constants.INVITE_INTERCOMPANY_SUPPLIER_SUCCESS,
  areaId,
  taskId,
});

const inviteIntercompanySupplierRequest = (taskId) => ({
  type: constants.INVITE_INTERCOMPANY_SUPPLIER_REQUEST,
  taskId,
});

module.exports.inviteIntercompanySupplier = (areaId, taskId, recipientId) => (dispatch) => {
  dispatch(inviteIntercompanySupplierRequest(taskId.id));
  $.when(TaskModel.inviteIntercompanySupplier(taskId.uid, recipientId))
    .done(() => dispatch(inviteIntercompanySupplierSuccess(areaId, taskId.id)));
};

module.exports.clearVersionComparator = () => ({
  type: constants.VERSION_COMPARATOR_CLEAR,
});

const getVersionComparisonRequest = () => ({
  type: constants.GET_VERSION_COMPARISON_REQUEST,
});

const getVersionComparisonSuccess = (info, areas) => ({
  type: constants.GET_VERSION_COMPARISON_SUCCESS,
  info,
  areas,
});

module.exports.getVersionComparison = (projectId, versions) => (dispatch) => {
  dispatch(getVersionComparisonRequest());

  $.when(VersionModel.getComparison(projectId, versions[0], versions[1]))
    .done((info) => {
      dispatch(
        getVersionComparisonSuccess(info),
      );
    });
};

const updateTaskOrderRequest = () => ({
  type: constants.UPDATE_TASK_ORDER_REQUEST,
});

const updateTaskOrderSuccess = (positions) => ({
  type: constants.UPDATE_TASK_ORDER_SUCCESS,
  positions,
});

module.exports.updateTaskOrder = (positions, projectId, budgetId) => (dispatch) => {
  dispatch(updateTaskOrderRequest());

  $.when(BudgetModel.sort(positions, budgetId))
    .done(() => {
      dispatch(updateTaskOrderSuccess(positions));
      emitSocketEvent(projectId, 'task-update-order', positions);
    });
};
module.exports.changeStatusFromCollaborator = (info) => (dispatch) => {
  dispatch(getVersion(info.projectId));
  dispatch(getVersionList(info.projectId));
};

const createBudgetRequest = () => ({
  type: constants.CREATE_BUDGET_REQUEST,
});

const createBudgetSuccess = (budgetInfo, currencies) => ({
  type: constants.CREATE_BUDGET_SUCCESS,
  budgetInfo,
  currencies,
});

module.exports.createBudget = (projectId, templateId) => (dispatch) => {
  dispatch(createBudgetRequest());

  $.when(BudgetModel.create(projectId, templateId))
    .done((response) => {
      $.when(
        CurrencyModel.getWithProjectRates(projectId),
      )
        .done((currencies) => {
          const infoBudget = BudgetInfoService.formatBudgetInfo(
            response,
          );
          dispatch(createBudgetSuccess(
            infoBudget,
            currencies,
          ));
        });
    });
};

module.exports.closeCreateBudgetModal = () => (dispatch) => {
  dispatch(closeModal());
  Wethod.navigate('pipeline/projects', { trigger: true });
};

const insertJobTitleRequest = () => ({
  type: constants.INSERT_JOB_TITLE_REQUEST,
});

const insertJobTitleSuccess = (day) => ({
  type: constants.INSERT_JOB_TITLE_SUCCESS,
  day,
});

module.exports.insertJobTitle = (taskId, jobTitleId, projectId) => (dispatch) => {
  dispatch(insertJobTitleRequest());
  BudgetJobTitleModel.create(taskId, jobTitleId).done((day) => {
    dispatch(insertJobTitleSuccess(day));
    emitSocketEvent(projectId, 'add-job-title', {
      day,
    });
  });
};

const deleteJobTitleRequest = () => ({
  type: constants.DELETE_JOB_TITLE_REQUEST,
});

const deleteJobTitleSuccess = (id) => ({
  type: constants.DELETE_JOB_TITLE_SUCCESS,
  id,
});

module.exports.deleteJobTitle = (id, taskId, jobTitleId, projectId) => (dispatch) => {
  dispatch(deleteJobTitleRequest());

  BudgetJobTitleModel.delete(jobTitleId, taskId)
    .done(() => {
      dispatch(deleteJobTitleSuccess(id));
      emitSocketEvent(projectId, 'remove-job-title', {
        id,
      });
    });
};

const insertProductsRequest = () => ({
  type: constants.INSERT_PRODUCTS_REQUEST,
});

const insertProductsSuccess = (tasks, days) => ({
  type: constants.INSERT_PRODUCTS_SUCCESS,
  days,
  tasks,
});

module.exports.insertProducts = (areaId, products, projectId) => (dispatch) => {
  dispatch(insertProductsRequest());
  BudgetProductModel.insert(areaId, products).done((resourceList) => {
    let tasks = [];
    let days = [];

    resourceList.forEach((resource) => {
      tasks = tasks.concat(resource.task);
      days = days.concat(resource.days);
    });

    dispatch(insertProductsSuccess(tasks, days));
    emitSocketEvent(projectId, 'create-tasks', {
      areaId,
      tasks,
      days,
    });
  });
};

const updatePricelistRequest = () => ({
  type: constants.UPDATE_PRICELIST_REQUEST,
});

const updatePricelistSuccess = (budgetInfo) => ({
  type: constants.UPDATE_PRICELIST_SUCCESS,
  budgetInfo,
});

module.exports.updatePricelist = (projectId, budgetId, pricelistId) => (dispatch) => {
  dispatch(updatePricelistRequest());
  BudgetPricelistModel.update(budgetId, pricelistId).done((budgetInfo) => {
    dispatch(updatePricelistSuccess(budgetInfo));
    dispatch(closeModal());
    // TODO: fix, levels is not anymore in the budgetInfo (use formatter first or use budget_price_list_levels)
    emitSocketEvent(projectId, 'change-pricelist', {
      price_list: budgetInfo.price_list,
      levels: budgetInfo.levels,
    });
  });
};

const updatePriceListAreaRequest = () => ({
  type: constants.UPDATE_PRICE_LIST_AREA_REQUEST,
});

const updatePriceListAreaSuccess = (budgetInfo) => ({
  type: constants.UPDATE_PRICE_LIST_AREA_SUCCESS,
  budgetInfo,
});

module.exports.updateAreaPriceList = (priceListId, areaId) => (dispatch) => {
  dispatch(updatePriceListAreaRequest());
  Pricelist.updateBudgetAreaPriceList(priceListId, areaId).done((budgetInfo) => {
    dispatch(updatePriceListAreaSuccess(budgetInfo));
    dispatch(closeModal());
    // TODO: fix, levels is not anymore in the budgetInfo (use formatter first or use budget_price_list_levels)
    emitSocketEvent(budgetInfo.project.id, 'change-pricelist', {
      price_list: budgetInfo.price_list,
      levels: budgetInfo.levels,
    });
  });
};

const collaboratorChangePricelist = (info) => ({
  type: constants.COLLABORATOR_CHANGE_PRICELIST,
  info,
});

module.exports.changePricelistFromCollaborator = (info) => (dispatch) => {
  dispatch(collaboratorChangePricelist(info));
};

const updateBudgetLevelVisibleSuccess = (budgetPriceListLevels) => ({
  type: constants.UPDATE_BUDGET_LEVEL_VISIBLE_SUCCESS,
  budgetPriceListLevels,
});
module.exports.setBudgetLevelVisible = (idLevel, isVisible, budgetId) => (dispatch) => {
  BudgetPriceListLevel.update(idLevel, isVisible, budgetId)
    .done((budgetPriceListLevels) => {
      dispatch(updateBudgetLevelVisibleSuccess(budgetPriceListLevels));
    });
};
const setScrollingComponentScrollAmount = (amount) => ({
  type: constants.SET_SCROLLING_COMPONENT_AMOUNT,
  amount,
});

module.exports.setScrollingComponentScrollAmount = (amount) => (dispatch) => {
  dispatch(setScrollingComponentScrollAmount(amount));
};

module.exports.collapseArea = (areaId) => ({
  type: constants.AREA_COLLAPSE,
  areaId,
});

module.exports.expandArea = (areaId) => ({
  type: constants.AREA_EXPAND,
  areaId,
});

const updateBaselineSuccess = (baseline) => ({
  type: constants.BASELINE_UPDATE_SUCCESS,
  baseline,
});
const updateBaselineRequest = () => ({
  type: constants.BASELINE_UPDATE_REQUEST,
});
const setNewBaseline = (baseline) => (dispatch) => {
  dispatch(updateBaselineRequest());
  $.when(BudgetBaseline.updateBaseline(baseline))
    .done((newBaseline) => {
      dispatch(closeModal());
      dispatch(
        updateBaselineSuccess(newBaseline.version),
      );
    })
    .fail();
};

module.exports.setNewBaseline = setNewBaseline;

module.exports.showDailyCostPriceLevelModal = (
  levelId, levelName, projectId, budgetVersion,
) => (dispatch) => {
  dispatch(showModal(
    modals.DAILY_COST_PRICE_LEVEL_MODAL, {
      levelId,
      levelName,
      projectId,
      budgetVersion,
    },
  ));
};
