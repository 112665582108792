const BudgetModel = require('../../../../../models/Budget');

/**
 * Attributes representing an empty day.
 * @type {{days: number}}
 */
const emptyDaySkeleton = {
  days: 0,
};

/**
 * Attributes representing an empty task.
 * @type {{markup: number, total_cost: number, total_price: number, currency: null, external_cost: number, product_quantity: null, total_days: number}}
 */
const emptyTaskSkeleton = {
  currency: null,
  external_cost: 0,
  markup: 0,
  product_quantity: 0,
  total_cost: 0,
  total_days: 0,
  total_price: 0,
};

/**
 * Merge given object by putting "to" inside "from.was".
 * A missing "from" means object has been deleted.
 *
 * Future API update will return deleted:true instead.
 *
 * @param {{}} from
 * @param {{}} to
 * @return {*&{was}}
 */
const merge = (from, to) => ({
  ...from,
  deleted: from === null,
  is_new: to === null,
  was: to,
});

/**
 * Merge given list by putting "to" inside "from.was" for each item.
 * Item without "from" (which represents deleted items) are removed because we don't know how to threat them yet.
 * @param {[{from,to}]} list
 * @return {[{was}]}
 */
const mergeList = (list) => list
  .map((item) => merge(item.from, item.to));

/**
 * Format a deleted task to make it possible to render it.
 * A "deleted task" is a task returned from compare API where "from" is missing.
 *
 * This can be deleted when tasks will be soft deletable.
 *
 * @param task
 * @return {(*&{markup: number, total_cost: number, total_price: number, currency: null, id: string, external_cost: number, product_quantity: null, total_days: number})|*}
 */
const formatDeletedTask = (task) => {
  if (task.deleted) {
    return {
      ...task.was,
      ...task,
      id: `${task.was.id}-deleted`,
      ...emptyTaskSkeleton,
    };
  }
  return task;
};

/**
 * Adds empty was for newly created task.
 * @param task
 * @return {(*&{was: (*&{markup: number, total_cost: number, total_price: number, currency: null, id: string, external_cost: number, product_quantity: null, total_days: number})})|*}
 */
const formatCreatedTask = (task) => {
  if (task.is_new) {
    return {
      ...task,
      was: {
        ...task,
        id: `${task.id}-new`,
        ...emptyTaskSkeleton,
      },
    };
  }
  return task;
};

/**
 * Format a deleted day to make it possible to render it.
 * A "deleted day" is a day returned from compare API where "from" is missing.
 *
 * This can be deleted when tasks and days will be soft deletable.
 *
 * @param day
 * @return {(*&{days: number, id: string})|*}
 */
const formatDeletedDay = (day) => {
  if (day.deleted) {
    return {
      ...day,
      ...day.was,
      id: `${day.was.id}-deleted`,
      ...emptyDaySkeleton,
    };
  }
  return day;
};

/**
 * Adds empty was for newly created day.
 * @param day
 * @return {(*&{was: (*&{days: number, id: string})})|*}
 */
const formatCreatedDay = (day) => {
  if (day.is_new) {
    return {
      ...day,
      was: {
        ...day,
        id: `${day.id}-new`,
        ...emptyDaySkeleton,
      },
    };
  }
  return day;
};

/**
 * Format a deleted jobTitle to make it possible to render it.
 * A "deleted job title" is a jobTitle returned from compare API where "from" is missing.
 *
 * This can be deleted when tasks and days will be soft deletable.
 *
 * @param jobTitle
 * @return {(*&{days: number, id: string})|*}
 */
const formatDeletedJobTitle = (jobTitle) => {
  if (jobTitle.deleted) {
    return {
      ...jobTitle.was,
      ...jobTitle,
      id: jobTitle.was.id,
    };
  }
  return jobTitle;
};

/**
 * Adds empty was for newly created job title.
 * @param jobTitle
 * @return {(*&{was: (*&{days: number, id: string})})|*}
 */
const formatCreatedJobTitle = (jobTitle) => {
  if (jobTitle.is_new) {
    return {
      ...jobTitle,
      was: {
        ...jobTitle,
        id: `${jobTitle.id}-new`,
      },
    };
  }
  return jobTitle;
};

module.exports = {
  isDraft(status) {
    return status === BudgetModel.STATUS_DRAFT;
  },
  isSubmitted(status) {
    return status === BudgetModel.STATUS_SUBMITTED;
  },
  isApproved(status) {
    return status === BudgetModel.STATUS_APPROVED;
  },
  /**
   * Format given response {from,to,areas:[{from,to},..]} -> {was,areas:[{was},..]}.
   * This makes it easier to work with deltas.
   *
   * @param compareResponse data returned from BudgetVersion.getComparison()
   * @return {*&{was}}
   */
  formatWithWas(compareResponse) {
    const version = merge(compareResponse.from, compareResponse.to);
    version.areas = mergeList(compareResponse.areas);
    version.tasks = mergeList(compareResponse.tasks).map(formatDeletedTask).map(formatCreatedTask);
    version.days = mergeList(compareResponse.days).map(formatDeletedDay).map(formatCreatedDay);
    version.mainBudgetPriceListLevels = mergeList(compareResponse.mainBudgetPriceListLevels);
    version.job_titles = mergeList(compareResponse.job_titles).map(formatDeletedJobTitle)
      .map(formatCreatedJobTitle);
    version.budget_price_list_levels = mergeList(compareResponse.budget_price_list_levels);
    return version;
  },
};
