const React = require('react');

const HeaderLevelTooltipMessage = ({
  levelId, name,
  projectId, budgetVersion, showDailyCostPriceLevelModal,
}) => {
  function showDailyCostPriceLevelModalLevel() {
    showDailyCostPriceLevelModal(levelId, name, projectId, budgetVersion);
  }

  return (
    <div className="wethod-budget-calculator__level-info-tooltip-message-click">
      <div className="wethod-budget-calculator__level-info-tooltip-row"
        onClick={showDailyCostPriceLevelModalLevel}>
        <b>See Daily Cost and Price &#8594;</b>
      </div>
    </div>
  );
};

module.exports = HeaderLevelTooltipMessage;
