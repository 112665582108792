const Grouping = require('./ChartConfigAccountDimensionGrouping');

class ChartConfigAccountDimensionTopNGrouping extends Grouping {
  /**
   * @param {number} value
   */
  constructor(value) {
    super(ChartConfigAccountDimensionTopNGrouping.TYPE);
    this.value = value;
  }

  /**
   * Return index of the group containing "all the rest" accounts.
   * @return {?number}
   */
  getOthersGroupIndex() {
    return this.isGrouped() ? this.value : null;
  }

  /**
   * @param data
   * @return {ChartConfigAccountDimensionTopNGrouping}
   */
  static fromJSON(data) {
    const value = parseInt(data);
    return new ChartConfigAccountDimensionTopNGrouping(value);
  }

  // eslint-disable-next-line class-methods-use-this
  isGrouped() {
    return true;
  }

  clone() {
    return new ChartConfigAccountDimensionTopNGrouping(this.value);
  }
}

ChartConfigAccountDimensionTopNGrouping.TYPE = 'top-n';

module.exports = ChartConfigAccountDimensionTopNGrouping;
