class ChartConfigTimeDimensionGrouping {
  /**
   * @param {('month'|'quarter'|'year')} value
   */
  constructor(value = ChartConfigTimeDimensionGrouping.VALUE_YEAR) {
    this.type = 'period';
    this.value = value;
  }

  /**
   * @param data
   * @return {ChartConfigTimeDimensionGrouping}
   */
  static fromJSON(data) {
    return new ChartConfigTimeDimensionGrouping(data.value);
  }

  /**
   * Return a copy of this instance.
   * @return {ChartConfigTimeDimensionGrouping}
   */
  clone() {
    return new ChartConfigTimeDimensionGrouping(this.value);
  }
}

ChartConfigTimeDimensionGrouping.VALUE_MONTH = 'month';
ChartConfigTimeDimensionGrouping.VALUE_QUARTER = 'quarter';
ChartConfigTimeDimensionGrouping.VALUE_YEAR = 'year';

module.exports = ChartConfigTimeDimensionGrouping;
