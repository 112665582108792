const { connect } = require('react-redux');
const Component = require('../../../components/Calculator/JobTitle/JobTitle.react');
const actions = require('../../../actions');
const LevelService = require('../../../services/LevelService');

const mapStateToProps = (state) => ({
  availableLevels: LevelService.sort(state.info.mainBudgetPriceListLevels),
});

const mapDispatchToProps = (dispatch) => ({
  showDeleteModal: (id, taskId, jobTitleId) => dispatch(actions
    .showDeleteJobTitleModal(id, taskId, jobTitleId)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
