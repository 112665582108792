const React = require('react');
const PropTypes = require('prop-types');
const AccountDimensionTopNGrouping = require('../../../../../apps/core/modules/insights/revenues/models/ChartConfigDimension/ChartConfigAccountDimension/ChartConfigAccountDimensionTopNGrouping');
const AccountDimensionDistinctGrouping = require('../../../../../apps/core/modules/insights/revenues/models/ChartConfigDimension/ChartConfigAccountDimension/ChartConfigAccountDimensionDistinctGrouping');
const Options = require('./ChartSidebarDimensionOptions.react');
const RadioGroup = require('../../../inputs/RadioGroup/RadioGroup.react');
const Radio = require('../../../inputs/RadioGroup/Radio.react');
const TopNOption = require('./ChartSidebarDimensionTopNGroupingOption.react');

const ChartSidebarDimensionAccountOptions = ({
  type, value, onChangeType, onChangeValue,
  updateErrors, getError,
}) => {
  function handleTypeChange(e) {
    const selectedType = e.target.value;
    switch (selectedType) {
      case AccountDimensionTopNGrouping.TYPE:
        onChangeType(new AccountDimensionTopNGrouping(3));
        break;
      case AccountDimensionDistinctGrouping.TYPE:
        onChangeType(new AccountDimensionDistinctGrouping());
        break;
      default:
        throw new Error(`${value} is not a valid account dimension type`);
    }
  }

  return (
    <Options>
      <RadioGroup name="account-dimension-grouping" onChange={handleTypeChange} value={type}>
        <Radio value={AccountDimensionDistinctGrouping.TYPE} label="Show distinct values" />
        <TopNOption getError={getError}
          updateErrors={updateErrors}
          topNValue={value}
          value={AccountDimensionTopNGrouping.TYPE}
          onChangeTopNValue={onChangeValue} />
      </RadioGroup>
    </Options>
  );
};

ChartSidebarDimensionAccountOptions.defaultProps = {
  value: 3,
};

ChartSidebarDimensionAccountOptions.propTypes = {
  type: PropTypes.string.isRequired,
  value: PropTypes.number,
  onChangeType: PropTypes.func.isRequired,
  onChangeValue: PropTypes.func.isRequired,
  updateErrors: PropTypes.func.isRequired,
  getError: PropTypes.func.isRequired,
};

module.exports = ChartSidebarDimensionAccountOptions;
