const React = require('react');
const PropTypes = require('prop-types');
const Menu = require('../../Menu/ListMenu/ListMenu.react');
const EventService = require('../../../../services/EventService');
const Empty = require('../../Menu/ListMenu/EmptyItem.react');

require('./style.scss');

class Select extends React.Component {
  /**
   * Transform the given value into an array and return it.
   * Null value are removed.
   * @param value
   * @return {*[]}
   */
  static toArray(value) {
    const arrayValue = Array.isArray(value) ? value : [value];

    return arrayValue.filter((item) => item !== null && item !== undefined && item !== '');
  }

  constructor(props) {
    super(props);

    this.state = {
      value: Select.toArray(props.value),
      open: false,
    };

    this.onInputClick = this.onInputClick.bind(this);
    this.onOptionClick = this.onOptionClick.bind(this);
    this.onClose = this.onClose.bind(this);
    this.setMenuAnchorRef = this.setMenuAnchorRef.bind(this);
  }

  componentDidMount() {
    this.validate(this.state.value);
  }

  componentDidUpdate(prevProps) {
    const changedFromParent = this.props.value !== prevProps.value;
    if (changedFromParent) {
      this.syncWithParent();
    }
  }

  componentWillUnmount() {
    if (this.props.resetErrors) {
      this.props.resetErrors(this.props.name);
    }
  }

  onInputClick() {
    this.setState((prevState) => ({ open: !prevState.open }));
  }

  onClose() {
    this.setState({ open: false });
  }

  onParentChange(e, option) {
    if (this.props.onChange) {
      this.props.onChange(e, option);
    }
  }

  onOptionClick(e) {
    const option = this.getOption(e.target.value);
    const isOptionSelected = this.state.value.includes(e.target.value);
    const nextState = {};
    if (this.props.multiple) {
      if (isOptionSelected) {
        nextState.value = this.state.value.filter((item) => item !== e.target.value);
      } else {
        nextState.value = this.state.value.concat(e.target.value);
      }
    } else {
      nextState.value = [e.target.value];
    }

    this.setState(nextState, () => {
      if (this.props.multiple) {
        e.target.value = this.state.value;
      }
      this.validate(e.target.value);
      this.onParentChange(e, option.props);
    });
  }

  getOptions() {
    return React.Children.map(this.props.children, (child) => React
      .cloneElement(child, {
        onClick: (e) => {
          if (this.props.multiple) {
            // Allow multiple select without closing menu
            e.stopPropagation();
          }
          // Cannot use original event because empty <li> value is converted to 0
          const event = EventService.cloneEvent(e, {
            name: this.props.name,
            value: child.props.value,
          });
          this.onOptionClick(event);
        },
        key: child.props.value,
        selected: this.state.value.includes(child.props.value),
      }));
  }

  getMenuContent() {
    if (this.isEmpty()) {
      return <Empty>{this.props.empty}</Empty>;
    }
    return this.getOptions();
  }

  getOption(value) {
    const found = React.Children.toArray(this.props.children)
      .filter((child) => String(child.props.value) === String(value));
    return found.length ? found[0] : null;
  }

  /**
   * Finds the option with the given value and returns the label to show inside Select button.
   * Default label for an option is its children. You can customize this by explicitly passing a
   * "label" prop to the option.
   * @param {string[]} value
   * @returns {null|string|HTMLElement}
   */
  getOptionLabel(value) {
    const options = value.map(this.getOption.bind(this)).filter((item) => item !== null);
    const labels = options.map((option) => {
      const optionProps = option.props;
      if (optionProps.label !== undefined) {
        return optionProps.label;
      }
      // Children is an array if it's made of multiple words
      if (Array.isArray(optionProps.children)) {
        return optionProps.children.join('');
      }
      return optionProps.children;
    });

    if (labels.length === 0) {
      return '';
    }
    if (this.props.multiple) {
      return `${labels.length} selected`;
    }
    return labels[0];
  }

  getButton() {
    const Button = this.props.button;

    return (
      <Button name={this.props.name}
        label={this.props.label}
        prefix={this.props.prefix}
        suffix={this.props.suffix}
        helperText={this.props.helperText}
        errorText={this.props.errorText}
        value={this.getOptionLabel(this.state.value)}
        id={this.props.id}
        required={this.props.required}
        placeholder={this.props.placeholder}
        disabled={this.props.disabled}
        readOnly={this.props.readOnly}
        onClick={this.onInputClick}
        inputRef={this.setMenuAnchorRef}
        {...this.props.buttonProps} />
    );
  }

  setMenuAnchorRef(ref) {
    this.menuAnchorRef = ref;
  }

  validate(value) {
    if (this.props.validate) {
      this.props.validate(this.props.name, value);
    }
  }

  syncWithParent() {
    this.setState({ value: Select.toArray(this.props.value) }, () => {
      this.validate(this.state.value);
    });
  }

  isEmpty() {
    const visibleChildren = React.Children.toArray(this.props.children)
      .filter((child) => child.props.hidden !== true);

    return visibleChildren.length === 0;
  }

  render() {
    return (
      <div className={`wethod-select ${this.props.className}`}>
        {this.getButton()}
        <Menu open={this.state.open} onClose={this.onClose} anchorEl={this.menuAnchorRef} bottom className="wethod-select-menu">
          {this.getMenuContent()}
        </Menu>
      </div>
    );
  }
}

Select.defaultProps = {
  label: null,
  required: undefined,
  id: undefined,
  prefix: undefined,
  suffix: undefined,
  helperText: undefined,
  errorText: undefined,
  validate: undefined,
  buttonProps: undefined,
  disabled: undefined,
  readOnly: undefined,
  onChange: null,
  value: [],
  placeholder: undefined,
  empty: 'There\'s nothing here',
  resetErrors: null,
  className: '',
  multiple: false,
};

Select.propTypes = {
  /** Current value. * */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    ),
  ]),
  /** Class to instantiate as button * */
  button: PropTypes.func.isRequired,
  /**
   * List containing options that can be selected.
   */
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  /**
   * Function to call when a new value is selected.
   * @param e {SyntheticEvent}
   * @param option {{}} selected option props
   */
  onChange: PropTypes.func,
  /** Element to use as label * */
  label: PropTypes.node,
  required: PropTypes.bool,
  id: PropTypes.string,
  /** Element to put before children * */
  prefix: PropTypes.node,
  /** Element to put after children * */
  suffix: PropTypes.node,
  /** Text to display below children * */
  helperText: PropTypes.string,
  /** Error message to display below children * */
  errorText: PropTypes.string,
  /**
   * Function to call to validate the given value.
   * @param name {string}
   * @errors errors {[]}
   */
  validate: PropTypes.func,
  /** Properties that need to be passed to button element * */
  buttonProps: PropTypes.shape({
    color: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  placeholder: PropTypes.string,
  /** Content to show when children is empty * */
  empty: PropTypes.node,
  /**
   * Function to call to reset errors for current input.
   * @param name {string}
   */
  resetErrors: PropTypes.func,
  className: PropTypes.string,
  /** If true, value must be an array and the menu will support multiple selections. * */
  multiple: PropTypes.bool,
};

module.exports = Select;
