const React = require('react');
const moment = require('moment');
const InputValidator = require('../../../../../../common/react/InputValidator/InputValidator.react');
const InputWrapper = require('../../../../../../common/react/sidebar/SidebarInputWrapper.react');
const Row = require('../../../../../../common/react/sidebar/SidebarInputRow.react');
const NumericField = require('../../../../../../common/react/inputs/NumericField/OutlinedNumericField/OutlinedNumericField.react');
const TextArea = require('../../../../../../common/react/inputs/TextArea/OutlinedTextArea/OutlinedTextArea.react');
const TextField = require('../../../../../../common/react/inputs/TextField/OutlinedTextField/OutlinedTextField.react');
const DateInput = require('../../../../../../common/react/inputs/DayPicker/OutlinedDayPicker/OutlinedDayPicker.react');
const Switch = require('../../../../../../common/react/inputs/Switch/Switch.react');
const ClientSelect = require('./ClientSelect.react');
const ShowIf = require('../../../../../../common/react/ShowIf/ShowIf.react');

const PricelistSidebarBody = ({
  readOnly,
  item,
  levels,
  errors,
  updateErrors,
  onChange,
}) => {
  const isCompanyDefault = item.default_for_company;
  const isClientDefaultSwitchDisabled = readOnly || !item.client;
  const isClientDefaultSwitchChecked = item.default_for_client && Boolean(item.client);
  const isDateReadOnly = isClientDefaultSwitchChecked || isCompanyDefault || readOnly;
  const syncCostWithDefaultPricelist = item.sync_with_company_default;

  function getLevelRate(levelId) {
    const level = item.price_list_levels.find((l) => l.level.id === levelId);
    return level ? level.price : 0.00;
  }

  function getLevelCost(levelId) {
    const level = item.price_list_levels.find((l) => l.level.id === levelId);
    return level ? level.cost : 0;
  }

  function handleChange(e) {
    const { name, value } = e.target;
    if (onChange) {
      onChange(name, value);
    }
  }

  function handleLevelChange(e) {
    const { name, value } = e.target;
    const nameParts = name.split('-');
    const key = nameParts[2];
    let parsedValue = parseFloat(value);
    parsedValue = !Number.isNaN(parsedValue) ? parsedValue : 0.00;
    const levelId = parseInt(nameParts[1], 10);
    const updatedLevels = item.price_list_levels.map((level) => {
      if (level.level.id === levelId) {
        return {
          ...level,
          [key]: parsedValue,
        };
      }
      return level;
    });

    onChange('price_list_levels', updatedLevels);
  }

  function handleClientSelectChange(name, option) {
    onChange(name, option);
  }

  function handleSwitchChange(e) {
    const { name, checked } = e.target;
    onChange(name, checked);
  }

  function getNameInput() {
    return (
      <Row>
        <InputWrapper>
          <InputValidator updateErrors={updateErrors} constraints={['required']}>
            <TextField readOnly={readOnly}
              name="name"
              id="sidebar-name"
              label="Pricelist name"
              value={item.name}
              onChange={handleChange}
              errorText={errors.name} />
          </InputValidator>
        </InputWrapper>
      </Row>
    );
  }
  function getDescriptionInput() {
    return (
      <Row>
        <InputWrapper>
          <InputValidator updateErrors={updateErrors} constraints={['maxLength:50']}>
            <TextArea readOnly={readOnly}
              name="description"
              id="sidebar-description"
              label="Description"
              value={item.description}
              rows={3}
              onChange={handleChange}
              errorText={errors.description} />
          </InputValidator>
        </InputWrapper>
      </Row>
    );
  }
  function getClientSelect() {
    if (isCompanyDefault) {
      return null;
    }
    return (
      <Row>
        <InputWrapper>
          <ClientSelect readOnly={readOnly}
            name="client"
            id="sidebar-client"
            label="Client"
            value={item.client}
            onChange={handleClientSelectChange}
            errorText={errors.client}
            updateErrors={updateErrors} />
        </InputWrapper>
      </Row>
    );
  }
  function getDatesInput() {
    if (isCompanyDefault) {
      return null;
    }
    return (
      <div>
        <Row>
          <InputWrapper>
            <DateInput readOnly={isDateReadOnly}
              resettable
              name="available_from"
              id="sidebar-from"
              label="From"
              value={item.available_from}
              onChange={handleChange}
              errorText={errors.available_from} />
          </InputWrapper>
          <InputWrapper>
            <DateInput readOnly={isDateReadOnly}
              resettable
              name="available_to"
              id="sidebar-to"
              label="To"
              value={item.available_to}
              onChange={handleChange}
              errorText={errors.available_to} />
          </InputWrapper>
        </Row>
      </div>
    );
  }
  function getLevelsList() {
    return levels?.map((level) => (
      <InputWrapper key={`input-wrapper-${level.id}`}>
        <Row className="pricelists__sidebar-levels-row" key={level.id}>
          <TextField readOnly
            name={`level-label-${level.id}`}
            value={level.short_text} />
          <div className="pricelists__sidebar-levels-input">
            <InputValidator updateErrors={updateErrors} constraints={[]}>
              <NumericField
                readOnly={readOnly || syncCostWithDefaultPricelist}
                precision={2}
                name={`level-${level.id}-cost`}
                id={`sidebar-level-${level.id}-cost`}
                label="Cost"
                value={getLevelCost(level.id)}
                onChange={handleLevelChange} />
            </InputValidator>
          </div>
          <div className="pricelists__sidebar-levels-input">
            <InputValidator updateErrors={updateErrors} constraints={[]}>
              <NumericField
                readOnly={readOnly}
                precision={2}
                name={`level-${level.id}-price`}
                id={`sidebar-level-${level.id}-price`}
                label="Rate"
                value={getLevelRate(level.id)}
                onChange={handleLevelChange} />
            </InputValidator>
          </div>
        </Row>
      </InputWrapper>
    ));
  }
  function getLevelsSection() {
    return (
      <div className="pricelists__sidebar-levels">
        {getLevelsList()}
      </div>
    );
  }
  function getClientDefaultSwitch() {
    if (isCompanyDefault) {
      return null;
    }
    return (
      <div>
        <Row>
          <InputWrapper>
            <InputValidator updateErrors={updateErrors} constraints={[]}>
              <Switch
                disabled={isClientDefaultSwitchDisabled}
                label="Set as default for this client"
                name="default_for_client"
                id="client-default-switch"
                checked={isClientDefaultSwitchChecked}
                onChange={handleSwitchChange} />
            </InputValidator>
          </InputWrapper>
        </Row>
        <Row>
          <InputWrapper>
            <span className="pricelists__sidebar-switch-description">
              Setting a price list as default for a client means that new budgets will automatically
              depend on these rates. A default price list is always available,
              so once set as default availability dates will be deleted.
            </span>
          </InputWrapper>
        </Row>
      </div>
    );
  }
  function getSyncCostsSwitch() {
    const canBeShown = !isCompanyDefault;

    return (
      <ShowIf condition={canBeShown}>
        <Row className="pricelists__sidebar-sync-cost-switch-row">
          <InputWrapper>
            <Switch disabled={readOnly}
              label="Sync costs with default pricelist"
              name="sync_with_company_default"
              id="sync-with-company-default-switch"
              checked={syncCostWithDefaultPricelist}
              onChange={handleSwitchChange} />
          </InputWrapper>
        </Row>
      </ShowIf>
    );
  }

  function formatLogDate(logDate) {
    const date = moment(logDate);
    return date.format('DD/MM/YY - HH:mm');
  }

  function getPriceListLevelLogRows() {
    return item.price_list_logs?.map((log) => (
      <div key={log.id} className="pricelists__sidebar-log-row">
        <Row>
          <InputWrapper>
            <span>
              {/* eslint-disable-next-line max-len */}
              "{log.level_short_text}" {log.type.toLowerCase()} changed from {log.from_value} to {log.to_value}
            </span>
          </InputWrapper>
        </Row>
        <Row>
          <InputWrapper>
            <span className="pricelists__sidebar-log-info">
              {formatLogDate(log.created)} - {log.updated_by}
            </span>
          </InputWrapper>
        </Row>
      </div>
    ));
  }
  function getLogTitle() {
    return (
      <InputWrapper>
        <h3 className="wethod-sidebar__subtitle">Changelog</h3>
      </InputWrapper>
    );
  }
  function getPriceListLevelLogsSection() {
    return (
      <ShowIf condition={item.price_list_logs?.length > 0}>
        <div>
          {getLogTitle()}
          {getPriceListLevelLogRows()}
        </div>
      </ShowIf>
    );
  }

  return (
    <div className="pricelists__sidebar-body">
      {getNameInput()}
      {getDescriptionInput()}
      {getClientSelect()}
      {getDatesInput()}
      {getSyncCostsSwitch()}
      {getLevelsSection()}
      {getClientDefaultSwitch()}
      {getPriceListLevelLogsSection()}
    </div>
  );
};

module.exports = PricelistSidebarBody;
