const { connect } = require('react-redux');
const Component = require('../../../components/Calculator/Area/UserDefinedArea.react');
const actions = require('../../../actions');

const mapStateToProps = (state) => ({
  mainBudgetPriceListLevels: state.info.mainBudgetPriceListLevels,
});
const mapDispatchToProps = (dispatch) => ({
  showProductInsertModal: (areaId, nextTaskOrder) => dispatch(actions
    .showProductInsertModal(areaId, nextTaskOrder)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
