const React = require('react');
const BaseArea = require('./BaseArea.react');
const UserDefinedArea = require('../../../containers/Calculator/Area/UserDefinedArea');
const Task = require('../Task/TaskFactory.react');
const constants = require('../../../constants');

const AreaFactory = ({
  horizontalScrollClass = '',
  fitContentWidth,
  column,
  horizontalScroll,
  id,
  name,
  levels,
  externalCosts,
  markup,
  cost,
  price,
  tasks,
  days,
  editableBudget,
  updateTaskInfo,
  updateTaskLevel,
  createTask,
  isPending,
  showDeleteTaskModal,
  updateArea,
  enabled,
  showMakeTaskIntercompanyModal,
  previous,
  showInviteIntercompanySupplierModal,
  saveInfoOptimistic,
  order,
  showSortTaskModal,
  showIntercompanyDetailsModal,
  type,
  collapsedAreas,
  expandArea,
  collapseArea,
  priceList,
  priceLists,
  budgetPriceListLevels,
  mainBudgetPriceListLevels,
  ...props
}) => {
  function onSaveInfoOptimistic(info) {
    if (editableBudget) {
      saveInfoOptimistic(info);
    }
  }

  function onTaskLevelChange(taskLevelAmountId, levelId, budgetTaskId, quantity) {
    updateTaskLevel(taskLevelAmountId, levelId, budgetTaskId, quantity);
  }

  /**
   * Return order to use for next task related to area.
   * @return {number}
   */
  const getNextTaskOrder = () => {
    // Expect tasks ordered from smallest to biggest task.sort
    const lastTask = tasks[tasks.length - 1];
    return lastTask ? lastTask.sort + 1 : 1;
  };

  function handleTaskCreate(label) {
    createTask(id, { name: label, sort: getNextTaskOrder() });
  }

  function onDeleteTaskClick(taskId) {
    showDeleteTaskModal(taskId);
  }

  function onMakeTaskIntercompanyClick(task, taskDays, taskMarkup, taskJobTitles) {
    showMakeTaskIntercompanyModal(task, taskDays, taskMarkup, taskJobTitles);
  }

  function onInviteIntercompanySupplierClick(taskId, supplier) {
    showInviteIntercompanySupplierModal(id, taskId, supplier);
  }

  function onReorderTaskClick() {
    showSortTaskModal(id, order, tasks);
  }

  function onUpdateArea(changes) {
    updateArea(id, changes);
  }

  function onAreaAccordionChange(expanded) {
    if (expanded) {
      expandArea(id);
    } else {
      collapseArea(id);
    }
  }

  const isCreatingTask = () => isPending(`add-task-to-area-${id}`);

  /**
   * Return if this area has been created by a user.
   * @return {boolean}
   */
  const isUserDefined = () => type === constants.AREA_TYPE_DEFAULT;

  const isDefault = (task) => task.type !== constants.TASK_TYPE_DEFAULT;

  const isCollapsed = collapsedAreas.includes(id);

  const taskList = tasks.map((task) => (
    <Task
      column={column}
      key={task.id}
      product={task.product}
      created={task.is_new}
      uid={task.uid}
      deleted={task.deleted}
      previous={task.was}
      budgetTaskId={task.id}
      quantity={task.product_quantity}
      updateTaskInfo={updateTaskInfo}
      updateQuantity={onSaveInfoOptimistic}
      updateTaskLevel={onTaskLevelChange}
      onDeleteTaskClick={onDeleteTaskClick}
      onMakeTaskIntercompanyClick={onMakeTaskIntercompanyClick}
      onInviteIntercompanySupplierClick={onInviteIntercompanySupplierClick}
      onIntercompanyMessageClick={showIntercompanyDetailsModal}
      currency={task.currency}
      intercompany={task.intercompany}
      order={task.sort}
      id={task.id}
      editableBudget={editableBudget}
      isDefault={isDefault(task)}
      name={task.name}
      jobTitles={task.jobTitles}
      cost={task.total.cost}
      price={task.total.price}
      days={task.total.days}
      levels={task.levels}
      levelsTotals={task.total.levels}
      externalCosts={task.external_cost}
      markup={task.markup}
      type={task.type} />
  ));

  if (isUserDefined()) {
    return (
      <UserDefinedArea
        fitContentWidth={fitContentWidth}
        isCollapsed={isCollapsed}
        onAccordionChange={onAreaAccordionChange}
        showShadows={props.showShadows}
        column={column}
        nextTaskOrder={getNextTaskOrder()}
        key={id}
        previous={previous}
        id={id}
        order={order}
        days={days}
        editableBudget={editableBudget}
        name={name}
        enabled={enabled}
        tasks={taskList}
        showSortTaskModal={onReorderTaskClick}
        markup={markup}
        isCreatingTask={isCreatingTask()}
        externalCosts={externalCosts}
        createTask={handleTaskCreate}
        updateArea={onUpdateArea}
        cost={cost}
        price={price}
        levels={levels}
        priceList={priceList}
        priceLists={priceLists} />
    );
  }
  return (
    <BaseArea
      fitContentWidth={fitContentWidth}
      isCollapsed={isCollapsed}
      onAccordionChange={onAreaAccordionChange}
      showShadows={props.showShadows}
      column={column}
      horizontalScrollClass={horizontalScrollClass}
      horizontalScroll={horizontalScroll}
      name={name}
      previous={previous}
      id={id}
      editableBudget={editableBudget}
      updateArea={onUpdateArea}
      levels={levels}
      externalCosts={externalCosts}
      markup={markup}
      cost={cost}
      enabled={enabled}
      price={price}
      days={days}
      tasks={taskList}
      setScrollingComponentScrollAmount={
        props.setScrollingComponentScrollAmount
      }
      scrollingComponentAmount={props.scrollingComponentAmount}
      mainBudgetPriceListLevels={mainBudgetPriceListLevels}
      priceLists={priceLists} />
  );
};

module.exports = AreaFactory;
