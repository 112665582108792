const React = require('react');

const CheckboxEmptyIcon = () => (
  <svg className="wethod-checkbox__icon wethod-checkbox__icon--empty"
    focusable="false"
    viewBox="0 0 16 16"
    fill="none">
    <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#212121" />
  </svg>
);

module.exports = CheckboxEmptyIcon;
