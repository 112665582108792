const React = require('react');
const Task = require('./Task.react');
const PrimaryInfo = require('./TaskPrimaryInfo.react');
const Name = require('./TaskName.react');
const Levels = require('./TaskLevels.react');
const ExternalCosts = require('../Cell/ExternalCostCell.react');
const Markup = require('./TaskMarkup.react');
const MoreOptions = require('../MoreOptionsButton/MoreOptionsButton.react');
const MoreOptionsMenuItem = require('../MoreOptionsButton/MoreOptionsMenuItem.react');
const PercentageCell = require('../Cell/PercentageCell.react');
const DaysCell = require('../Cell/ManDaysCell.react');
const Price = require('../Cell/PriceCell.react');
const Cost = require('../Cell/CostCell.react');
const CurrencyManager = require('../../../containers/CurrencyManager');
const IconCell = require('../Cell/IconCell.react');
const JobTitle = require('../../../containers/Calculator/JobTitle/JobTitle');
const LevelService = require('../../../services/LevelService');

const BaseTask = ({
  column,
  name,
  levels,
  externalCosts,
  markup,
  days,
  cost,
  price,
  editable,
  id,
  updateTaskInfo,
  updateTaskLevel,
  currency,
  deleteTask,
  showIntercompanyTaskModal,
  previous,
  budgetTaskId,
  isDefault,
  deleted,
  created,
  showJobTitleInsertModal,
  levelsTotals,
  jobTitles = [],
  mainBudgetPriceListLevels,
}) => {
  function saveName(e) {
    updateTaskInfo(id, { name: e.target.value });
  }

  function saveTaskLevel(taskLevelAmountId, levelId, quantity) {
    updateTaskLevel(taskLevelAmountId, levelId, budgetTaskId, quantity);
  }

  function saveTaskValues(changes) {
    updateTaskInfo(id, changes);
  }

  function saveExternalCosts(value, currencyCode) {
    const nextMarkup = value === 0 ? 0 : markup;
    saveTaskValues({
      external_cost: value,
      markup: nextMarkup,
      currency: currencyCode,
    });
  }

  function saveMarkup(value) {
    saveTaskValues({ markup: value });
  }

  function showJobTitleModal() {
    // Sum of days not related to job titles
    const nonJobTitleTaskDays = jobTitles.length ? 0 : days;
    showJobTitleInsertModal(id, nonJobTitleTaskDays, jobTitles);
  }

  const jobTitleList = jobTitles.map((jobTitle) => (
    <JobTitle dayId={jobTitle.id}
      created={jobTitle.is_new}
      deleted={jobTitle.deleted}
      taskId={id}
      key={jobTitle.id}
      name={jobTitle.budget_job_title.name}
      days={jobTitle.days}
      cost={jobTitle.total.cost}
      price={jobTitle.total.price}
      editableBudget={editable}
      updateLevel={saveTaskLevel}
      jobTitleId={jobTitle.budget_job_title.id}
      levelId={jobTitle.budget_job_title.job_title_level.id}
      previous={jobTitle.was}
      column={column} />
  ));

  const jobTitleContainer = () => (
    <div className="wethod-budget-task__job-title-container">{jobTitleList}</div>
  );

  const areLevelsEditable = () => editable && jobTitles.length === 0;

  /**
   * Return levels with previous values and total values.
   * Total days can differ from level days if task has job titles for that level.
   */
  // eslint-disable-next-line no-param-reassign
  const levelsToSort = levels.map((level) => {
    const levelForSort = mainBudgetPriceListLevels
      .find((budgetPriceListLevel) => budgetPriceListLevel.level.id === level.level.level.id);
    return {
      ...level,
      sort: levelForSort.sort,
    };
  });
  const levelsWithTotals = LevelService.sort(levelsToSort).map((level) => ({
    ...level,
    was: previous.levels.find((levelPrev) => levelPrev.level_id === level.level.level.id),
    total: levelsTotals.find((levelsTotal) => levelsTotal.level_id === level.level.level.id),
  }));

  if (column === 'left') {
    return (
      <Task
        exists={created}
        existed={deleted}
        column={column}>
        <PrimaryInfo>
          <Name editable={editable && !isDefault} save={saveName} id={`task-${id}-name`}>{name}</Name>
          <PercentageCell />
        </PrimaryInfo>
        {jobTitleContainer()}
      </Task>
    );
  }
  if (column === 'center') {
    return (
      <Task column={column}>
        <PrimaryInfo>
          <Levels items={levelsWithTotals}
            saveLevel={saveTaskLevel}
            editable={areLevelsEditable()}
            taskId={id} />
        </PrimaryInfo>
        {jobTitleContainer()}
      </Task>
    );
  }
  return (
    <Task column={column}>
      <PrimaryInfo>
        <CurrencyManager>
          <ExternalCosts save={saveExternalCosts}
            currencyCode={currency}
            editable={editable}
            value={externalCosts}
            previousValue={previous.external_cost}
            id={`task-${id}-external-cost`} />
        </CurrencyManager>
        <Markup save={saveMarkup}
          id={`task-${id}-markup`}
          editable={editable && externalCosts !== 0}
          previousValue={previous.markup}>{markup}
        </Markup>
        <DaysCell>{days}</DaysCell>
        <Cost>{cost}</Cost>
        <Price>{price}</Price>
        <IconCell />
        <MoreOptions editable={editable && !isDefault}>
          <MoreOptionsMenuItem onClick={deleteTask}>Delete</MoreOptionsMenuItem>
          <MoreOptionsMenuItem onClick={showIntercompanyTaskModal}>
            Make intercompany
          </MoreOptionsMenuItem>
          <MoreOptionsMenuItem onClick={showJobTitleModal}>
            Insert job title
          </MoreOptionsMenuItem>
        </MoreOptions>
      </PrimaryInfo>
      {jobTitleContainer()}
    </Task>
  );
};

module.exports = BaseTask;
