'use strict';

/* eslint-disable default-case */
var PipedriveIntegration = require('../../../models/PipedriveIntegration');

Wethod.module('SettingsApp.Team', function (Team, Wethod, Backbone, Marionette, $, _, Header) {
  // Declare views
  var teamLayout;
  var headerView;
  var invitationLayout;
  var invitationCollectionView;
  var actualLayout;
  var actualCollectionView;

  // Collections
  var invitations;
  var levels;
  var jobTitles = [];
  var roles;
  var actuals;
  var tagCategorys;
  var userCodesCollection;
  var filteredUserCodesCollection;
  var isEmptyTagCategorys = false;

  var moreThanOneSuperUser = true;
  var isPipedriveIntegrationEnabled = false;

  var resetData = function () {
    teamLayout = {};
    headerView = {};
    invitationLayout = {};
    invitationCollectionView = {};
    actualLayout = {};
    actualCollectionView = {};

    invitations = {};
    levels = {};
    jobTitles = {};
    roles = {};
    actuals = {};
    tagCategorys = {};
  };

  this.autocompleteJobTitles = function (region, val, employeeObj) {
    var params = {
      whatSearch: val,
      levelId: employeeObj.model.get('level').id,
      archived: false,
    };
    var autocompleteRequest = Wethod.request('get:jobTitle:autocomplete', params);
    region.html('Loading...');

    $.when(autocompleteRequest).done(function (autocompleteResponse) {
      if (!_.isUndefined(autocompleteResponse)) {
        var autocompleteTemplate = new Team.JobTitleAutocompleteCollectionView({
          collection: autocompleteResponse,
          childViewOptions: {
            parent: employeeObj,
          },
        });
        if (_.isEmpty(autocompleteResponse.models)) {
          region.html('<p>Cannot find associated job title</p>');
        } else {
          region.html(autocompleteTemplate.render().$el);
        }
      }
    });
  };

  this.deleteEmployeeJobTitle = function (employeeId) {
    Wethod.request('delete:employee:jobTitle', employeeId);
  };

  this.updateEmployeeJobTitle = function (hint, parent, employeeId, jobTitleId) {
    var updateJobTitleRequest = Wethod.request('update:employee:jobTitle', employeeId, jobTitleId);
    $.when(updateJobTitleRequest).done(function (updateJobTitleResponse) {
      if (!_.isUndefined(updateJobTitleResponse)) {
        var jobTitleName = updateJobTitleResponse.options.job_title_name;
        parent.ui.jobTitlesInput.val(jobTitleName);
      }
    });
  };

  this.autocomplete = function (region, val, catType, employeeObj) {
    val = val.replace(' ', ',');
    var category;
    switch (catType) {
      case 'A':
        category = tagCategorys.models[0].attributes.id;
        break;
      case 'B':
        category = tagCategorys.models[1].attributes.id;
        break;
      case 'C':
        category = tagCategorys.models[2].attributes.id;
        break;
    }

    var params = {
      whatSearch: (val === '') ? undefined : val,
      category: category,
    };
    var autocompleteRequest = Wethod.request('get:tag:autocomplete', params);

    region.html('Loading...');

    $.when(autocompleteRequest).done(function (autocompleteResponse) {
      if (!_.isUndefined(autocompleteResponse)) {
        if (_.isEmpty(autocompleteResponse.models)) {
          region.html('Nothing found.');
        } else {
          // display hits
          var autocompleteTemplate = new Team.AutocompleteCollectionView({
            collection: autocompleteResponse,
            childViewOptions: {
              idCategory: category,
              catType: catType,
              parent: employeeObj,
            },
          });
          region.html(autocompleteTemplate.render().$el);
        }
      }
    });
  };

  this.createNewTag = function (value, catType, idCategory, employeeObj) {
    switch (catType) {
      case 'A':
        idCategory = tagCategorys.models[0].attributes.id;
        break;
      case 'B':
        idCategory = tagCategorys.models[1].attributes.id;
        break;
      case 'C':
        idCategory = tagCategorys.models[2].attributes.id;
        break;
    }

    var data = {
      name: value,
      id_category: idCategory,
    };
    var relationData = {};
    var tagModel = new Team.EmployeeTagModel(data);
    var insertTagRequest = Wethod.request('team:insert:tag', data);

    // valido il tag inserito
    if (tagModel.isValid()) {
      // creo il nuovo tag
      $.when(insertTagRequest).done(function (insertTagResponse) {
        relationData.id_employee = employeeObj.model.get('employee').id;
        relationData.id_tag_emp = insertTagResponse.get('id');
        Team.updateEmployeeTag(insertTagResponse, catType, employeeObj, 'insert');
      });
    } else {
      // displayError( projectModel.validationError, viewObj.$el );
    }
  };

  this.updateEmployeeTag = function (tagModel, catType, employeeObj, action) {
    var data = {};
    var sync = {};
    var insertTagRealationRequest;

    data.id_employee = employeeObj.model.get('employee').id;
    data.id_tag_emp = tagModel.get('id');

    insertTagRealationRequest = Wethod.request('team:insert:tag:relation', data);
    $.when(insertTagRealationRequest).done(function (insertTagRealationResponse) {
      if (action === 'update' && !_.isEmpty(employeeObj.model.get('tags'))) {
        switch (catType) {
          case 'A':
            _.each(employeeObj.model.get('tags'), function (tag) {
              if (tag.tag_emp_category_id === employeeObj.model.get('tagCategory')[0].id) {
                employeeObj.model.get('tags')[0].tag_emp_id = tagModel.get('id_tag_emp');
                employeeObj.model.get('tags')[0].employee_tag_id = insertTagRealationResponse.get('id');
                employeeObj.model.get('tags')[0].tag_emp_name = tagModel.get('name');
                employeeObj.model.get('tags')[0].tag_emp_category_id = tagModel.get('category').id;
              }
            });

            break;
          case 'B':
            _.each(employeeObj.model.get('tags'), function (tag) {
              if (tag.tag_emp_category_id === employeeObj.model.get('tagCategory')[1].id) {
                employeeObj.model.get('tags')[1].tag_emp_id = tagModel.get('id_tag_emp');
                employeeObj.model.get('tags')[1].employee_tag_id = insertTagRealationResponse.get('id');
                employeeObj.model.get('tags')[1].tag_emp_name = tagModel.get('name');
                employeeObj.model.get('tags')[1].tag_emp_category_id = tagModel.get('category').id;
              }
            });
            break;
          case 'C':
            _.each(employeeObj.model.get('tags'), function (tag) {
              if (tag.tag_emp_category_id === employeeObj.model.get('tagCategory')[1].id) {
                employeeObj.model.get('tags')[2].tag_emp_id = tagModel.get('id_tag_emp');
                employeeObj.model.get('tags')[2].employee_tag_id = insertTagRealationResponse.get('id');
                employeeObj.model.get('tags')[2].tag_emp_name = tagModel.get('name');
                employeeObj.model.get('tags')[2].tag_emp_category_id = tagModel.get('category').id;
              }
            });
            break;
        }
      } else {
        sync.tag_emp_id = tagModel.get('id');
        sync.employee_tag_id = insertTagRealationResponse.get('id');
        sync.tag_emp_name = tagModel.get('name');

        switch (catType) {
          case 'A':
            employeeObj.ui.tagAInput.val(tagModel.get('name'));
            employeeObj.ui.tagListA.removeClass('isVisible');
            sync.tag_emp_category_id = employeeObj.model.get('tagCategory')[0].id;
            break;
          case 'B':
            employeeObj.ui.tagBInput.val(tagModel.get('name'));
            employeeObj.ui.tagListB.removeClass('isVisible');
            sync.tag_emp_category_id = employeeObj.model.get('tagCategory')[1].id;
            break;
          case 'C':
            employeeObj.ui.tagCInput.val(tagModel.get('name'));
            employeeObj.ui.tagListC.removeClass('isVisible');
            sync.tag_emp_category_id = employeeObj.model.get('tagCategory')[2].id;
            break;
        }

        employeeObj.model.get('tags').push(sync);
      }
    });
  };

  this.deleteEmployeeTag = function (employeeObj, catType) {
    var idRel;

    switch (catType) {
      case 'A':
        for (var i = 0; i < employeeObj.model.get('tags').length; i++) {
          if (employeeObj.model.get('tags')[i].tag_emp_category_id === employeeObj.model.get('tagCategory')[0].id) {
            idRel = employeeObj.model.get('tags')[i].employee_tag_id;
            employeeObj.model.get('tags').splice(i, 1);
          }
        }
        break;
      case 'B':
        for (var j = 0; j < employeeObj.model.get('tags').length; j++) {
          if (employeeObj.model.get('tags')[j].tag_emp_category_id === employeeObj.model.get('tagCategory')[1].id) {
            idRel = employeeObj.model.get('tags')[j].employee_tag_id;
            employeeObj.model.get('tags').splice(j, 1);
          }
        }
        break;
      case 'C':
        for (var k = 0; k < employeeObj.model.get('tags').length; k++) {
          if (employeeObj.model.get('tags')[k].tag_emp_category_id === employeeObj.model.get('tagCategory')[2].id) {
            idRel = employeeObj.model.get('tags')[k].employee_tag_id;
            employeeObj.model.get('tags').splice(k, 1);
          }
        }
        break;
    }

    if (!_.isUndefined(idRel)) {
      Wethod.request('team:delete:tag:relation', { id: idRel });
    }
  };

  this.showModal = function (data, options) {
    var model = new Team.ModalModel(data);
    var modalTemplate = new Team.ModalItemView({
      model: model,
      data: options,
    });

    teamLayout.modal.show(modalTemplate);
  };

  this.showImportTagsModal = function () {
    var model = new Backbone.Model();
    var modalView = new Team.ImportTagsModalView({ model: model });

    teamLayout.modal.show(modalView);
  };

  this.showAllocationManagerModal = function (viewObj) {
    var activeEmployees = actuals.filter(function (actual) {
      // Employee not archived
      return actual.get('employee').type !== 2;
    });
    var modalView = new Team.AllocationManagerModalView({
      employees: activeEmployees,
      viewObj: viewObj,
      model: new Backbone.Model(viewObj.model.get('employee')),
    });

    teamLayout.modal.show(modalView);
  };

  this.showPricelistModal = function (viewObj) {
    var modalView = new Team.PricelistModalView({
      pricelists: [],
      viewObj: viewObj,
      model: new Backbone.Model({
        person: viewObj.model.get('person'),
        employee: viewObj.model.get('employee'),
        pricelist: viewObj.model.get('price_list'),
        level: viewObj.model.get('level'),
      }),
    });

    teamLayout.modal.show(modalView);
  };
  this.showChangeLogModal = function (viewObj) {
    var employee = viewObj.model.get('employee');
    var changeLogRequest = Wethod.request('get:employee:change-log', employee.id);

    // viewObj.html('Loading...');

    $.when(changeLogRequest).done(function (changeLogResponse) {
      if (!_.isUndefined(changeLogResponse)) {
        var modalView = new Team.ChangeLogModalView({
          // pricelists: [],
          // viewObj: viewObj,
          model: new Backbone.Model({
            changeLogs: changeLogResponse,
          }),
        });

        // if (_.isEmpty(changeLogResponse.models)) {
        //   viewObj.html('<p>Cannot find changelog</p>');
        // } else {
        teamLayout.modal.show(modalView);
        // }
      }
    });
  };

  this.showUserCodeModal = function (data, employeeView) {
    var employee = data.get('employee');
    var userCodes = employee.external_ids ? employee.external_ids : [];
    userCodesCollection = new Team.EmployeeExternalIdCollection(userCodes);
    filteredUserCodesCollection = userCodesCollection.clone();

    var modalTemplate = new Team.UserCodeHistoryModalView({
      model: data,
      employeeView: employeeView,
      isPipedriveIntegrationEnabled: isPipedriveIntegrationEnabled,
      userCodesCollection: userCodesCollection,
    });

    // Only show the filtered codes
    var userCodesView = new Team.UserCodeCollectionView({
      collection: filteredUserCodesCollection,
      employeeView: employeeView,
    });

    teamLayout.modal.show(modalTemplate);

    modalTemplate.userCodes.show(userCodesView);
  };

  this.uninvite = function (inviteViewObj, modalViewObj) {
    var model = inviteViewObj.model;

    modalViewObj.ui.wrapMessage.show();
    modalViewObj.ui.messageEl.text('Loading...');
    modalViewObj.ui.actionButton.addClass('disabled');
    modalViewObj.ui.cancelButton.addClass('disabled');

    var options = {
      code: model.get('code'),
    };

    var deleteInvitationRequest = Wethod.request('team:delete:invitation', options);
    $.when(deleteInvitationRequest).done(function () {
      if (!_.isUndefined(deleteInvitationRequest.code) && deleteInvitationRequest.code === 500) {
        modalViewObj.ui.messageEl.addClass('error');
        modalViewObj.ui.messageEl.text(deleteInvitationRequest.code);
      } else {
        inviteViewObj.destroy();
        modalViewObj.destroy();
      }
    });
  };

  this.ArchiveEmployee = function (action, employeeViewObj, modalViewObj) {
    var options = {
      id: employeeViewObj.model.get('employee').id,
    };
    var archiveRequest;
    var type;
    switch (action) {
      case 'unarchive':
        archiveRequest = Wethod.request('team:unarchive:employee', options);
        type = 0;
        break;
      case 'archive':
        archiveRequest = Wethod.request('team:archive:employee', options);
        type = 2;
        break;
    }

    modalViewObj.ui.wrapMessage.show();
    modalViewObj.ui.messageEl.text('Loading...');
    modalViewObj.ui.actionButton.addClass('disabled');
    modalViewObj.ui.cancelButton.addClass('disabled');

    $.when(archiveRequest).done(function (archiveResponse) {
      if (!_.isUndefined(archiveResponse) && archiveResponse.code === 500) {
        modalViewObj.ui.messageEl.addClass('error');
        // MESSGGIO ERRORE STRIPE
        modalViewObj.ui.messageEl.text('Unfortunately your payment couldn\'t be processed. Double check whether your credit card datas were incorrect or you lack enough money in your debit card.');
        modalViewObj.ui.cancelButton.removeClass('disabled');
        modalViewObj.ui.hiddenActionWrap.show();
      } else {
        if (type === 2) {
          employeeViewObj.ui.archiveIcon.show();
        } else {
          employeeViewObj.ui.archiveIcon.hide();
        }

        // sync employee model
        employeeViewObj.model.get('employee').type = type;
        modalViewObj.destroy();
      }
    });
  };

  this.saveCategory = function (tagNameObj, name) {
    var data = {
      id: tagNameObj.model.get('id'),
      name: name,
    };

    $(tagNameObj.ui.category).prop('disabled', true);
    var saveCatRequest = Wethod.request('save:tagCategory', data);
    $.when(saveCatRequest).done(function (saveCatResponse) {
      var catNumber = isEmptyTagCategorys ? 0 : 1;

      if (!_.isNull(data.id)) {
        $(tagNameObj.ui.category).prop('disabled', false);
      } else {
        switch (catNumber) {
          case 0:
            tagCategorys.models[0].attributes = saveCatResponse.attributes;
            $('[data-action="selectTagA"]').prop('disabled', false);
            break;
          case 1:
            tagCategorys.models[1].attributes = saveCatResponse.attributes;
            $('[data-action="selectTagB"]').prop('disabled', false);
            break;
        }

        isEmptyTagCategorys = false;

        $(tagNameObj.ui.category).prop('disabled', false);
      }
    });
  };

  // invitation change plan
  this.checkPlan = function (recordViewObj, modifier, action, moreData) {
    switch (action) {
      case 'insert':

        recordViewObj.ui.sendNewInvitationButtonLabel.text('LOADING...');
        recordViewObj.ui.sendNewInvitationButton.addClass('disabled');

        var invitationModel = new Team.EmployeeInvitationModel(moreData);
        if (invitationModel.isValid()) {
          Team.sendNewInvitation(moreData);
        } else {
          recordViewObj.ui.sendNewInvitationButtonLabel.text('invite');
          recordViewObj.ui.sendNewInvitationButton.removeClass('disabled');
          invitationLayout.errorNewInvitation();
        }

        break;
      case 'unarchive':
        Team.ArchiveEmployee(action, recordViewObj, moreData);
        break;
      case 'archive':
        Team.ArchiveEmployee(action, recordViewObj, moreData);
        break;
    }
  };

  this.sendNewInvitation = function (invitationData, modalViewObj) {
    if (!_.isEmpty(modalViewObj)) {
      modalViewObj.ui.wrapMessage.show();
      modalViewObj.ui.messageEl.removeClass('error');
      modalViewObj.ui.messageEl.text('Loading...');
      modalViewObj.ui.actionButton.addClass('disabled');
      modalViewObj.ui.cancelButton.addClass('disabled');
    }

    var newInvitationReques = Wethod.request('create:invitation', invitationData);
    $.when(newInvitationReques).done(function (saveInvitationCollection) {
      if (_.isUndefined(saveInvitationCollection)) {
        invitationLayout.errorNewInvitation();
      } else {
        // if( !_.isUndefined( saveInvitationCollection ) && saveInvitationCollection.code === 500
        // ){  modalViewObj.ui.messageEl.addClass('error'); //MESSGGIO ERRORE STRIPE
        // modalViewObj.ui.messageEl.text('Unfortunately your payment couldn\'t be processed.
        // Double check whether your credit card datas were incorrect or you lack enough money in
        // your debit card.'); modalViewObj.ui.cancelButton.removeClass('disabled');
        // modalViewObj.ui.hiddenActionWrap.show(); $('.newInvitation').hide();  //TODO rimuovere
        // record inserito }else{
        invitationLayout.hideNewInvitation();
        saveInvitationCollection.each(function (inv) {
          inv.set('role', inv.get('role').toLowerCase().substring(5, inv.get('role').length));
          invitations.add(inv, { merge: true });
          invitationCollectionView.render();
        });
        if (!_.isEmpty(modalViewObj)) {
          modalViewObj.destroy();
        }
        // }
      }
    });
  };

  this.hideFromPlanning = function (employeeView, modalView) {
    var employeeId = employeeView.model.get('employee').id;
    var hideFromPlanningRequest = Wethod.request('team:hide:employee', employeeId);
    $.when(hideFromPlanningRequest).done(function () {
      modalView.destroy();
      employeeView.ui.showHidePlanningIcon.show();
      employeeView.model.get('employee').visible = false;
    });
  };

  this.showOnPlanning = function (employeeView, modalView) {
    var employeeId = employeeView.model.get('employee').id;
    var hideFromPlanningRequest = Wethod.request('team:show:employee', employeeId);
    $.when(hideFromPlanningRequest).done(function () {
      modalView.destroy();
      employeeView.ui.showHidePlanningIcon.hide();
      employeeView.model.get('employee').visible = true;
    });
  };

  this.makeTimesheetNeeded = function (employeeView, modalView) {
    var employeeId = employeeView.model.get('employee').id;
    var makeTimesheetNeededRequest = Wethod.request('team:timesheet:needed', {
      employeeId: employeeId,
      timesheetNeeded: true,
    });
    $.when(makeTimesheetNeededRequest).done(function () {
      modalView.destroy();
      employeeView.ui.timesheetNotNeededIcon.hide();
      employeeView.model.get('employee').timesheet_needed = true;
    });
  };

  this.makeTimesheetOptional = function (employeeView, modalView) {
    var employeeId = employeeView.model.get('employee').id;
    var makeTimesheetNeededRequest = Wethod.request('team:timesheet:needed', {
      employeeId: employeeId,
      timesheetNeeded: false,
    });
    $.when(makeTimesheetNeededRequest).done(function () {
      modalView.destroy();
      employeeView.ui.timesheetNotNeededIcon.show();
      employeeView.model.get('employee').timesheet_needed = false;
    });
  };

  this.saveUserCode = function (data, employeeView, modalView) {
    var employeeId = employeeView.model.get('employee').id;
    var saveUserCodeRequest = Wethod.request('team:save:user-code', data, employeeId);
    $.when(saveUserCodeRequest).done(function (response) {
      var extIds = employeeView.model.get('employee').external_ids;
      if (!extIds) {
        extIds = [];
      }

      extIds.unshift(response);
      employeeView.model.get('employee').external_ids = extIds;
      var model = new Team.EmployeeExternalId(response);
      userCodesCollection.add(model, { at: 0 });
      filteredUserCodesCollection.add(model, { at: 0 });
      modalView.hideError();
      modalView.hideNewCode();
    }).fail(function (response) {
      var error = 'Error: ' + response.message;
      modalView.showError(error);
      modalView.enableSave();
    });
  };

  this.updateUserCode = function (model, employeeView, itemView) {
    var updateUserCodeRequest = Wethod.request('team:update:user-code', model);
    $.when(updateUserCodeRequest).done(function () {
      var extIds = userCodesCollection.toJSON();
      employeeView.model.get('employee').external_ids = extIds;

      itemView.hideError();
    }).fail(function (response) {
      var error = 'Error: ' + response.message;
      itemView.showError(error);
    });
  };

  this.deleteUserCode = function (model, employeeView, itemView) {
    var deleteUserCodeRequest = Wethod.request('team:delete:user-code', model);
    $.when(deleteUserCodeRequest).done(function () {
      var extIds = userCodesCollection.toJSON();
      employeeView.model.get('employee').external_ids = extIds;

      itemView.hideError();
    }).fail(function (response) {
      var error = 'Error: ' + response.message;
      itemView.showError(error);
    });
  };

  this.filterUserCode = function (userCodeList) {
    filteredUserCodesCollection.reset(userCodeList);
  };

  var prepareRoleName = function (roleName) {
    return roleName.toLowerCase().substring(5, roleName.length).replace('_', ' ');
  };

  /**
   * Return if the given role object represents a non custom super user.
   * @param role {{id,name}}
   * @returns {boolean}
   */
  this.isSuperUserRole = function (role) {
    return role.name === 'ROLE_SUPER_USER' || role.name === 'super_user' || role.name === 'super user';
  };

  /**
   * Return the amount of actual super users.
   * @returns {number}
   */
  var countSuperUser = function () {
    if (actuals instanceof Backbone.Collection) {
      return actuals.filter(function (user) {
        return Team.isSuperUserRole(user.get('role'));
      }).length;
    }
    throw new TypeError('\'actuals\' must be a Backbone.Collection');
  };

  Team.Controller = {
    showTeam: function (options) {
      resetData();
      // Splash screen loading
      var sentence = Wethod.Utility.randomSentence(Wethod.sentencesDays, Wethod.sentencesSongs);
      var loadingView = new Wethod.View.Loading.BigView({ sentence: sentence });
      Wethod.regions.body.show(loadingView);

      var authorizationRequest = Wethod.request('get:settings:authorization');
      $.when(authorizationRequest).done(function (authorizationResponse) {
        if (_.isUndefined(authorizationResponse)) {
          return;
        }

        Wethod.SettingsApp.authorization = authorizationResponse;

        if (Wethod.SettingsApp.getPermission('team')) {
          var sectionHeaderModel = new Header.SectionHeaderModel({
            current_section: 'Team',
            helper_url: 'settings/index/#team',
          });
          // Initialize views
          teamLayout = new Team.TeamLayoutView();
          headerView = new Header.SectionHeaderView({ model: sectionHeaderModel });
          invitationLayout = new Team.InvitationLayoutView();

          // fetch levels and roles
          var getLevels = Wethod.request('get:levels');
          var getRoles = Wethod.request('get:roles');

          var companyJobTitles = Wethod.request('get:jobTitles');
          $.when(getLevels, getRoles, companyJobTitles)
            .done(function (responseLevels, responseRoles, responseJobTitles) {
              if (
                _.isUndefined(responseLevels)
                || _.isUndefined(responseRoles)
                || _.isUndefined(responseJobTitles)) {
                return;
              }

              responseRoles.each(function (role) {
                role.set('name', prepareRoleName(role.get('name')));
              });

              levels = responseLevels;
              roles = responseRoles;
              jobTitles = responseJobTitles;

              // show pending invitations and actual team
              var getInvitations = Wethod.request('get:invitations');
              var getActualTeam = Wethod.request('get:actual');
              var getTagCategory = Wethod.request('get:tag:category');

              $.when(
                getInvitations,
                getActualTeam,
                getTagCategory,
                PipedriveIntegration.isEnabled()
              )
                .done(function (responseInvitation, responseActualTeam, responseTagCategory,
                  isPipedriveEnabled) {
                  invitations = responseInvitation;
                  actuals = responseActualTeam;
                  tagCategorys = responseTagCategory;
                  moreThanOneSuperUser = countSuperUser() > 1;
                  isPipedriveIntegrationEnabled = isPipedriveEnabled;

                  if (tagCategorys.length === 0) {
                    isEmptyTagCategorys = true;
                  }

                  if (responseTagCategory.models.length < 2) {
                    for (var i = responseTagCategory.models.length; i < 2; i++) {
                      var TagCategoryModel = new Team.TagCategoryModel();
                      responseTagCategory.add(TagCategoryModel);
                    }
                  }

                  var tagCategoryTemplate = new Team.TagCategorysCollectionView({
                    collection: tagCategorys,
                    jobTitles: jobTitles,
                  });
                  invitations.each(function (inv) {
                    inv.set('role', prepareRoleName(inv.get('role')));
                  });

                  invitationCollectionView = new Team.InvitationsCollectionView({
                    collection: invitations,
                  });
                  actualCollectionView = new Team.ActualCollectionView({
                    collection: actuals,
                    childViewOptions: {
                      tagCategory: tagCategorys.models,
                      isPipedriveIntegrationEnabled: isPipedriveIntegrationEnabled,
                      jobTitles: jobTitles,
                    },
                  });
                  // Per gestire l'eventuale presenza del tag speciale "Business Unit" introdotto
                  // per fusione HIC - TRA
                  actualLayout = tagCategorys.length > 2
                    ? new Team.ActualLayoutWithBUView({
                      jobTitles: jobTitles,
                      model: new Wethod.Utility.BaseModel(),
                    }) : new Team.ActualLayoutView({
                      jobTitles: jobTitles,
                      model: new Wethod.Utility.BaseModel(),
                    });
                  // Render
                  teamLayout.render();
                  teamLayout.header.show(headerView);
                  if (Wethod.userInfo.get('company') !== 'playground') {
                    teamLayout.invitation.show(invitationLayout);
                    invitationLayout.showNewInvitationButton();
                    invitationLayout.invitation.show(invitationCollectionView);
                  }
                  teamLayout.actual.show(actualLayout);

                  actualLayout.tagCategoryInput.show(tagCategoryTemplate);
                  actualLayout.actual.show(actualCollectionView);

                  // adjust the height of the div with white background
                  var fill = $('.fillHeight');
                  if (fill.height() < $('html').height()) {
                    fill.height('100%');
                  } else {
                    fill.height('auto');
                  }

                  dispatcher.trigger('tour:start', options.idTour);
                });
            });
        } else {
          loadingView.destroy();
          var permissionDeniedView = new Wethod.PermissionDenied.PermissionDeniedTemplateView();
          Wethod.regions.body.show(permissionDeniedView);
        }
      });
    },
    showNewInvitation: function () {
      if (!invitationLayout.isInvitationButtonEnabled()) {
        var levelCollectionView = this.getLevels();
        var roleCollectionView = this.getRoles();

        invitationLayout.levels.show(levelCollectionView);
        invitationLayout.roles.show(roleCollectionView);

        invitationLayout.showNewInvitation();
      }
    },

    getLevels: function (employeeId, levelId, pricelistId) {
      return new Team.LevelCollectionView({
        collection: levels,
        employeeId: employeeId,
        employeeLevel: levelId,
        pricelistId: pricelistId,
        childViewOptions: {
          employeeLevel: levelId,
        },
      });
    },

    getRoles: function (employeeId, roleId) {
      return new Team.RoleCollectionView({
        collection: roles,
        employeeId: employeeId,
        employeeRole: roleId,
        childViewOptions: {
          employeeRole: roleId,
          moreThanOneSuperUser: moreThanOneSuperUser,
        },
      });
    },

    saveLevel: function (employeeId, levelId, pricelistId) {
      var params = {
        pricelistId: pricelistId,
        levelId: levelId,
        employeeId: employeeId,
      };
      $.when(Wethod.request('team:employee:pricelist:level:update', params)).done(function () {
        Team.deleteEmployeeJobTitle(employeeId);
      });
    },

    saveRole: function (employeeId, roleId) {
      $.when(Wethod.request('save:role', employeeId, roleId)).done(function () {
        // update actuals
        var foundRole = roles.filter(function (role) {
          return role.get('id') === parseInt(roleId);
        })[0];

        var foundRoleName = prepareRoleName(foundRole.options.name);

        _.each(actuals.models, function (person) {
          if (person.get('employee').id === parseInt(employeeId)) {
            person.get('role').id = foundRole.get('id');
            person.get('role').name = foundRoleName;
          }
        });

        moreThanOneSuperUser = countSuperUser() > 1;

        actualCollectionView = new Team.ActualCollectionView({
          collection: actuals,
          childViewOptions: {
            tagCategory: tagCategorys.models,
            isPipedriveIntegrationEnabled: isPipedriveIntegrationEnabled,
            jobTitles: jobTitles,
          },
        });

        actualLayout.actual.show(actualCollectionView);
      });
    },
  };
}, Wethod.module('HeaderApp.Header'));
