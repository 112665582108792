class ChartPickerTab {
  constructor(title, chartId, canDeleteChart = false, isSelected = false) {
    this.title = title;
    this.chartId = chartId;
    this.canDeleteChart = canDeleteChart;
    this.isSelected = isSelected;
  }
}

module.exports = ChartPickerTab;
