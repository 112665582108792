const { connect } = require('react-redux');
const Component = require('../../../components/modal/TransferHoursModal/TransferHoursRow.react');
const actions = require('../../../actions');

const mapDispatchToProps = (dispatch) => ({
  onTimesheetSelect: (id, selected) => dispatch(actions.selectTimesheetToTransfer(id, selected)),
  updateHoursToTransfer: (id, hours) => dispatch(actions.updateHoursToTransfer(id, hours)),
});

module.exports = connect(null, mapDispatchToProps)(Component);
