module.exports = class ChartConfigAccountDimensionGrouping {
  /**
   * @param {string} type
   */
  constructor(type) {
    this.type = type;
  }

  /**
   * @abstract
   * @return {boolean}
   */
  // eslint-disable-next-line class-methods-use-this
  isGrouped() {
    throw new Error('isGrouped must be defined in classed that extends ChartConfigAccountDimensionGrouping');
  }

  /**
   * Return a copy of this instance.
   * @abstract
   * @return {ChartConfigAccountDimensionGrouping}
   */
  // eslint-disable-next-line class-methods-use-this
  clone() {
    throw new Error('clone must be defined in classes that extend ChartConfigAccountDimensionGrouping');
  }
};
