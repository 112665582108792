const ChartConfigDimension = require('../ChartConfigDimension');
const DistinctGrouping = require('./ChartConfigProbabilityDimensionDistinctGrouping');
const ClusterGrouping = require('./ChartConfigProbabilityDimensionClusterGrouping');

class ChartConfigProbabilityDimension extends ChartConfigDimension {
  /**
   * @param {ChartConfigProbabilityDimensionGrouping} grouping
   */
  constructor(grouping = new DistinctGrouping()) {
    super(ChartConfigProbabilityDimension.KEY);
    this.grouping = grouping;
  }

  /**
   * Build and return probability dimension grouping based on given data.
   * @param data
   * @return {ChartConfigProbabilityDimensionGrouping}
   */
  static groupingFromJSON(data) {
    switch (data.type) {
      case DistinctGrouping.TYPE:
        return DistinctGrouping.fromJSON();
      case ClusterGrouping.TYPE:
        return ClusterGrouping.fromJSON(data);
      default:
        throw new Error(`'${data.type}' is not a valid probability dimension grouping type`);
    }
  }

  /**
   * Build and return probability dimension based on given data.
   * @param data
   * @return {ChartConfigProbabilityDimension}
   */
  static fromJSON(data) {
    return new ChartConfigProbabilityDimension(ChartConfigProbabilityDimension
      .groupingFromJSON(data.grouping));
  }

  /**
   * Return a copy of this instance.
   * @abstract
   * @return {ChartConfigProbabilityDimension}
   */
  clone() {
    return new ChartConfigProbabilityDimension(this.grouping.clone());
  }
}

ChartConfigProbabilityDimension.KEY = 'probability';

module.exports = ChartConfigProbabilityDimension;
