const { connect } = require('react-redux');
const Component = require('../../../components/Calculator/Header/Header.react');
const actions = require('../../../actions');

const mapStateToProps = (state) => ({
  scrollingComponentAmount: state.scrollingComponentAmount,
  status: state.info.status,
  days: state.info.days,
  mainBudgetPriceListLevels: state.info.mainBudgetPriceListLevels,
  budgetPriceListLevels: state.info.budget_price_list_levels,
  budgetId: state.info.id,
});
const mapDispatchToProps = (dispatch) => ({
  showPricelistModal: () => dispatch(actions.showModalPricelist()),
  setScrollingComponentScrollAmount: ((amount) => dispatch(actions
    .setScrollingComponentScrollAmount(amount))),
  setVisible: (idLevel, isVisible, budgetId = null) => dispatch(actions
    .setBudgetLevelVisible(idLevel, isVisible, budgetId)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
