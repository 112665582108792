const React = require('react');
const PropTypes = require('prop-types');
const RadioFilter = require('../../AdvancedSearch/filters/RadioFilter/RadioFilter.react');
const TimeRelativeFilter = require('../../../../apps/core/modules/insights/revenues/models/ChartConfigFilter/ChartConfigFilterTimeRelative');

const ChartSidebarTimeRangeFilter = ({ onChange, value, label }) => {
  const options = [{
    label: 'Year to date',
    value: TimeRelativeFilter.VALUE_YEAR_TO_DATE,
  }, {
    label: 'This year',
    value: TimeRelativeFilter.VALUE_THIS_YEAR,
  }, {
    label: 'Last year',
    value: TimeRelativeFilter.VALUE_LAST_YEAR,
  }, {
    label: 'Last 3 years',
    value: TimeRelativeFilter.VALUE_LAST_3_YEARS,
  }, {
    label: 'Last 5 years',
    value: TimeRelativeFilter.VALUE_LAST_5_YEARS,
  }];

  return (
    <RadioFilter name="time" onChange={onChange} value={value} label={label} availableOptions={options} />
  );
};

ChartSidebarTimeRangeFilter.defaultProps = {
  value: '',
};

ChartSidebarTimeRangeFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  label: PropTypes.string.isRequired,
};

module.exports = ChartSidebarTimeRangeFilter;
