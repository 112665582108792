/* eslint-disable react/sort-comp,class-methods-use-this,jsx-a11y/anchor-is-valid,no-shadow,react/no-array-index-key,no-bitwise,react/no-did-update-set-state,no-unused-expressions,no-use-before-define,react/jsx-no-bind,jsx-a11y/mouse-events-have-key-events,react/no-access-state-in-setstate,consistent-return,default-case,no-param-reassign */

const React = require('react');
const Formatters = require('../../../services/Formatter');

module.exports = class ConversionModalTotalsHeader extends React.Component {
  getValue(amount) {
    if (amount !== 0) return amount;
  }

  getFormattedConversionValue(value) {
    if (value) return numeral(value).format('+0,0.[00]');
  }

  getLevelsLabels() {
    return this.props.levels.map((level) => (
      <div
        key={level.id}
        className="budget-table-header__level budget-table-header__level--not-empty budget-table-header__level--no-shrink">
        {level.short_name}
      </div>
    ));
  }

  getLevelCost(levelId) {
    const level = this.props.levels.filter((level) => level.level.id === levelId);
    return level ? level[0].cost : null;
  }

  getLevelConversionStyle() {
    let style = 'planning-conversion-modal__conversion';
    if (this.props.availableAmount >= 0) style += ' planning-conversion-modal__conversion--positive';
    else style += ' planning-conversion-modal__conversion--negative';
    return style;
  }

  getLevelsConversion(levelId) {
    const levelCost = this.getLevelCost(levelId);
    const availableConvertedDays = levelCost ? this.props.availableAmount / levelCost : 0;

    return (
      <div className={this.getLevelConversionStyle()}>
        {this.getFormattedConversionValue(this.getValue(availableConvertedDays))}
      </div>
    );
  }

  getLevelsTotals() {
    return this.props.totals.levels.map((level) => (
      <div key={level.id} className="budget-table-header__total-level budget-table-header__total-level--no-shrink">
        {this.getLevelsConversion(level.id)}
        <div>
          {Formatters.getFormattedDaysValue(level.total)}
        </div>
      </div>
    ));
  }

  render() {
    return (
      <div className="budget-table-header">
        <div className="budget-table-header-row">
          <div className="budget-table-header__price-list-new" />
          <div className="budget-table-header__levels-new">
            <div className="budget-table-header__levels-new--horizontal-scroll" ref={this.props.setLevelsHeaderReference} onScroll={this.props.onScroll}>
              {this.getLevelsLabels()}
            </div>
          </div>
          <div className="budget-table-header__days-new">Days</div>
        </div>
        <div className="budget-table-header-row--totals">
          <div className="budget-table-header__total-label-new">
            Total
          </div>
          <div className="budget-table-header__total-levels-new">
            <div className="budget-table-header__total-levels-new--horizontal-scroll" ref={this.props.setTotalsHeaderReference} onScroll={this.props.onScroll}>
              {this.getLevelsTotals()}
            </div>
          </div>
          <div className="budget-table-header__total-days-new">
            {Formatters.getFormattedDaysValue(this.getValue(this.props.totals.total))}
          </div>
        </div>
      </div>
    );
  }
};
