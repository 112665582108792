const Grouping = require('./ChartConfigProjectTypeDimensionGrouping');

class ChartConfigProjectTypeDimensionTopNGrouping extends Grouping {
  /**
   * @param {number} value
   */
  constructor(value) {
    super(ChartConfigProjectTypeDimensionTopNGrouping.TYPE);
    this.value = value;
  }

  /**
   * Return index of the group containing "all the rest" project types.
   * @return {?number}
   */
  getOthersGroupIndex() {
    return this.isGrouped() ? this.value : null;
  }

  /**
   * @param data
   * @return {ChartConfigProjectTypeDimensionTopNGrouping}
   */
  static fromJSON(data) {
    const value = parseInt(data);
    return new ChartConfigProjectTypeDimensionTopNGrouping(value);
  }

  // eslint-disable-next-line class-methods-use-this
  isGrouped() {
    return true;
  }

  clone() {
    return new ChartConfigProjectTypeDimensionTopNGrouping(this.value);
  }
}

ChartConfigProjectTypeDimensionTopNGrouping.TYPE = 'top-n';

module.exports = ChartConfigProjectTypeDimensionTopNGrouping;
