const React = require('react');
const Levels = require('../Cell/LevelsCell.react');
const Level = require('./AreaLevel.react');

const AreaLevels = ({ items, previousItems, mainBudgetPriceListLevels }) => {
  const getDays = (idLevel) => {
    const item = items.find((el) => el.level_id === idLevel);
    return item ? item.days : 0;
  };

  const sortedLevels = mainBudgetPriceListLevels.filter((el) => el.is_visible);

  const levels = sortedLevels.map((level) => (
    <Level key={level.level.id}
      previousValue={previousItems.find((el) => el.level_id === level.level.id).days}>
      {getDays(level.level.id)}
    </Level>
  ));

  return (
    <Levels className="wethod-budget-area__levels">{levels}</Levels>
  );
};

module.exports = AreaLevels;
